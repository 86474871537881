import { getUrl, get, update } from '../../services';

export async function getProjectAddressApi(params: any) {
  return await get(getUrl('projects/' + params.idProject + '/address'));
}

export async function putProjectAddressApi(params: any) {
  return await update(
    getUrl('projects/' + params.idProject + '/address'),
    params.address
  );
}

export async function getOrganizationAddressApi(params: any) {
  return await get(
    getUrl('organizations/' + params.idOrganization + '/address')
  );
}

export async function putOrganizationAddressApi(params: any) {
  return await update(
    getUrl('organizations/' + params.idOrganization + '/address'),
    params.address
  );
}
