import ImageModel from './ImageModel';

export default class ProductMethodModel {
  id: number;
  createdAt: Date | null;
  dateStart: Date | null;
  dateStop: Date | null;
  isActive: true;
  name: string;
  paymentType: string;
  picture: ImageModel | null;
  position: number;
  provider: string;
  reference: string;
  updatedAt: Date | null;

  constructor(data: any) {
    this.id = data.id;
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.dateStart = data.dateStartUTC ? new Date(data.dateStartUTC) : null;
    this.dateStop = data.dateStopUTC ? new Date(data.dateStopUTC) : null;
    this.isActive = data.isActive;
    this.name = data.name ?? '';
    this.paymentType = data.paymentType;
    this.picture = data.picture ? new ImageModel(data.picture) : null;
    this.position = data.position;
    this.provider = data.provider ?? '';
    this.reference = data.reference;
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
  }
}
