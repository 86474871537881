import { attendeesConstants } from '../constants';

export const getAttendeesByProjectRequest = (
  idProject: number,
  options: any
) => ({
  type: attendeesConstants.ATTENDEES_GET_BY_PROJECT_REQUEST,
  idProject,
  options,
});

export const getAttendeesByProjectSuccess = (attendees: any) => ({
  type: attendeesConstants.ATTENDEES_GET_BY_PROJECT_SUCCESS,
  attendees,
});

export const getAttendeesByProjectFailure = (error: any) => ({
  type: attendeesConstants.ATTENDEES_GET_BY_PROJECT_FAILURE,
  error,
});

export const getAttendeeByIdRequest = (idAttendee: number) => ({
  type: attendeesConstants.ATTENDEE_GET_BY_ID_REQUEST,
  idAttendee,
});

export const getAttendeeByIdSuccess = (attendee: any) => ({
  type: attendeesConstants.ATTENDEE_GET_BY_ID_SUCCESS,
  attendee,
});

export const getAttendeeByIdFailure = (error: any) => ({
  type: attendeesConstants.ATTENDEE_GET_BY_ID_FAILURE,
  error,
});

export const getAttendeeNfcTagsRequest = (
  idAttendee: number,
  options: any
) => ({
  type: attendeesConstants.ATTENDEE_GET_NFCTAG_REQUEST,
  idAttendee,
  options,
});

export const getAttendeeNfcTagsSuccess = (nfcTags: any) => ({
  type: attendeesConstants.ATTENDEE_GET_NFCTAG_SUCCESS,
  nfcTags,
});

export const getAttendeeNfcTagsFailure = (error: any) => ({
  type: attendeesConstants.ATTENDEE_GET_NFCTAG_FAILURE,
  error,
});

export const getAttendeeBarcodesRequest = (
  idAttendee: number,
  options: any
) => ({
  type: attendeesConstants.ATTENDEE_GET_BARCODES_REQUEST,
  idAttendee,
  options,
});

export const getAttendeeBarcodesSuccess = (barcodes: any) => ({
  type: attendeesConstants.ATTENDEE_GET_BARCODES_SUCCESS,
  barcodes,
});

export const getAttendeeBarcodesFailure = (error: any) => ({
  type: attendeesConstants.ATTENDEE_GET_BARCODES_FAILURE,
  error,
});

export const getAttendeeBasketsRequest = (
  idAttendee: number,
  options: any
) => ({
  type: attendeesConstants.ATTENDEE_GET_BASKETS_REQUEST,
  idAttendee,
  options,
});

export const getAttendeeBasketsSuccess = (baskets: any) => ({
  type: attendeesConstants.ATTENDEE_GET_BASKETS_SUCCESS,
  baskets,
});

export const getAttendeeBasketsFailure = (error: any) => ({
  type: attendeesConstants.ATTENDEE_GET_BASKETS_FAILURE,
  error,
});

export const getAttendeeNotesRequest = (idAttendee: number, options: any) => ({
  type: attendeesConstants.ATTENDEE_GET_NOTES_REQUEST,
  idAttendee,
  options,
});

export const getAttendeeNotesSuccess = (notes: any) => ({
  type: attendeesConstants.ATTENDEE_GET_NOTES_SUCCESS,
  notes,
});

export const getAttendeeNotesFailure = (error: any) => ({
  type: attendeesConstants.ATTENDEE_GET_NOTES_FAILURE,
  error,
});

export const getAttendeeMailsRequest = (idAttendee: number, options: any) => ({
  type: attendeesConstants.ATTENDEE_GET_MAILS_REQUEST,
  idAttendee,
  options,
});

export const getAttendeeMailsSuccess = (mails: any) => ({
  type: attendeesConstants.ATTENDEE_GET_MAILS_SUCCESS,
  mails,
});

export const getAttendeeMailsFailure = (error: any) => ({
  type: attendeesConstants.ATTENDEE_GET_MAILS_FAILURE,
  error,
});

export const postAttendeeNotesRequest = (
  idAttendee: number,
  note: any,
  params: any
) => ({
  type: attendeesConstants.ATTENDEE_POST_NOTES_REQUEST,
  idAttendee,
  note,
  params,
});

export const postAttendeeNotesSuccess = (response: any) => ({
  type: attendeesConstants.ATTENDEE_POST_NOTES_SUCCESS,
  response,
});

export const postAttendeeNotesFailure = (error: any) => ({
  type: attendeesConstants.ATTENDEE_POST_NOTES_FAILURE,
  error,
});
