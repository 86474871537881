export const projectConstants = {
  PROJECT_DETAILS_GET_REQUEST: 'PROJECT_DETAILS_GET_REQUEST',
  PROJECT_DETAILS_GET_SUCCESS: 'PROJECT_DETAILS_GET_SUCCESS',
  PROJECT_DETAILS_GET_FAILURE: 'PROJECT_DETAILS_GET_FAILURE',

  PROJECTS_SELF_GET_REQUEST: 'PROJECTS_SELF_GET_REQUEST',
  PROJECTS_SELF_GET_SUCCESS: 'PROJECTS_SELF_GET_SUCCESS',
  PROJECTS_SELF_GET_FAILURE: 'PROJECTS_SELF_GET_FAILURE',

  PROJECT_DETAILS_PUT_REQUEST: 'PROJECT_DETAILS_PUT_REQUEST',
  PROJECT_DETAILS_PUT_SUCCESS: 'PROJECT_DETAILS_PUT_SUCCESS',
  PROJECT_DETAILS_PUT_FAILURE: 'PROJECT_DETAILS_PUT_FAILURE',

  PROJECT_GET_COST_REQUEST: 'PROJECT_GET_COST_REQUEST',
  PROJECT_GET_COST_SUCCESS: 'PROJECT_GET_COST_SUCCESS',
  PROJECT_GET_COST_FAILURE: 'PROJECT_GET_COST_FAILURE',

  ARCHIVE_PROJECT_REQUEST: 'ARCHIVE_PROJECT_REQUEST',
  ARCHIVE_PROJECT_SUCCESS: 'ARCHIVE_PROJECT_SUCCESS',
  ARCHIVE_PROJECT_FAILURE: 'ARCHIVE_PROJECT_FAILURE',

  CLEAR_PROJECT: 'CLEAR_PROJECT',
};
