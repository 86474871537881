import ProductMethodModel from './ProductMethodModel';
import TransactionModel from './TransactionModel';

export default class PaymentModel {
  id: number;
  amount: number;
  canceledByPayment: string | null;
  createdAt: Date | null;
  currency: string;
  datePayment: Date | null;
  includeInStats: boolean;
  ip: null | string;
  method: string;
  orderId: string | null;
  paymentMethod: ProductMethodModel;
  paymentTransactions: TransactionModel[];
  requiredAmount: null | number;
  requiredCurrencyAmount: null | number;
  signature: string;
  status: number;
  statusName: string;
  updatedAt: Date | null;

  constructor(data: any) {
    this.id = data.id;
    this.amount = data.amount ?? '';
    this.canceledByPayment = data.canceledByPayment;
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.currency = data.currency;
    this.datePayment = data.datePaymentUTC
      ? new Date(data.datePaymentUTC)
      : null;
    this.includeInStats = data.includeInStats;
    this.ip = data.ip;
    this.method = data.method;
    this.orderId = data.orderId ? data.orderId : '';
    this.paymentMethod = new ProductMethodModel(data.paymentMethod);
    this.paymentTransactions = data.paymentTransactions.map(
      (transaction: any) => new TransactionModel(transaction)
    );
    this.requiredAmount = data.requiredAmount;
    this.requiredCurrencyAmount = data.requiredCurrencyAmount;
    this.signature = data.signature;
    this.status = data.status;
    this.statusName = data.statusName ?? '/';
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
  }
}
