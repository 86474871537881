import CustomerProfileModel from './CustomerProfileModel';

export default class NoteModel {
  id: number;
  content: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  userProfile: CustomerProfileModel | null;

  constructor(data: any) {
    this.id = data.id;
    this.content = data.content;
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
    this.userProfile = data.userProfile
      ? new CustomerProfileModel(data.userProfile)
      : null;
  }
}
