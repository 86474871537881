import { capitalizeFirstLetter } from './capitalizeFirstLetter';

export const getNameLanguage = (
  defaultLanguage: string | null,
  locale: string
): string => {
  let languageNames = new Intl.DisplayNames(
    [defaultLanguage ? defaultLanguage.replaceAll('_', '-') : 'fr-FR'],
    {
      type: 'language',
    }
  );

  return capitalizeFirstLetter(languageNames.of(locale.replaceAll('_', '-')));
};
