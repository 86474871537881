import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Chip, Popover } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './index.css';
export const StatusComponent = (props) => {
    const successStatus = useMemo(() => [
        //Status will have the color green
        'onsale',
        'payment_accept',
        'validated',
        'redeemed',
        'delivered',
        'complete',
        'stock',
        'accepted',
        'payment_via_invoice',
    ], []);
    const warningStatus = useMemo(() => [
        //Status will have the color orange
        'postponed',
        'scanned',
        'planned',
        'awaiting_payment_online',
        'awaiting_payment_bank',
        'waitPayment',
        'verification',
        'booked',
        'delivery',
        'waiting',
        'payment_pending',
    ], []);
    const cancelStatus = useMemo(() => [
        //Status will have the color red
        'expired',
        'deleted',
        'swapped',
        'denied',
        'lost',
        'stolen',
        'refused',
    ], []);
    const darkStatus = useMemo(() => [
        //Status will have the color black
        'refunded',
        'refund',
        'downloaded',
        'soldout',
        'cancel',
        'canceled',
        'technicalProblem',
        'consigned',
        'repair',
        'broken',
        'failed',
    ], []);
    const lightStatus = useMemo(() => [
        //Status will have the color grey
        'unknown',
        'created',
        'giveup',
        'draft',
        'revoked',
        'returned',
        'quarantine',
        'comingsoon',
        'notStarted',
    ], []);
    const [anchorEl, setAnchorEl] = useState(null);
    const [classStatus, setClassStatus] = useState('unknown');
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function changeStatus(newStatus) {
        if (props.updateStatus) {
            props.updateStatus(newStatus.name);
            setAnchorEl(newStatus);
        }
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    useEffect(() => {
        if (props.status) {
            if (successStatus.includes(props.status)) {
                setClassStatus('successStatus');
            }
            if (warningStatus.includes(props.status)) {
                setClassStatus('warningStatus');
            }
            if (cancelStatus.includes(props.status)) {
                setClassStatus('cancelStatus');
            }
            if (darkStatus.includes(props.status)) {
                setClassStatus('darkStatus');
            }
            if (lightStatus.includes(props.status)) {
                setClassStatus('lightStatus');
            }
        }
    }, [props.status]);
    if (!props.clickable) {
        return (_jsx(Chip, { className: `chipContainer ${classStatus}`, label: props.label, variant: "outlined" }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Chip, { className: `chipContainer ${classStatus}`, label: _jsxs("div", { className: "arrowText", style: { color: props.color }, children: [props.label, _jsx(ArrowDropDownIcon, {})] }), variant: "outlined", onClick: handleClick }), _jsx(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }, children: (props === null || props === void 0 ? void 0 : props.statusList) &&
                    props.statusList.map((data, index) => {
                        if (props.status !== data.name) {
                            return (_jsx("div", { className: "popup", onClick: () => changeStatus(data), children: data.text }, index));
                        }
                    }) })] }));
};
