export default class WalletConfigModel {
  decimalDigits: number;
  default: boolean;
  id: number;
  name: string;
  unitName: string;
  unitSymbol: string;

  constructor(data: any) {
    this.decimalDigits = data.decimalDigits;
    this.default = data.default;
    this.id = data.id;
    this.name = data.name;
    this.unitName = data.unitName;
    this.unitSymbol = data.unitSymbol;
  }
}
