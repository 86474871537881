import ImageModel from './ImageModel';
import { setFullName } from './UserModel';

export default class AdminModel {
  aliasName: null | string;
  cellularPhoneNumber: null | string;
  defaultLanguage: null | string;
  email: string;
  emailConfirmed: boolean;
  firstName: string;
  fullName: string | null;
  gender: string;
  id: number;
  lastName: string;
  locale: null | string;
  phonenumber: string;
  picture: ImageModel | null;

  constructor(data: any) {
    this.aliasName = data.aliasName;
    this.cellularPhoneNumber = data.cellularPhoneNumber;
    this.defaultLanguage = data.defaultLanguage;
    this.email = data.email;
    this.emailConfirmed = data.emailConfirmed;
    this.firstName = data.firstName;
    this.fullName = setFullName(data.fullName);
    this.gender = data.gender;
    this.id = data.id;
    this.lastName = data.lastName;
    this.locale = data.locale;
    this.phonenumber = data.phonenumber;
    this.picture = data.picture ? new ImageModel(data.picture) : null;
  }
}
