import UserModel, { setFullName } from './UserModel';

export default class UserSelfModel {
  aliasName: null | string;
  birthday: Date | null;
  cellularPhoneNumber: null | string;
  createdAt: Date | null;
  defaultLanguage: null | string;
  email: string;
  emailConfirmed: boolean;
  federationIdentificationNumber: null | string;
  firstName: string;
  fullName: string | null;
  gender: null | string;
  id: number;
  lastName: string;
  locale: null | string;
  namePrefix: null | string;
  nameSuffix: null | string;
  nationalIdentificationNumber: null | string;
  phonenumber: string;
  pictureFullWebPath: null | string;
  stripeCustomerId: null | number;
  updatedAt: Date | null;
  user: UserModel | null;

  constructor(data: any) {
    this.aliasName = data.aliasName;
    this.birthday = data.birthday ? new Date(data.birthday) : null;
    this.cellularPhoneNumber = data.cellularPhoneNumber;
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.defaultLanguage = data.defaultLanguage;
    this.email = data.email ?? '';
    this.emailConfirmed = data.emailConfirmed;
    this.federationIdentificationNumber = data.federationIdentificationNumber;
    this.firstName = data.firstName ?? '';
    this.fullName = setFullName(data.fullName);
    this.gender = data.gender;
    this.id = data.id;
    this.lastName = data.lastName ?? '';
    this.locale = data.locale;
    this.namePrefix = data.namePrefix;
    this.nameSuffix = data.nameSuffix;
    this.nationalIdentificationNumber = data.nationalIdentificationNumber;
    this.phonenumber = data.phonenumber ?? '';
    this.pictureFullWebPath = data.pictureFullWebPath;
    this.stripeCustomerId = data.stripeCustomerId;
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
    this.user = data.user ? new UserModel(data.user) : null;
  }
}
