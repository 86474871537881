import { currentProject } from '../Global/currentProject';

export type printAtHomePayload = {
  printAtHome: number;
  locale: string;
};

export default class PrintAtHomesModel {
  id: number | null;
  src: string | null;
  original: string | null;

  constructor(data: any) {
    this.id = data.id;
    this.src = data.src;
    this.original = data.original;
  }
}

export const createPrintAtHomes = (printAtHomes: any) => {
  let imagesObject: any = {};
  currentProject.allowedLanguages.map((language: string) => {
    if (printAtHomes && printAtHomes.hasOwnProperty(language)) {
      imagesObject[language] = new PrintAtHomesModel({
        id: printAtHomes[language].id,
        src: null,
        original: printAtHomes[language].original,
      });
    } else {
      imagesObject[language] = new PrintAtHomesModel({
        id: null,
        src: null,
        original: null,
      });
    }
  });

  return imagesObject;
};
