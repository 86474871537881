import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getContentsSuccess,
  getContentsFailure,
  getContentContentsSuccess,
  getContentContentsFailure,
  getContentMailSuccess,
  getContentMailFailure,
  putContentMailFailure,
  putContentMailSuccess,
  putContentContentsSuccess,
  putContentContentsFailure,
} from '../actions';
import { contentsConstants } from '../constants';
import {
  getContentContentsApi,
  getContentMailsApi,
  getContentsApi,
  putContentContentsApi,
  putContentMailsApi,
} from '../api';

export function* fetchGetContents(action: any): any {
  try {
    const contents = yield call(getContentsApi, action.params);
    yield put(getContentsSuccess(contents));
  } catch (error) {
    yield put(getContentsFailure(error));
  }
}

export function* fetchGetContentContents(action: any): any {
  try {
    const response = yield call(getContentContentsApi, {
      reference: action.reference,
      params: action.params,
    });
    yield put(getContentContentsSuccess(response));
  } catch (error) {
    yield put(getContentContentsFailure(error));
  }
}

export function* fetchGetContentMails(action: any): any {
  try {
    const response = yield call(getContentMailsApi, {
      reference: action.reference,
      params: action.params,
    });
    yield put(getContentMailSuccess(response));
  } catch (error) {
    yield put(getContentMailFailure(error));
  }
}

export function* fetchPutContentMails(action: any): any {
  try {
    const response = yield call(putContentMailsApi, {
      reference: action.reference,
      projectId: action.projectId,
      params: action.params,
    });
    yield put(putContentMailSuccess(response));
  } catch (error) {
    yield put(putContentMailFailure(error));
  }
}

export function* fetchPutContentContents(action: any): any {
  try {
    const response = yield call(putContentContentsApi, {
      reference: action.reference,
      projectId: action.projectId,
      params: action.params,
    });
    yield put(putContentContentsSuccess(response));
  } catch (error) {
    yield put(putContentContentsFailure(error));
  }
}

export function* getContents() {
  yield takeLatest(contentsConstants.CONTENTS_GET_REQUEST, fetchGetContents);
}

export function* getContentContents() {
  yield takeLatest(
    contentsConstants.GET_CONTENT_CONTENTS_REQUEST,
    fetchGetContentContents
  );
}

export function* getContentMails() {
  yield takeLatest(
    contentsConstants.GET_CONTENT_MAIL_REQUEST,
    fetchGetContentMails
  );
}

export function* putContentMails() {
  yield takeLatest(
    contentsConstants.PUT_CONTENT_MAIL_REQUEST,
    fetchPutContentMails
  );
}

export function* putContentContents() {
  yield takeLatest(
    contentsConstants.PUT_CONTENT_CONTENTS_REQUEST,
    fetchPutContentContents
  );
}

export default function* contentsSaga() {
  yield all([
    fork(getContents),
    fork(getContentContents),
    fork(getContentMails),
    fork(putContentMails),
    fork(putContentContents),
  ]);
}
