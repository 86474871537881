import { ticketConstants } from '../constants';

export const getTicketsByProjectRequest = (
  idProject: number,
  options: any
) => ({
  type: ticketConstants.TICKETS_GET_BY_PROJECT_REQUEST,
  idProject,
  options,
});

export const getTicketsByProjectSuccess = (tickets: any) => ({
  type: ticketConstants.TICKETS_GET_BY_PROJECT_SUCCESS,
  allTickets: tickets,
});

export const getTicketsByProjectFailure = (error: any) => ({
  type: ticketConstants.TICKETS_GET_BY_PROJECT_FAILURE,
  error,
});

export const putTicketRequest = (ticket: any) => ({
  type: ticketConstants.PUT_TICKET_REQUEST,
  ticket,
});

export const putTicketSuccess = (updatedTicket: any) => ({
  type: ticketConstants.PUT_TICKET_SUCCESS,
  updatedTicket,
});

export const putTicketFailure = (error: any) => ({
  type: ticketConstants.PUT_TICKET_FAILURE,
  error,
});

export const postTicketRequest = (idProject: number, ticket: any) => ({
  type: ticketConstants.POST_TICKET_REQUEST,
  idProject,
  ticket,
});

export const postTicketSuccess = (newTicket: any) => ({
  type: ticketConstants.POST_TICKET_SUCCESS,
  newTicket,
});

export const postTicketFailure = (error: any) => ({
  type: ticketConstants.POST_TICKET_FAILURE,
  error,
});

export const getTicketRequest = (id: number | string) => ({
  type: ticketConstants.GET_TICKET_REQUEST,
  id,
});

export const getTicketSuccess = (ticket: any) => ({
  type: ticketConstants.GET_TICKET_SUCCESS,
  ticket,
});

export const getTicketFailure = (error: any) => ({
  type: ticketConstants.GET_TICKET_FAILURE,
  error,
});

export const putTicketTranslationsRequest = (
  id: number,
  translations: any
) => ({
  type: ticketConstants.PUT_TICKET_TRANSLATIONS_REQUEST,
  id,
  translations,
});

export const putTicketTranslationsSuccess = (
  updatedTicketTranslations: any
) => ({
  type: ticketConstants.PUT_TICKET_TRANSLATIONS_SUCCESS,
  updatedTicketTranslations,
});

export const putTicketTranslationsFailure = (error: any) => ({
  type: ticketConstants.PUT_TICKET_TRANSLATIONS_FAILURE,
  error,
});

export const putTicketCategoriesRequest = (
  idTicket: number,
  categories: any
) => ({
  type: ticketConstants.PUT_TICKET_CATEGORIES_REQUEST,
  idTicket,
  categories,
});

export const putTicketCategoriesSuccess = (updatedTicketCategories: any) => ({
  type: ticketConstants.PUT_TICKET_CATEGORIES_SUCCESS,
  updatedTicketCategories,
});

export const putTicketCategoriesFailure = (error: any) => ({
  type: ticketConstants.PUT_TICKET_CATEGORIES_FAILURE,
  error,
});

export const deleteTicketRequest = (idTicket: number) => ({
  type: ticketConstants.DELETE_TICKET_REQUEST,
  idTicket,
});

export const deleteTicketSuccess = (deletedTicket: any) => ({
  type: ticketConstants.DELETE_TICKET_SUCCESS,
  deletedTicket,
});

export const deleteTicketFailure = (error: any) => ({
  type: ticketConstants.DELETE_TICKET_FAILURE,
  error,
});

export const clearTicketRequest = () => ({
  type: ticketConstants.CLEAR_TICKET,
});
