import { currentProject } from '../Global/currentProject';

type VatRateTranslationType = {
  id?: number;
  description: string;
  locale: string;
};

export default class VatRateModel {
  countryCode: string;
  description: string;
  enabled: boolean;
  id: number;
  rate: number;
  value: number;
  translations: VatRateTranslationType[];

  constructor(data: any) {
    this.countryCode = data.countryCode;
    this.description = data.description;
    this.enabled = data.enabled;
    this.id = data.id;
    this.rate = data.rate;
    this.value = data.id;
    this.translations = this.createVatRateTranslations(data);
  }

  private createVatRateTranslations = (data: any): VatRateTranslationType[] => {
    let allTranslations: VatRateTranslationType[] = [];
    if (data.hasOwnProperty('translations')) {
      currentProject.allowedLanguages.forEach((language: string) => {
        const found = data.translations.find(
          (translation: any) => translation.locale === language
        );

        if (found) {
          allTranslations.push(found);
        } else {
          allTranslations.push({
            locale: language,
            description: data.description
              ? data.description
              : data.rate.toString() + ' %',
          });
        }
      });
    }
    return allTranslations;
  };

  get label() {
    if (this.translations?.length > 0) {
      const currentLabel = this.translations.find(
        (translation: any) =>
          translation.locale === currentProject.language.locale
      );

      if (currentLabel) {
        return currentLabel.description;
      } else {
        return this.translations[0]?.description;
      }
    }

    return this.description ? this.description : this.rate.toString() + ' %';
  }
}
