import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getProductTypesSuccess, getProductTypesFailure } from '../actions';
import { productTypesConstants } from '../constants';
import { getProductTypesApi } from '../api';

export function* fetchGetProductTypes(): any {
  try {
    const productTypes = yield call(getProductTypesApi);
    yield put(getProductTypesSuccess(productTypes));
  } catch (error) {
    yield put(getProductTypesFailure(error));
  }
}

export function* getProductTypes() {
  yield takeLatest(
    productTypesConstants.PRODUCT_TYPES_GET_REQUEST,
    fetchGetProductTypes
  );
}

export default function* productTypeSaga() {
  yield all([fork(getProductTypes)]);
}
