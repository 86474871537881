import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import { post, getUrl } from '../services';
import {
  removeTokenFromStorage,
  addTokenToStorage,
} from '../../services/handleToken';
import { isTokenNearlyInvalid, isTokenOver } from '../../services/parseJwt';
import { pagesConstant } from '../../Constants/Constant';
import appSaga from '../saga';

const loggerMiddleware = createLogger();
let refreshTokenRequest: number = 0;
let pendingRequest: any[] = [];

const refreshTokenMiddleware = () => (next: any) => (action: any) => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  if (token && refreshToken) {
    if (isTokenOver(token)) {
      pendingRequest.push({
        next: next,
        action: action,
      });
      if (!refreshTokenRequest) {
        refreshCurrentToken();
      }
    } else {
      if (isTokenNearlyInvalid(token)) {
        if (!refreshTokenRequest) {
          refreshCurrentToken();
        }
      }

      return next(action);
    }
  } else {
    return next(action);
  }
};

export const refreshCurrentToken = () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  refreshTokenRequest++;

  post(getUrl('/api/refresh_token'), {
    refresh_token: refreshToken,
  })
    .then((response) => {
      addTokenToStorage(response);

      refreshTokenRequest = 0;
      pendingRequest.map((request) => request.next(request.action));
      pendingRequest = [];
    })
    .catch(() => {
      if (isTokenNearlyInvalid(token)) {
        if (refreshTokenRequest < 3) {
          setTimeout(() => {
            refreshCurrentToken();
          }, 10000);
        } else {
          refreshTokenRequest = 0;
          pendingRequest = [];
          removeTokenFromStorage();
          location.pathname = pagesConstant.signIn;
        }
      } else {
        if (isTokenOver(token)) {
          pendingRequest = [];
          removeTokenFromStorage();
          location.pathname = pagesConstant.signIn;
        }
      }
    });
};

const sagaMiddleware = createSagaMiddleware();
let middlewares: any[] = [refreshTokenMiddleware, sagaMiddleware];

if (process.env.REACT_APP_ENABLE_LOG === 'true') {
  middlewares.push(loggerMiddleware);
}

export default function getStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
  });

  sagaMiddleware.run(appSaga);
  return store;
}
