import { categoriesConstants } from '../constants';

export const deleteCategoryRequest = (idCategory: number) => ({
  type: categoriesConstants.CATEGORY_DELETE_REQUEST,
  idCategory,
});

export const deleteCategorySuccess = (deletedCategory: any) => ({
  type: categoriesConstants.CATEGORY_DELETE_SUCCESS,
  deletedCategory,
});

export const deleteCategoryFailure = (error: any) => ({
  type: categoriesConstants.CATEGORY_DELETE_FAILURE,
  error,
});

export const getCategoryTranslationsRequest = (idCategory: number) => ({
  type: categoriesConstants.CATEGORY_GET_TRANSLATIONS_REQUEST,
  idCategory,
});

export const getCategoryTranslationsSuccess = (categoryTranslations: any) => ({
  type: categoriesConstants.CATEGORY_GET_TRANSLATIONS_SUCCESS,
  categoryTranslations,
});

export const getCategoryTranslationsFailure = (error: any) => ({
  type: categoriesConstants.CATEGORY_GET_TRANSLATIONS_FAILURE,
  error,
});

export const getCategoriesByProjectRequest = (idProject: number) => ({
  type: categoriesConstants.CATEGORIES_GET_BY_PROJECT_REQUEST,
  idProject,
  options: {
    limit: 1000,
    order: 'id:DESC',
    isOnline: 1,
    type: 'formule',
  },
});

export const getCategoriesByProjectSuccess = (categories: any) => ({
  type: categoriesConstants.CATEGORIES_GET_BY_PROJECT_SUCCESS,
  categories,
});

export const getCategoriesByProjectFailure = (error: any) => ({
  type: categoriesConstants.CATEGORIES_GET_BY_PROJECT_FAILURE,
  error,
});

export const postCategoryByProjectRequest = (
  idProject: number,
  newCategory: any
) => ({
  type: categoriesConstants.CATEGORY_POST_BY_PROJECT_REQUEST,
  idProject,
  newCategory,
});

export const postCategoryByProjectSuccess = (newCategory: any) => ({
  type: categoriesConstants.CATEGORY_POST_BY_PROJECT_SUCCESS,
  newCategory,
});

export const postCategoryByProjectFailure = (error: any) => ({
  type: categoriesConstants.CATEGORY_POST_BY_PROJECT_FAILURE,
  error,
});

export const putCategoryTranslationsRequest = (
  idCategory: number,
  updatedCategoryTranslations: any
) => ({
  type: categoriesConstants.CATEGORY_TRANSLATIONS_PUT_REQUEST,
  idCategory,
  updatedCategoryTranslations,
});

export const putCategoryTranslationsSuccess = (
  updatedCategoryTranslations: any
) => ({
  type: categoriesConstants.CATEGORY_TRANSLATIONS_PUT_SUCCESS,
  updatedCategoryTranslations,
});

export const putCategoryTranslationsFailure = (error: any) => ({
  type: categoriesConstants.CATEGORY_TRANSLATIONS_PUT_FAILURE,
  error,
});

export const postCategoryTranslationsRequest = (
  idCategory: number,
  categoryTranslations: any
) => ({
  type: categoriesConstants.CATEGORY_TRANSLATIONS_POST_REQUEST,
  idCategory,
  categoryTranslations,
});

export const postCategoryTranslationsSuccess = (
  newCategoryTranslations: any
) => ({
  type: categoriesConstants.CATEGORY_TRANSLATIONS_POST_SUCCESS,
  newCategoryTranslations,
});

export const postCategoryTranslationsFailure = (error: any) => ({
  type: categoriesConstants.CATEGORY_TRANSLATIONS_POST_FAILURE,
  error,
});

export const postCategoryProductRequest = (
  idCategory: number,
  idProduct: number
) => ({
  type: categoriesConstants.CATEGORY_POST_PRODUCT_REQUEST,
  idCategory,
  idProduct,
});

export const postCategoryProductSuccess = (newCategoryProduct: any) => ({
  type: categoriesConstants.CATEGORY_POST_PRODUCT_SUCCESS,
  newCategoryProduct,
});

export const postCategoryProductFailure = (error: any) => ({
  type: categoriesConstants.CATEGORY_POST_PRODUCT_FAILURE,
  error,
});

export const clearCategories = () => ({
  type: categoriesConstants.CLEAR_CATEGORIES,
});
