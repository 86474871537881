import { getUrl, del, get, patch, post, update } from '../../services';

export async function getTicketsByProjectApi(params: any) {
  return await get(
    getUrl('projects/' + params.idProject + '/products', params.args)
  );
}

export async function putTicketApi(ticket: any) {
  return await update(getUrl('products/' + ticket.id), ticket);
}

export async function postTicketApi(params: any) {
  return await post(
    getUrl('projects/' + params.idProject + '/products'),
    params.ticket
  );
}

export async function getTicketApi(params: any) {
  return await get(getUrl('products/' + params.id));
}

export async function putTicketTranslationsApi(params: any) {
  return await update(
    getUrl('products/' + params.id + '/translations'),
    params.translations
  );
}

export async function deleteTicketApi(params: any) {
  return await del(getUrl('products/' + params.id));
}

export async function putTicketCategoriesApi(params: any) {
  return await patch(
    getUrl(`products/${params.id}/categories`),
    params.categories
  );
}
