export default class ProductReportModel {
  attendeeCount: boolean;
  cashlessCredits: number;
  categoryId: number | null;
  categoryName: string | null;
  formuleId: number;
  formuleName: string;
  numberAttendees: number;
  numberAttendeesLeft: number;
  numberAttendeesScanned: number;
  percentScanned: number;
  priceWithVat: number;
  priceWithoutVat: number;
  quantity: number;

  constructor(data: any) {
    this.attendeeCount = data.attendeeCount;
    this.cashlessCredits = data.cashlessCredits;
    this.categoryId = data.categoryId;
    this.categoryName = data.categoryName;
    this.formuleId = data.formuleId;
    this.formuleName = data.formuleName;
    this.numberAttendees = data.numberAttendees;
    this.numberAttendeesLeft = data.numberAttendeesLeft;
    this.numberAttendeesScanned = data.numberAttendeesScanned;
    this.percentScanned = data.percentScanned;
    this.priceWithVat = data.priceWithVat;
    this.priceWithoutVat = data.priceWithoutVat;
    this.quantity = data.quantity;
  }
}
