import intl from 'react-intl-universal';
import AppLanguages, { languageDataInterface } from '../lngProvider';
import { getDayjsLocale } from './getTempLocale';
import { currentProject } from '../Global/currentProject';

function initTranslations(newLocale?: string) {
  let currentLanguage = getLanguage(newLocale);

  if (!currentLanguage) {
    const localFromStorage = localStorage.getItem('locale');
    let language: languageDataInterface = AppLanguages[0];

    if (localFromStorage) {
      const checkLanguage = getLanguage(localFromStorage);
      if (checkLanguage) {
        language = checkLanguage;
      }
    }

    currentLanguage = language;
  }

  currentProject.updateLocale(currentLanguage);
  getDayjsLocale(currentLanguage.locale);

  intl
    .init({
      currentLocale: currentLanguage.locale,
      locales: { [currentLanguage.locale]: currentLanguage.translations },
      warningHandler: (message) => {
        console.error('Init translation error : ' + message);
      },
    })
    .then();
}

const getLanguage = (locale?: string) => {
  return AppLanguages.find(
    (appLanguage: languageDataInterface) => appLanguage.locale === locale
  );
};

export default initTranslations;
