export default class ShopConfigModel {
  id: number;
  isOpen: boolean;
  previewKey: string | null;
  showAvailabilityIndicators: boolean;
  collapseCategories: boolean;
  showProductPicture: boolean;
  hideNewsletter: boolean;
  slug: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.isOpen = data.isOpen;
    this.previewKey = data.previewKey;
    this.showAvailabilityIndicators = data.showAvailabilityIndicators;
    this.collapseCategories = data.collapseCategories;
    this.showProductPicture = data.showProductPicture;
    this.hideNewsletter = data.hideNewsletter;
    this.slug = data.slug;
  }

  updateIsOpen = (newValue: boolean) => {
    this.isOpen = newValue;
  };
}
