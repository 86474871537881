import UserModel, { setFullName } from './UserModel';
import ImageModel from './ImageModel';

export default class CustomerProfileModel {
  id: number;
  aliasName: string | null;
  cellularPhoneNumber: string | null;
  createdAt: Date | null;
  defaultLanguage: string | null;
  email: string;
  emailConfirmed: boolean;
  firstName: string | null;
  fullName: string | null;
  gender: string | null;
  lastName: string | null;
  locale: string | null;
  phonenumber: string | null;
  updatedAt: Date | null;
  user: UserModel | null;
  picture: ImageModel | null;

  constructor(data: any) {
    this.id = data.id;
    this.aliasName = data.aliasName;
    this.defaultLanguage = data.defaultLanguage;
    this.cellularPhoneNumber = data.cellularPhoneNumber;
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.email = data.email;
    this.emailConfirmed = data.emailConfirmed;
    this.firstName = data.firstName;
    this.fullName = setFullName(data.fullName);
    this.gender = data.gender;
    this.lastName = data.lastName;
    this.locale = data.locale;
    this.phonenumber = data.phonenumber;
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
    this.user = data.user ? new UserModel(data.user) : null;
    this.picture = data.picture ? new ImageModel(data.picture) : null;
  }
}
