import ProgressiveFeeModel from './ProgressiveFeeModel';

export default class ProjectCostModel {
  id: number;
  activateCosts: boolean;
  newCardFee: null | number;
  organizerBasketOnlineFee: number;
  organizerBasketOnsiteFee: number;
  replaceCardFee: null | number;
  tagActivationAccessFee: number;
  tagActivationAccessPeriod: string;
  tagActivationCashlessFee: number;
  tagActivationCashlessPeriod: string;
  ticketProgressiveFees: ProgressiveFeeModel[];

  constructor(data: any) {
    this.id = data.id;
    this.activateCosts = data.activateCosts;
    this.newCardFee = data.newCardFee;
    this.organizerBasketOnlineFee = data.organizerBasketOnlineFee;
    this.organizerBasketOnsiteFee = data.organizerBasketOnsiteFee;
    this.replaceCardFee = data.replaceCardFee;
    this.tagActivationAccessFee = data.tagActivationAccessFee;
    this.tagActivationAccessPeriod = data.tagActivationAccessPeriod;
    this.tagActivationCashlessFee = data.tagActivationCashlessFee;
    this.tagActivationCashlessPeriod = data.tagActivationCashlessPeriod;
    this.ticketProgressiveFees = data.ticketProgressiveFees.map(
      (fee: any) => new ProgressiveFeeModel(fee)
    );
  }
}
