import BrandingContentModel from './BrandingContentModel';
import { currentProject } from '../Global/currentProject';

type ContentContentTranslationType = {
  body: string;
  locale: string;
};

type ContentContentTranslationsType = {
  [lang: string]: ContentContentTranslationType;
};

export default class ContentContentModel extends BrandingContentModel {
  isMarkdown: boolean;
  translations: ContentContentTranslationsType;

  constructor(data: any) {
    super(data);
    this.isMarkdown = data.isMarkdown;
    this.translations = this.createContentTranslations(data.translations);
  }

  private createContentTranslations = (
    translations: any
  ): ContentContentTranslationsType => {
    let allTranslations: ContentContentTranslationsType = {};
    currentProject.allowedLanguages.forEach((language: string) => {
      if (translations && translations[language]) {
        allTranslations[language] = translations[language];
      } else {
        allTranslations[language] = {
          body: '',
          locale: language,
        };
      }
    });
    return allTranslations;
  };
}
