export default class OrganizationModel {
  bankTransferBic: null | string;
  bankTransferIban: null | string;
  bankTransferName: null | string;
  defaultCountry: string;
  id: number;
  isActive: boolean;
  legalStatus: string;
  name: string;
  vatNumber: string;
  vatRate: number | null;

  constructor(data: any) {
    this.bankTransferBic = data.bankTransferBic;
    this.bankTransferIban = data.bankTransferIban;
    this.bankTransferName = data.bankTransferName;
    this.defaultCountry = data.defaultCountry;
    this.id = data.id;
    this.isActive = data.isActive;
    this.legalStatus = data.legalStatus;
    this.name = data.name;
    this.vatNumber = data.vatNumber;
    this.vatRate = data.vatRate?.id || null;
  }
}
