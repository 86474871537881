import { projectConstants } from '../constants';
import ProjectModel from '../../Models/ProjectModel';
import ErrorModel from '../../Models/ErrorModel';
import ProjectCostModel from '../../Models/ProjectCostModel';
import UserProjectModel from '../../Models/UserProjectModel';

const initialState: any = {
  projectDetails: null,
  projectsSelf: null,
  updatedProjectDetails: null,
  projectCost: null,
  projectArchived: null,
};

let projectSelfList: UserProjectModel[] = [];

const project = (state = initialState, action: any) => {
  switch (action.type) {
    case projectConstants.PROJECT_DETAILS_GET_REQUEST: {
      return {
        ...state,
        projectDetails: {
          detail: null,
          error: false,
          loading: true,
        },
      };
    }

    case projectConstants.PROJECT_DETAILS_GET_SUCCESS: {
      return {
        ...state,
        projectDetails: {
          detail: new ProjectModel(action.projectDetails.data),
          error: false,
          loading: false,
        },
      };
    }

    case projectConstants.PROJECT_DETAILS_GET_FAILURE: {
      return {
        ...state,
        projectDetails: {
          detail: null,
          error: true,
          loading: false,
        },
      };
    }

    case projectConstants.PROJECT_DETAILS_PUT_REQUEST: {
      return {
        ...state,
        updatedProjectDetails: {
          projectDetails: null,
          error: false,
          loading: true,
        },
      };
    }

    case projectConstants.PROJECT_DETAILS_PUT_SUCCESS: {
      return {
        ...state,
        updatedProjectDetails: {
          projectDetails: new ProjectModel(action.updatedProjectDetails.data),
          error: false,
          loading: false,
        },
        projectDetails: {
          detail: new ProjectModel(action.updatedProjectDetails.data),
          error: false,
          loading: false,
        },
      };
    }

    case projectConstants.PROJECT_DETAILS_PUT_FAILURE: {
      return {
        ...state,
        updatedProjectDetails: {
          projectDetails: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case projectConstants.PROJECTS_SELF_GET_REQUEST: {
      return {
        ...state,
        projectsSelf: {
          projects: projectSelfList,
          error: false,
          loading: true,
        },
      };
    }

    case projectConstants.PROJECTS_SELF_GET_SUCCESS: {
      const seenIds: number[] = [];
      projectSelfList = [];

      action.projects.data.forEach((obj: any) => {
        if (!seenIds.includes(obj.id)) {
          seenIds.push(obj.id);
          projectSelfList.push(new UserProjectModel(obj));
        }
      });

      return {
        ...state,
        projectsSelf: {
          projects: projectSelfList,
          error: false,
          loading: false,
        },
      };
    }

    case projectConstants.PROJECTS_SELF_GET_FAILURE: {
      return {
        ...state,
        projectsSelf: { projects: null, error: true, loading: false },
      };
    }

    case projectConstants.PROJECT_GET_COST_REQUEST: {
      return {
        ...state,
        projectCost: {
          cost: null,
          error: false,
          loading: true,
        },
      };
    }

    case projectConstants.PROJECT_GET_COST_SUCCESS: {
      return {
        ...state,
        projectCost: {
          cost: new ProjectCostModel(action.projectCost.data),
          error: false,
          loading: false,
        },
      };
    }

    case projectConstants.PROJECT_GET_COST_FAILURE: {
      return {
        ...state,
        projectCost: { cost: null, error: true, loading: false },
      };
    }

    case projectConstants.ARCHIVE_PROJECT_REQUEST: {
      return {
        ...state,
        projectArchived: {
          project: null,
          error: false,
          loading: true,
        },
      };
    }

    case projectConstants.ARCHIVE_PROJECT_SUCCESS: {
      return {
        ...state,
        projectArchived: {
          project: new ProjectModel(action.response.data),
          error: false,
          loading: false,
        },
      };
    }

    case projectConstants.ARCHIVE_PROJECT_FAILURE: {
      return {
        ...state,
        projectArchived: { project: null, error: true, loading: false },
      };
    }

    case projectConstants.CLEAR_PROJECT: {
      return {
        ...state,
        updatedProjectDetails: null,
        projectArchived: null,
      };
    }

    default:
      return state;
  }
};

export default project;
