import { getUrl, get, update } from '../../services';

export async function getUserSelfDetailsApi(idProject: number) {
  return await get(getUrl('self?project_id=' + idProject));
}

export async function getUserSelfProjectsApi() {
  return await get(getUrl('self/roles?isAdmin=true&order=id:DESC&limit=1000'));
}

export async function getUserProjectByProjectApi(params: any) {
  return await get(
    getUrl('users/' + params.idUser + '/projects/' + params.idProject)
  );
}

export async function putUserApi(user: any) {
  return await update(getUrl('users/' + user.id), user);
}
