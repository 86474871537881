import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  putProjectAddressSuccess,
  putProjectAddressFailure,
  getProjectAddressSuccess,
  getProjectAddressFailure,
  getOrganizationAddressSuccess,
  getOrganizationAddressFailure,
  putOrganizationAddressSuccess,
  putOrganizationAddressFailure,
} from '../actions';
import { addressesConstants } from '../constants';
import {
  putProjectAddressApi,
  getProjectAddressApi,
  getOrganizationAddressApi,
  putOrganizationAddressApi,
} from '../api';

export function* fetchPutProjectAddress(action: any): any {
  try {
    const address = yield call(putProjectAddressApi, {
      idProject: action.idProject,
      address: action.address,
    });
    yield put(putProjectAddressSuccess(address));
  } catch (error) {
    yield put(putProjectAddressFailure(error));
  }
}

export function* fetchGetProjectAddress(action: any): any {
  try {
    const projectAddress = yield call(getProjectAddressApi, {
      idProject: action.idProject,
    });
    yield put(getProjectAddressSuccess(projectAddress));
  } catch (error) {
    yield put(getProjectAddressFailure(error));
  }
}

export function* fetchGetOrganizationAddress(action: any): any {
  try {
    const address = yield call(getOrganizationAddressApi, {
      idOrganization: action.idOrganization,
    });
    yield put(getOrganizationAddressSuccess(address));
  } catch (error) {
    yield put(getOrganizationAddressFailure(error));
  }
}

export function* fetchPutOrganizationAddress(action: any): any {
  try {
    const address = yield call(putOrganizationAddressApi, {
      idOrganization: action.idOrganization,
      address: action.address,
    });
    yield put(putOrganizationAddressSuccess(address));
  } catch (error) {
    yield put(putOrganizationAddressFailure(error));
  }
}

export function* putProjectAddress() {
  yield takeLatest(
    addressesConstants.PUT_PROJECT_ADDRESS_REQUEST,
    fetchPutProjectAddress
  );
}

export function* getProjectAddress() {
  yield takeLatest(
    addressesConstants.GET_PROJECT_ADDRESS_REQUEST,
    fetchGetProjectAddress
  );
}

export function* getOrganizationAddress() {
  yield takeLatest(
    addressesConstants.GET_ORGANIZATION_ADDRESS_REQUEST,
    fetchGetOrganizationAddress
  );
}

export function* putOrganizationAddress() {
  yield takeLatest(
    addressesConstants.PUT_ORGANIZATION_ADDRESS_REQUEST,
    fetchPutOrganizationAddress
  );
}

export default function* addressesSaga() {
  yield all([
    fork(getProjectAddress),
    fork(putProjectAddress),
    fork(getOrganizationAddress),
    fork(putOrganizationAddress),
  ]);
}
