import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  postPosProductSuccess,
  postPosProductFailure,
  getPosBasketsFailure,
  getPosBasketsSuccess,
  getPosProductsFailure,
  getPosProductsSuccess,
  putFormulePositionPosSuccess,
  putFormulePositionPosFailure,
  getPosShopsSuccess,
  getPosShopsFailure,
  patchPosShopSuccess,
  patchPosShopFailure,
  openPosShopSuccess,
  openPosShopFailure,
  closePosShopSuccess,
  closePosShopFailure,
} from '../actions';
import { posConstants } from '../constants';
import {
  postPosProductApi,
  getPosBasketsApi,
  getPosProductsApi,
  putFormulePositionPosApi,
  getPosShopsApi,
  patchPosShopApi,
  openPosShopApi,
  closePosShopApi,
} from '../api';

export function* fetchGetPosBaskets(action: any): any {
  try {
    const posBaskets = yield call(getPosBasketsApi, {
      idPos: action.idPos,
      args: action.options,
    });
    yield put(getPosBasketsSuccess(posBaskets));
  } catch (error) {
    yield put(getPosBasketsFailure(error));
  }
}

export function* fetchPostPosProduct(action: any): any {
  try {
    const productLinked = yield call(postPosProductApi, {
      idPos: action.idPos,
      idProduct: action.idProduct,
    });
    yield put(postPosProductSuccess(productLinked));
  } catch (error) {
    yield put(postPosProductFailure(error));
  }
}

export function* fetchGetPosProducts(action: any): any {
  try {
    const posProducts = yield call(getPosProductsApi, {
      idPos: action.idPos,
      args: action.options,
    });
    yield put(getPosProductsSuccess(posProducts));
  } catch (error) {
    yield put(getPosProductsFailure(error));
  }
}

export function* fetchPutFormulePositionPos(action: any): any {
  try {
    const linkPosItem = yield call(putFormulePositionPosApi, {
      idPos: action.idPos,
      idFormule: action.idFormule,
      params: action.params,
    });
    yield put(putFormulePositionPosSuccess(linkPosItem));
  } catch (error) {
    yield put(putFormulePositionPosFailure(error));
  }
}

export function* fetchGetPosShops(action: any): any {
  try {
    const shops = yield call(getPosShopsApi, {
      idPos: action.idPos,
    });
    yield put(getPosShopsSuccess(shops));
  } catch (error) {
    yield put(getPosShopsFailure(error));
  }
}

export function* fetchPatchPosShop(action: any): any {
  try {
    const result = yield call(patchPosShopApi, {
      idPos: action.idPos,
      params: action.params,
    });
    yield put(patchPosShopSuccess(result));
  } catch (error) {
    yield put(patchPosShopFailure(error));
  }
}

export function* fetchOpenPosShop(action: any): any {
  try {
    const result = yield call(openPosShopApi, {
      idPos: action.idPos,
    });
    yield put(openPosShopSuccess(result));
  } catch (error) {
    yield put(openPosShopFailure(error));
  }
}

export function* fetchClosePosShop(action: any): any {
  try {
    const result = yield call(closePosShopApi, {
      idPos: action.idPos,
    });
    yield put(closePosShopSuccess(result));
  } catch (error) {
    yield put(closePosShopFailure(error));
  }
}

export function* getPosBaskets() {
  yield takeLatest(posConstants.GET_POS_BASKETS_REQUEST, fetchGetPosBaskets);
}
export function* postPosProduct() {
  yield takeLatest(posConstants.POST_POS_PRODUCT_REQUEST, fetchPostPosProduct);
}

export function* getPosProducts() {
  yield takeLatest(posConstants.GET_POS_PRODUCTS_REQUEST, fetchGetPosProducts);
}

export function* putFormulePositionPos() {
  yield takeLatest(
    posConstants.PUT_FORMULE_POSITION_POS_REQUEST,
    fetchPutFormulePositionPos
  );
}

export function* getPosShops() {
  yield takeLatest(posConstants.GET_POS_SHOPS_REQUEST, fetchGetPosShops);
}

export function* patchPosShop() {
  yield takeLatest(posConstants.PATCH_POS_SHOP_REQUEST, fetchPatchPosShop);
}

export function* openPosShop() {
  yield takeLatest(posConstants.OPEN_POS_SHOP_REQUEST, fetchOpenPosShop);
}

export function* closePosShop() {
  yield takeLatest(posConstants.CLOSE_POS_SHOP_REQUEST, fetchClosePosShop);
}

export default function* posSaga() {
  yield all([
    fork(postPosProduct),
    fork(getPosBaskets),
    fork(getPosProducts),
    fork(putFormulePositionPos),
    fork(getPosShops),
    fork(patchPosShop),
    fork(openPosShop),
    fork(closePosShop),
  ]);
}
