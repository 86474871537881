import { adminConstants } from '../constants';

export const getAdminsRequest = (projectId: number) => ({
  type: adminConstants.GET_ADMINS_REQUEST,
  projectId,
});

export const getAdminsSuccess = (admins: any) => ({
  type: adminConstants.GET_ADMINS_SUCCESS,
  admins,
});

export const getAdminsFailure = (error: any) => ({
  type: adminConstants.GET_ADMINS_FAILURE,
  error,
});

export const addAdminRequest = (projectId: number, payload: any) => ({
  type: adminConstants.ADD_ADMIN_REQUEST,
  projectId,
  payload,
});

export const addAdminSuccess = (response: any) => ({
  type: adminConstants.ADD_ADMIN_SUCCESS,
  response,
});

export const addAdminFailure = (error: any) => ({
  type: adminConstants.ADD_ADMIN_FAILURE,
  error,
});

export const deleteAdminRequest = (projectId: number, adminId: number) => ({
  type: adminConstants.DELETE_ADMIN_REQUEST,
  projectId,
  adminId,
});

export const deleteAdminSuccess = (response: any) => ({
  type: adminConstants.DELETE_ADMIN_SUCCESS,
  response,
});

export const deleteAdminFailure = (error: any) => ({
  type: adminConstants.DELETE_ADMIN_FAILURE,
  error,
});

export const resetAdmin = () => ({
  type: adminConstants.ADMIN_RESET,
});
