import { getUrl, get, post, update } from '../../services';

export async function getBasketApi(params: any) {
  return await get(getUrl('baskets/' + params.idBasket, params.args));
}

export async function getBasketItemsApi(idBasket: number) {
  return await get(getUrl('baskets/' + idBasket + '/items'));
}

export async function getBasketPaymentsApi(idBasket: number) {
  return await get(getUrl('baskets/' + idBasket + '/payments'));
}

export async function getBasketMailsApi(idBasket: number) {
  return await get(getUrl('baskets/' + idBasket + '/mails'));
}

export async function putBasketItemsApi(params: any) {
  return await update(
    getUrl('baskets/' + params.idBasket + '/items'),
    params.updatedBasketItems
  );
}

export async function getBasketNotesApi(arg: any) {
  return await get(getUrl('baskets/' + arg.idBasket + '/notes', arg.params));
}

export async function postBasketNotesApi(params: any) {
  return await post(
    getUrl('baskets/' + params.idBasket + '/notes'),
    params.note
  );
}
