import React, { useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import audience from '../../assets/images/audience/Audience.png';
import { pagesConstant } from '../../Constants/Constant';
import Translator from '../../services/translator';
import WelcomeComponent from './WelcomeComponent/WelcomeComponent';
import './SidenavContent.css';

const SidenavContent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const menuLi: any = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function () {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = closest(this, 'li');
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains('open'))
          ) {
            menuLi[j].classList.remove('open');
            menuLi[j].classList.remove('closed');
          }
        }
      };
    }
  }, []);

  useEffect(() => {
    const pathname = location.pathname; // get current path
    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element

    try {
      const activeNav = closest(activeLi, 'ul'); // select closest ul
      if (activeNav) {
        if (activeNav.classList.contains('sub-menu')) {
          closest(activeNav, 'li').classList.add('open');
        } else {
          closest(activeLi, 'li').classList.add('open');
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [location.pathname]);

  function closeAll() {
    const menuLi: any = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].classList.remove('open');
    }
  }

  function closest(el: any, selector: any) {
    try {
      let matchesFn: any;
      // find vendor prefix
      [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector',
      ].some((fn: any) => {
        // @ts-ignore
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  function handleClickTodoList(path: string) {
    closeAll();
    navigate(path);
  }

  const clickSideBar = (event: any) => {
    const element = event.target.closest('.menu');
    if (element.classList.contains('open')) {
      if (element.classList.contains('closed')) {
        element.classList.remove('closed');
      } else {
        element.classList.add('closed');
      }
    } else {
      element.classList.add('open');
    }
  };

  return (
    <div className={'nav-menu-container'}>
      <WelcomeComponent handleClick={handleClickTodoList} />

      <ul className="nav-menu">
        <div className={'menu-list'}>
          {/* Dashboard */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
                {Translator.trans('sidenav.title.dashboard')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to={pagesConstant.dashboard.ticketing}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.dashboardOnline')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={pagesConstant.dashboard.event}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.dashboardOnsite')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* CATALOG */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-collection-bookmark"></i>
              <span className="nav-text">
                {Translator.trans('sidenav.title.catalog')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to={pagesConstant.tickets.home}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.tickets')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={pagesConstant.categories}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.categories')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-arrow-split"></i>
              <span className="nav-text">
                {Translator.trans('sidenav.title.sales.channels')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to={pagesConstant.sales.online}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.sales.online')}
                  </span>
                </NavLink>
              </li>
              {/* TODO: V2 SALES CHANNELS */}
              <li className="d-none">
                <NavLink to={pagesConstant.sales.physical}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.sales.physical')}
                  </span>
                </NavLink>
              </li>
              <li className="d-none">
                <NavLink to={pagesConstant.import.manual}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.sales.manual.import')}
                  </span>
                </NavLink>
              </li>
              <li className="d-none">
                <NavLink to={pagesConstant.import.auto}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.sales.auto.import')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={pagesConstant.sales.integration}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.sales.integration')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* SALES */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-shopping-basket zmdi-hc-fw" />
              <span className="nav-text">
                {Translator.trans('sidenav.title.sales')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to={pagesConstant.orders.home}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.orders')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={pagesConstant.attendees.home}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.attendees')}
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink to={pagesConstant.barcodes}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.barcodes')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* MARKETING TODO: V2 */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-input-antenna"></i>
              <span className="nav-text">
                {Translator.trans('sidenav.title.marketing')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li className="d-none">
                {/* TODO: V2 create PROMOTION page */}
                <NavLink to={pagesConstant.marketing.promo}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.marketing.promo')}
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink to={pagesConstant.marketing.integration}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.marketing.integration')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* CONFIG */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">
                {Translator.trans('sidenav.title.config')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to={pagesConstant.events.current}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.event')}
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink to={pagesConstant.forms.home}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.forms')}
                  </span>
                </NavLink>
              </li>

              <li className={'d-none'}>
                {/* TODO: V2 create paymentMethod page */}
                <NavLink to={pagesConstant.paymentMethod}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.payment.methods')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={pagesConstant.branding.content}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.branding.content')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={pagesConstant.branding.visual}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.branding.visual')}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={pagesConstant.branding.ticket}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.branding.ticket')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* ORGANIZATION */}
          <li className="menu">
            <Button className="title-sidebar" onClick={clickSideBar}>
              <i className="zmdi zmdi-store"></i>
              <span className="nav-text">
                {Translator.trans('sidenav.title.organization')}
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to={pagesConstant.organization}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.my.organization')}
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink to={pagesConstant.administrators}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.administrators')}
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink to={pagesConstant.events.list}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.all.events')}
                  </span>
                </NavLink>
              </li>

              {/* TODO : HIDE UNTIL V2 */}
              <li style={{ display: 'none' }}>
                <NavLink to={pagesConstant.billing}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.billing')}
                  </span>
                </NavLink>
              </li>

              {/* TODO : HIDE UNTIL V2 */}
              <li style={{ display: 'none' }}>
                <NavLink to={pagesConstant.payment}>
                  <span className="nav-text">
                    {Translator.trans('sidenav.title.payment')}
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
        </div>

        <div className="footer-power-by">
          <span>powered by</span>
          <img src={audience} alt={'audience'} />
        </div>
      </ul>
    </div>
  );
};

export default SidenavContent;
