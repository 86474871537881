import { jsx as _jsx } from "react/jsx-runtime";
import MDEditor from '@uiw/react-md-editor';
/*
import {
  toolbarPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  Separator,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  headingsPlugin,
  UndoRedo,
  linkDialogPlugin,
  CreateLink,
  linkPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  thematicBreakPlugin,
  tablePlugin,
  InsertTable,
  InsertThematicBreak,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
*/
import './MarkdownEditor.css';
export const MarkdownEditor = (props) => {
    var _a;
    return (_jsx("div", { "data-color-mode": "light", children: _jsx(MDEditor, { className: 'markdown-editor-container', autoFocus: props.value === '', value: (_a = props.value) !== null && _a !== void 0 ? _a : '', onChange: (value) => (value ? props.onChange(value) : ''), height: 500 }) }));
};
