import { errorConstants } from '../constants';

const initialState: any = {
  error: null,
};

const error = (state = initialState, action: any) => {
  switch (action.type) {
    case errorConstants.POST_ERROR_SLACK_REQUEST: {
      return {
        ...state,
        error: { details: null, error: false, loading: true },
      };
    }

    case errorConstants.POST_ERROR_SLACK_SUCCESS: {
      return {
        ...state,
        error: { details: action.response, error: false, loading: false },
      };
    }

    case errorConstants.POST_ERROR_SLACK_FAILURE: {
      return {
        ...state,
        error: { details: null, error: true, loading: false },
      };
    }

    default:
      return state;
  }
};

export default error;
