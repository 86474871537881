import { contentsConstants } from '../constants';
import ContentModel from '../../Models/ContentModel';
import MailsContentModel from '../../Models/MailsContentModel';
import ContentContentModel from '../../Models/ContentContentModel';

const initialState: any = {
  contentsList: null,
  contentContents: null,
  contentMails: null,
  updatedContentMails: null,
  updatedContentContents: null,
};

let listContents: ContentModel;

const contents = (state = initialState, action: any) => {
  switch (action.type) {
    case contentsConstants.CONTENTS_GET_REQUEST: {
      return {
        ...state,
        contentsList: { contents: null, error: false, loading: true },
      };
    }

    case contentsConstants.CONTENTS_GET_SUCCESS: {
      listContents = new ContentModel(action.contents.data);
      return {
        ...state,
        contentsList: {
          contents: listContents,
          error: false,
          loading: false,
        },
      };
    }

    case contentsConstants.CONTENTS_GET_FAILURE: {
      return {
        ...state,
        contentsList: { contents: null, error: true, loading: false },
      };
    }

    case contentsConstants.GET_CONTENT_CONTENTS_REQUEST: {
      return {
        ...state,
        contentContents: { contents: null, error: false, loading: true },
      };
    }

    case contentsConstants.GET_CONTENT_CONTENTS_SUCCESS: {
      return {
        ...state,
        contentContents: {
          contents: new ContentContentModel(action.response.data),
          error: false,
          loading: false,
        },
      };
    }

    case contentsConstants.GET_CONTENT_CONTENTS_FAILURE: {
      return {
        ...state,
        contentContents: { contents: null, error: true, loading: false },
      };
    }

    case contentsConstants.GET_CONTENT_MAIL_REQUEST: {
      return {
        ...state,
        contentMails: { mail: null, error: false, loading: true },
      };
    }

    case contentsConstants.GET_CONTENT_MAIL_SUCCESS: {
      return {
        ...state,
        contentMails: {
          mail: new MailsContentModel(action.response.data),
          error: false,
          loading: false,
        },
      };
    }

    case contentsConstants.GET_CONTENT_MAIL_FAILURE: {
      return {
        ...state,
        contentMails: { mail: null, error: true, loading: false },
      };
    }

    case contentsConstants.PUT_CONTENT_MAIL_REQUEST: {
      return {
        ...state,
        updatedContentMails: { mail: null, error: false, loading: true },
      };
    }

    case contentsConstants.PUT_CONTENT_MAIL_SUCCESS: {
      return {
        ...state,
        updatedContentMails: {
          mail: new MailsContentModel(action.response.data),
          error: false,
          loading: false,
        },
      };
    }

    case contentsConstants.PUT_CONTENT_MAIL_FAILURE: {
      return {
        ...state,
        updatedContentMails: { mail: null, error: true, loading: false },
      };
    }

    case contentsConstants.PUT_CONTENT_CONTENTS_REQUEST: {
      return {
        ...state,
        updatedContentContents: { content: null, error: false, loading: true },
      };
    }

    case contentsConstants.PUT_CONTENT_CONTENTS_SUCCESS: {
      return {
        ...state,
        updatedContentContents: {
          content: new ContentContentModel(action.response.data),
          error: false,
          loading: false,
        },
      };
    }

    case contentsConstants.PUT_CONTENT_CONTENTS_FAILURE: {
      return {
        ...state,
        updatedContentContents: { content: null, error: true, loading: false },
      };
    }

    case contentsConstants.CLEAR_CONTENT: {
      return {
        ...state,
        contentContents: null,
        contentMails: null,
      };
    }

    default:
      return state;
  }
};

export default contents;
