import { organizationConstants } from '../constants';

export const getOrganizationRequest = (id: number) => ({
  type: organizationConstants.GET_ORGANIZATION_REQUEST,
  id,
});

export const getOrganizationSuccess = (organization: any) => ({
  type: organizationConstants.GET_ORGANIZATION_SUCCESS,
  organization,
});

export const getOrganizationFailure = (error: any) => ({
  type: organizationConstants.GET_ORGANIZATION_FAILURE,
  error,
});

export const putOrganizationRequest = (id: number, payload: any) => ({
  type: organizationConstants.PUT_ORGANIZATION_REQUEST,
  id,
  payload,
});

export const putOrganizationSuccess = (organization: any) => ({
  type: organizationConstants.PUT_ORGANIZATION_SUCCESS,
  organization,
});

export const putOrganizationFailure = (error: any) => ({
  type: organizationConstants.PUT_ORGANIZATION_FAILURE,
  error,
});

export const getOrganizationStatusRequest = (id: number) => ({
  type: organizationConstants.GET_ORGANIZATION_STATUS_REQUEST,
  id,
});

export const getOrganizationStatusSuccess = (organizationStatus: any) => ({
  type: organizationConstants.GET_ORGANIZATION_STATUS_SUCCESS,
  organizationStatus,
});

export const getOrganizationStatusFailure = (error: any) => ({
  type: organizationConstants.GET_ORGANIZATION_STATUS_FAILURE,
  error,
});

export const clearOrganizationRequest = () => ({
  type: organizationConstants.CLEAR_ORGANIZATION_REQUEST,
});
