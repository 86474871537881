import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { userLogOutRequest } from '../../Redux/actions';
import logoAudience from '../../assets/images/audience/logo-audience-ticketing.png';
import avatarUnknown from '../../assets/images/avatar.png';
import { removeTokenFromStorage } from '../../services/handleToken';
import { pagesConstant } from '../../Constants/Constant';
import Translator from '../../services/translator';
import { storeType } from '../../index';
import UserSelfModel from '../../Models/UserSelfModel';
import { checkReduxResponse } from '../../services/checkReduxResponse';

const UserInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState<UserSelfModel | null>(null);
  const [userImg, setUserImg] = useState(null);
  const userSelfDetails = useSelector(
    (state: storeType) => state.user.userSelfDetails
  );

  const signOut = useCallback(() => {
    navigate(pagesConstant.signIn);
    dispatch(userLogOutRequest());
    removeTokenFromStorage();
  }, [dispatch, navigate]);

  useEffect(() => {
    if (checkReduxResponse(userSelfDetails, 'details')) {
      if (userSelfDetails.details) {
        setCurrentUser(userSelfDetails.details);
      }

      if (userSelfDetails.details.pictureFullWebPath) {
        setUserImg(userSelfDetails.details.pictureFullWebPath);
      }
    }
  }, [userSelfDetails, userSelfDetails?.details]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className="background-logo">
        <Link className="app-logo" to={pagesConstant.home}>
          <img
            className="logo-powered-by"
            src={logoAudience}
            alt="logo by emisys"
          />
        </Link>
      </div>
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt="..."
          src={userImg ? userImg : avatarUnknown}
          className="user-avatar"
        />
        <div className="user-detail">
          <div className="d-flex">
            <h4 className="user-name" onClick={handleClick}>
              <span>
                {currentUser?.fullName
                  ? currentUser?.fullName
                  : currentUser?.email}
              </span>
              <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
            </h4>
          </div>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleRequestClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleRequestClose}>
            <NavLink to={pagesConstant.profile}>
              {Translator.trans('sidenav.title.my.account')}
            </NavLink>
          </MenuItem>
          <MenuItem
            onClick={signOut}
            className={'d-flex justify-content-center'}
          >
            {Translator.trans('appModule.logout')}
            <i className="zmdi zmdi-sign-in icon-alert zmdi-hc-fw" />
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default UserInfo;
