import ProjectModel from './ProjectModel';

export default class UserProjectModel {
  id: number;
  allowDataCashless: boolean;
  allowDataTicketing: boolean;
  isRegistrationComplete: boolean;
  isValid: boolean;
  isVolunteerConventionSigned: boolean;
  isVolunteerInfoComplete: boolean;
  project: ProjectModel;

  constructor(data: any) {
    this.id = data.id;
    this.allowDataCashless = data.allowDataCashless;
    this.allowDataTicketing = data.allowDataTicketing;
    this.isRegistrationComplete = data.isRegistrationComplete;
    this.isValid = data.isValid;
    this.isVolunteerConventionSigned = data.isVolunteerConventionSigned;
    this.isVolunteerInfoComplete = data.isVolunteerInfoComplete;
    this.project = new ProjectModel(data.project);
  }
}
