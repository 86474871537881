import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getFormsByProjectSuccess,
  getFormsByProjectFailure,
  getFormByIdSuccess,
  getFormByIdFailure,
  postFormSuccess,
  postFormFailure,
  putFormSuccess,
  putFormFailure,
  deleteFormSuccess,
  deleteFormFailure,
  getQuestionsByFormSuccess,
  getQuestionsByFormFailure,
  getQuestionsTypesSuccess,
  getQuestionsTypesFailure,
  postFormQuestionsSuccess,
  postFormQuestionsFailure,
  deleteFormQuestionSuccess,
  deleteFormQuestionFailure,
  getQuestionByIdSuccess,
  getQuestionByIdFailure,
  putQuestionSuccess,
  putQuestionFailure,
  putQuestionToggleRequiredSuccess,
  putQuestionToggleRequiredFailure,
  putFormQuestionsOrderSuccess,
  putFormQuestionsOrderFailure,
} from '../actions';
import { formsConstants } from '../constants';
import {
  getFormsByProjectApi,
  getFormByIdApi,
  postFormApi,
  putFormApi,
  deleteFormApi,
  getQuestionsByFormApi,
  getQuestionsTypesApi,
  postFormQuestionsApi,
  deleteFormQuestionApi,
  getQuestionByIdApi,
  putQuestionApi,
  putQuestionToggleRequiredApi,
  putFormQuestionsOrderApi,
} from '../api';

export function* fetchGetFormByProject(action: any): any {
  try {
    const forms = yield call(getFormsByProjectApi, {
      idProject: action.idProject,
    });
    yield put(getFormsByProjectSuccess(forms));
  } catch (error) {
    yield put(getFormsByProjectFailure(error));
  }
}

export function* fetchGetFormById(action: any): any {
  try {
    const currentForm = yield call(getFormByIdApi, { idForm: action.idForm });
    yield put(getFormByIdSuccess(currentForm));
  } catch (error) {
    yield put(getFormByIdFailure(error));
  }
}

export function* fetchPostForm(action: any): any {
  try {
    const newForm = yield call(postFormApi, {
      idProject: action.idProject,
      form: action.form,
    });
    yield put(postFormSuccess(newForm));
  } catch (error) {
    yield put(postFormFailure(error));
  }
}

export function* fetchPutForm(action: any): any {
  try {
    const updatedForm = yield call(putFormApi, {
      idForm: action.idForm,
      form: action.form,
    });
    yield put(putFormSuccess(updatedForm));
  } catch (error) {
    yield put(putFormFailure(error));
  }
}

export function* fetchDeleteForm(action: any): any {
  try {
    yield call(deleteFormApi, action.idForm);
    yield put(deleteFormSuccess());
  } catch (error) {
    yield put(deleteFormFailure(error));
  }
}

export function* fetchGetQuestionsByForm(action: any): any {
  try {
    const questions = yield call(getQuestionsByFormApi, {
      idForm: action.idForm,
    });
    yield put(getQuestionsByFormSuccess(questions));
  } catch (error) {
    yield put(getQuestionsByFormFailure(error));
  }
}

export function* fetchGetQuestionsTypes(): any {
  try {
    const questionsTypes = yield call(getQuestionsTypesApi);
    yield put(getQuestionsTypesSuccess(questionsTypes));
  } catch (error) {
    yield put(getQuestionsTypesFailure(error));
  }
}

export function* fetchPostFormQuestions(action: any): any {
  try {
    const newQuestions = yield call(postFormQuestionsApi, {
      idForm: action.idForm,
      questions: action.questions,
    });
    yield put(postFormQuestionsSuccess(newQuestions));
  } catch (error) {
    yield put(postFormQuestionsFailure(error));
  }
}

export function* fetchDeleteFormQuestion(action: any): any {
  try {
    yield call(deleteFormQuestionApi, action.idQuestion);
    yield put(deleteFormQuestionSuccess());
  } catch (error) {
    yield put(deleteFormQuestionFailure(error));
  }
}

export function* fetchGetQuestionById(action: any): any {
  try {
    const currentQuestion = yield call(getQuestionByIdApi, {
      idQuestion: action.idQuestion,
    });
    yield put(getQuestionByIdSuccess(currentQuestion));
  } catch (error) {
    yield put(getQuestionByIdFailure(error));
  }
}

export function* fetchPutQuestion(action: any): any {
  try {
    const updatedQuestion = yield call(putQuestionApi, {
      idQuestion: action.idQuestion,
      question: action.question,
    });
    yield put(putQuestionSuccess(updatedQuestion));
  } catch (error) {
    yield put(putQuestionFailure(error));
  }
}

export function* fetchPutQuestionToggleRequired(action: any): any {
  try {
    const updatedQuestionToggleRequired = yield call(
      putQuestionToggleRequiredApi,
      { idQuestion: action.idQuestion }
    );
    yield put(putQuestionToggleRequiredSuccess(updatedQuestionToggleRequired));
  } catch (error) {
    yield put(putQuestionToggleRequiredFailure(error));
  }
}

export function* fetchPutFormQuestionsOrder(action: any): any {
  try {
    const response = yield call(putFormQuestionsOrderApi, {
      idForm: action.idForm,
      questionsOrder: action.questionsOrder,
    });
    yield put(putFormQuestionsOrderSuccess(response));
  } catch (error) {
    yield put(putFormQuestionsOrderFailure(error));
  }
}

export function* getFormsByProject() {
  yield takeLatest(
    formsConstants.FORMS_GET_BY_PROJECT_REQUEST,
    fetchGetFormByProject
  );
}

export function* getFormById() {
  yield takeLatest(formsConstants.FORM_GET_BY_ID_REQUEST, fetchGetFormById);
}

export function* postForm() {
  yield takeLatest(formsConstants.FORM_POST_REQUEST, fetchPostForm);
}

export function* putForm() {
  yield takeLatest(formsConstants.FORM_PUT_REQUEST, fetchPutForm);
}

export function* deleteForm() {
  yield takeLatest(formsConstants.FORM_DELETE_REQUEST, fetchDeleteForm);
}

export function* getQuestionsByForm() {
  yield takeLatest(
    formsConstants.QUESTIONS_GET_BY_FORM_REQUEST,
    fetchGetQuestionsByForm
  );
}

export function* getQuestionsTypes() {
  yield takeLatest(
    formsConstants.QUESTIONS_GET_TYPES_REQUEST,
    fetchGetQuestionsTypes
  );
}

export function* postFormQuestions() {
  yield takeLatest(
    formsConstants.FORM_POST_QUESTIONS_REQUEST,
    fetchPostFormQuestions
  );
}

export function* deleteFormQuestion() {
  yield takeLatest(
    formsConstants.FORM_DELETE_QUESTION_REQUEST,
    fetchDeleteFormQuestion
  );
}

export function* getQuestionById() {
  yield takeLatest(
    formsConstants.QUESTION_GET_BY_ID_REQUEST,
    fetchGetQuestionById
  );
}

export function* putQuestion() {
  yield takeLatest(formsConstants.QUESTION_PUT_REQUEST, fetchPutQuestion);
}

export function* putQuestionToggleRequired() {
  yield takeLatest(
    formsConstants.QUESTION_PUT_TOGGLE_REQUIRED_REQUEST,
    fetchPutQuestionToggleRequired
  );
}

export function* putFormQuestionsOrder() {
  yield takeLatest(
    formsConstants.FORM_PUT_QUESTIONS_ORDER_REQUEST,
    fetchPutFormQuestionsOrder
  );
}

export default function* formsSaga() {
  yield all([
    fork(getFormsByProject),
    fork(getFormById),
    fork(postForm),
    fork(putForm),
    fork(deleteForm),
    fork(getQuestionsByForm),
    fork(getQuestionsTypes),
    fork(postFormQuestions),
    fork(deleteFormQuestion),
    fork(getQuestionById),
    fork(putQuestion),
    fork(putQuestionToggleRequired),
    fork(putFormQuestionsOrder),
  ]);
}
