export default class BarcodeReportModel {
  count: number;
  dateEnd: string;
  dateStart: string;
  value: number;

  constructor(data: any) {
    this.count = data.count;
    this.dateEnd = data.dateEnd;
    this.dateStart = data.dateStart;
    this.value = data.value;
  }
}
