import { getUrl, get, update } from '../../services';

export async function getOrganizationApi(params: any) {
  return await get(getUrl('organizations/' + params.id));
}

export async function putOrganizationApi(params: any) {
  return await update(getUrl('organizations/' + params.id), params.payload);
}

export async function getOrganizationStatusApi(params: any) {
  return await get(getUrl('organizations/' + params.id + '/status'));
}
