var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { DragAndDrop } from '../../index';
import 'react-image-crop/dist/ReactCrop.css';
import './ImageCropper.css';
export const ImageCropper = (props) => {
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState(undefined);
    const imageRef = useRef(null);
    const fileUrl = useRef('');
    const onCropChange = useCallback((crop) => {
        setCrop(crop);
    }, []);
    const initiateCrop = useCallback(() => {
        let newAspect = 1;
        if (props.aspect === 'portrait') {
            newAspect = 0.71;
        }
        if (props.aspect === 'landscape') {
            newAspect = 1.7;
        }
        onCropChange({
            unit: '%',
            height: 100,
            aspect: newAspect,
            x: 0,
            y: 0,
        });
    }, [onCropChange, props.aspect]);
    useEffect(() => {
        initiateCrop();
    }, [initiateCrop]);
    const onSelectFile = (image) => {
        if (image) {
            setSrc(image.src);
        }
        else {
            setSrc(null);
        }
    };
    // If you setState the crop in here you should return false.
    const onImageLoaded = useCallback((image) => {
        imageRef.current = image;
    }, []);
    const handleNewPicture = useCallback((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            props.handlePicture(reader.result);
        };
    }, [props]);
    const getCroppedImg = useCallback((image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
        ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    // reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                handleNewPicture(blob);
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () { };
                blob.name = fileName;
                window.URL.revokeObjectURL(fileUrl.current);
                fileUrl.current = window.URL.createObjectURL(blob);
                resolve(fileUrl.current);
            }, 'image/jpeg');
        });
    }, [handleNewPicture]);
    const makeClientCrop = useCallback((crop) => __awaiter(void 0, void 0, void 0, function* () {
        if (imageRef.current && crop.width && crop.height) {
            yield getCroppedImg(imageRef.current, crop, 'newFile.jpeg');
        }
    }), [getCroppedImg]);
    const onCropComplete = useCallback((crop) => {
        makeClientCrop(crop).then();
    }, [makeClientCrop]);
    const removeImage = () => {
        onSelectFile(null);
        onImageLoaded(null);
        props.handlePicture(null);
        initiateCrop();
    };
    return (_jsx("div", { children: !src ? (_jsx(DragAndDrop, { labelText: props.labelText, name: 'imageCropper', onChange: onSelectFile })) : (_jsxs("div", { className: 'cropperContainer', children: [_jsx(ReactCrop, { src: src, crop: crop, ruleOfThirds: true, onImageLoaded: onImageLoaded, onComplete: onCropComplete, onChange: onCropChange }), _jsx(IconButton, { className: 'iconRemoveImg', onClick: removeImage, children: _jsx(CancelIcon, {}) })] })) }));
};
