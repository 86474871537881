import { vatRatesConstants } from '../constants';
import VatRateModel from '../../Models/VatRateModel';

const initialState: any = {
  vatRatesList: null,
};

let listVatRates: VatRateModel[] = [];
const vatRates = (state = initialState, action: any) => {
  switch (action.type) {
    case vatRatesConstants.VAT_RATES_GET_REQUEST: {
      return {
        ...state,
        vatRatesList: { vatRates: listVatRates, error: false, loading: true },
      };
    }

    case vatRatesConstants.VAT_RATES_GET_SUCCESS: {
      listVatRates = action.vatRates.data.map(
        (vat: any) => new VatRateModel(vat)
      );

      return {
        ...state,
        vatRatesList: {
          vatRates: listVatRates,
          error: false,
          loading: false,
        },
      };
    }

    case vatRatesConstants.VAT_RATES_GET_FAILURE: {
      return {
        ...state,
        vatRatesList: { vatRates: null, error: true, loading: false },
      };
    }

    default:
      return state;
  }
};

export default vatRates;
