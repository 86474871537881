import { attendeesConstants } from '../constants';
import AttendeeModel from '../../Models/AttendeeModel';
import UserSelfModel from '../../Models/UserSelfModel';
import MailModel from '../../Models/MailModel';
import BasketModel from '../../Models/BasketModel';
import BarcodeModel from '../../Models/BarcodeModel';
import NfcTagModel from '../../Models/NfcTagModel';
import NoteModel from '../../Models/NoteModel';

const initialState: any = {
  attendeesList: null,
  currentAttendee: null,
  nfcTagList: null,
  barcodesList: null,
  basketsList: null,
  notesList: null,
  mailsList: null,
  newNote: null,
};

let listAttendee: AttendeeModel[] = [];
let listNotes: any[] = [];

const attendees = (state = initialState, action: any) => {
  switch (action.type) {
    case attendeesConstants.ATTENDEES_GET_BY_PROJECT_REQUEST: {
      return {
        ...state,
        attendeesList: { attendees: listAttendee, error: false, loading: true },
      };
    }

    case attendeesConstants.ATTENDEES_GET_BY_PROJECT_SUCCESS: {
      listAttendee = action.attendees.data.map(
        (attendee: any) => new AttendeeModel(attendee)
      );
      return {
        ...state,
        attendeesList: {
          attendees: listAttendee,
          meta: action.attendees.meta,
          error: false,
          loading: false,
        },
      };
    }

    case attendeesConstants.ATTENDEES_GET_BY_PROJECT_FAILURE: {
      return {
        ...state,
        attendeesList: { attendees: null, error: true, loading: false },
      };
    }

    case attendeesConstants.ATTENDEE_GET_BY_ID_REQUEST: {
      return {
        ...state,
        currentAttendee: { attendee: null, error: false, loading: true },
      };
    }

    case attendeesConstants.ATTENDEE_GET_BY_ID_SUCCESS: {
      return {
        ...state,
        currentAttendee: {
          attendee: new UserSelfModel(action.attendee.data),
          error: false,
          loading: false,
        },
      };
    }

    case attendeesConstants.ATTENDEE_GET_BY_ID_FAILURE: {
      return {
        ...state,
        currentAttendee: { attendee: null, error: true, loading: false },
      };
    }

    case attendeesConstants.ATTENDEE_GET_NFCTAG_REQUEST: {
      return {
        ...state,
        nfcTagList: { nfcTags: null, error: false, loading: true },
      };
    }

    case attendeesConstants.ATTENDEE_GET_NFCTAG_SUCCESS: {
      const list = action.nfcTags.data.map((nfc: any) => new NfcTagModel(nfc));
      return {
        ...state,
        nfcTagList: {
          nfcTags: list,
          error: false,
          loading: false,
        },
      };
    }

    case attendeesConstants.ATTENDEE_GET_NFCTAG_FAILURE: {
      return {
        ...state,
        nfcTagList: { nfcTags: null, error: true, loading: false },
      };
    }

    case attendeesConstants.ATTENDEE_GET_BARCODES_REQUEST: {
      return {
        ...state,
        barcodesList: { barcodes: null, error: false, loading: true },
      };
    }

    case attendeesConstants.ATTENDEE_GET_BARCODES_SUCCESS: {
      const barcodes = action.barcodes.data.map(
        (barcode: any) => new BarcodeModel(barcode)
      );
      return {
        ...state,
        barcodesList: {
          barcodes: barcodes,
          error: false,
          loading: false,
        },
      };
    }

    case attendeesConstants.ATTENDEE_GET_BARCODES_FAILURE: {
      return {
        ...state,
        barcodesList: { barcodes: null, error: true, loading: false },
      };
    }

    case attendeesConstants.ATTENDEE_GET_BASKETS_REQUEST: {
      return {
        ...state,
        basketsList: { baskets: null, error: false, loading: true },
      };
    }

    case attendeesConstants.ATTENDEE_GET_BASKETS_SUCCESS: {
      const baskets = action.baskets.data.map(
        (basket: any) => new BasketModel(basket)
      );
      return {
        ...state,
        basketsList: {
          baskets: baskets,
          error: false,
          loading: false,
        },
      };
    }

    case attendeesConstants.ATTENDEE_GET_BASKETS_FAILURE: {
      return {
        ...state,
        basketsList: { baskets: null, error: true, loading: false },
      };
    }

    case attendeesConstants.ATTENDEE_GET_NOTES_REQUEST: {
      return {
        ...state,
        notesList: { notes: listNotes, error: false, loading: true },
      };
    }

    case attendeesConstants.ATTENDEE_GET_NOTES_SUCCESS: {
      listNotes = action.notes.data.map((note: any) => new NoteModel(note));
      return {
        ...state,
        notesList: { notes: listNotes, error: false, loading: false },
      };
    }

    case attendeesConstants.ATTENDEE_GET_NOTES_FAILURE: {
      return {
        ...state,
        notesList: { notes: null, error: true, loading: false },
      };
    }

    case attendeesConstants.ATTENDEE_GET_MAILS_REQUEST: {
      return {
        ...state,
        mailsList: { mails: null, error: false, loading: true },
      };
    }

    case attendeesConstants.ATTENDEE_GET_MAILS_SUCCESS: {
      const mailList = action.mails.data.map(
        (mail: any) => new MailModel(mail)
      );
      return {
        ...state,
        mailsList: { mails: mailList, error: false, loading: false },
      };
    }

    case attendeesConstants.ATTENDEE_GET_MAILS_FAILURE: {
      return {
        ...state,
        mailsList: { mails: null, error: true, loading: false },
      };
    }

    case attendeesConstants.ATTENDEE_POST_NOTES_REQUEST: {
      return {
        ...state,
        newNote: { note: null, error: false, loading: true },
      };
    }

    case attendeesConstants.ATTENDEE_POST_NOTES_SUCCESS: {
      return {
        ...state,
        newNote: { note: action.response.data, error: false, loading: false },
      };
    }

    case attendeesConstants.ATTENDEE_POST_NOTES_FAILURE: {
      return {
        ...state,
        newNote: { note: null, error: true, loading: false },
      };
    }

    default:
      return state;
  }
};

export default attendees;
