import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getUserSelfDetailsSuccess,
  getUserSelfDetailsFailure,
  getUserSelfProjectsSuccess,
  getUserSelfProjectsFailure,
  getUserProjectByProjectSuccess,
  getUserProjectByProjectFailure,
  putUserSuccess,
  putUserFailure,
} from '../actions';
import { userConstants } from '../constants';
import {
  getUserSelfDetailsApi,
  getUserSelfProjectsApi,
  getUserProjectByProjectApi,
  putUserApi,
} from '../api';

export function* fetchUserSelfDetails(action: any): any {
  try {
    const userSelfDetails = yield call(getUserSelfDetailsApi, action.idProject);
    yield put(getUserSelfDetailsSuccess(userSelfDetails));
  } catch (error) {
    yield put(getUserSelfDetailsFailure(error));
  }
}

export function* fetchUserSelfProjects(): any {
  try {
    const userSelfProjects = yield call(getUserSelfProjectsApi);
    yield put(getUserSelfProjectsSuccess(userSelfProjects));
  } catch (error) {
    yield put(getUserSelfProjectsFailure(error));
  }
}

export function* fetchGetUserProjectByProject(action: any): any {
  try {
    const userProject = yield call(getUserProjectByProjectApi, {
      idProject: action.idProject,
      idUser: action.idUser,
    });
    yield put(getUserProjectByProjectSuccess(userProject));
  } catch (error) {
    yield put(getUserProjectByProjectFailure(error));
  }
}

export function* fetchPutUser(action: any): any {
  try {
    const userUpdate = yield call(putUserApi, action.user);
    yield put(putUserSuccess(userUpdate));
  } catch (error) {
    yield put(putUserFailure(error));
  }
}

export function* getUserSelfDetails() {
  yield takeLatest(
    userConstants.USER_SELF_DETAILS_GET_REQUEST,
    fetchUserSelfDetails
  );
}

export function* getUserSelfProjects() {
  yield takeLatest(
    userConstants.USER_SELF_PROJECTS_GET_REQUEST,
    fetchUserSelfProjects
  );
}

export function* getUserProjectByProject() {
  yield takeLatest(
    userConstants.USERS_GET_USER_PROJECT_REQUEST,
    fetchGetUserProjectByProject
  );
}

export function* putUser() {
  yield takeLatest(userConstants.USER_PUT_REQUEST, fetchPutUser);
}

export default function* userSaga() {
  yield all([
    fork(getUserSelfDetails),
    fork(getUserSelfProjects),
    fork(getUserProjectByProject),
    fork(putUser),
  ]);
}
