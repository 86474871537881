import { formsConstants } from '../constants';
import FormModel from '../../Models/FormModel';
import QuestionsTypesModel from '../../Models/QuestionsTypesModel';
import QuestionModel from '../../Models/QuestionModel';

const initialState: any = {
  formsList: null,
  currentForm: null,
  newForm: null,
  newQuestions: null,
  updatedForm: null,
  deletedForm: null,
  deletedQuestion: null,
  questionList: null,
  questionsTypes: null,
  currentQuestion: null,
  updatedQuestion: null,
  updatedQuestionToggleRequired: null,
  updatedFormQuestionsOrder: null,
};

let listForms: FormModel[] = [];
let listQuestions: any[] = [];
let listTypes: QuestionsTypesModel;
let listForm: FormModel;

const forms = (state = initialState, action: any) => {
  switch (action.type) {
    case formsConstants.FORMS_GET_BY_PROJECT_REQUEST: {
      return {
        ...state,
        formsList: { forms: listForms, error: false, loading: true },
      };
    }

    case formsConstants.FORMS_GET_BY_PROJECT_SUCCESS: {
      listForms = action.forms.data.map((form: any) => new FormModel(form));

      return {
        ...state,
        formsList: { forms: listForms, error: false, loading: false },
      };
    }

    case formsConstants.FORMS_GET_BY_PROJECT_FAILURE: {
      return {
        ...state,
        formsList: { forms: [], error: action.error, loading: false },
      };
    }

    case formsConstants.FORM_GET_BY_ID_REQUEST: {
      return {
        ...state,
        currentForm: { form: listForm, error: false, loading: true },
      };
    }

    case formsConstants.FORM_GET_BY_ID_SUCCESS: {
      listForm = new FormModel(action.form.data);
      return {
        ...state,
        currentForm: { form: listForm, error: false, loading: false },
      };
    }

    case formsConstants.FORM_GET_BY_ID_FAILURE: {
      return {
        ...state,
        currentForm: { form: [], error: action.error, loading: false },
      };
    }

    case formsConstants.FORM_POST_REQUEST: {
      return {
        ...state,
        newForm: { form: null, error: false, loading: true },
      };
    }

    case formsConstants.FORM_POST_SUCCESS: {
      return {
        ...state,
        newForm: { form: action.newForm.data, error: false, loading: false },
      };
    }

    case formsConstants.FORM_POST_FAILURE: {
      return {
        ...state,
        newForm: { form: null, error: true, loading: false },
      };
    }

    case formsConstants.FORM_PUT_REQUEST: {
      return {
        ...state,
        updatedForm: { form: null, error: false, loading: true },
      };
    }

    case formsConstants.FORM_PUT_SUCCESS: {
      return {
        ...state,
        updatedForm: {
          form: action.updatedForm.data,
          error: false,
          loading: false,
        },
      };
    }

    case formsConstants.FORM_PUT_FAILURE: {
      return {
        ...state,
        updatedForm: { form: null, error: true, loading: false },
      };
    }

    case formsConstants.FORM_DELETE_REQUEST: {
      return {
        ...state,
        deletedForm: { form: null, error: false, loading: true },
      };
    }

    case formsConstants.FORM_DELETE_SUCCESS: {
      return {
        ...state,
        deletedForm: {
          form: true,
          error: false,
          loading: false,
        },
      };
    }

    case formsConstants.FORM_DELETE_FAILURE: {
      return {
        ...state,
        deletedForm: { form: null, error: true, loading: false },
      };
    }

    case formsConstants.QUESTIONS_GET_BY_FORM_REQUEST: {
      return {
        ...state,
        questionList: { questions: null, error: false, loading: true },
      };
    }

    case formsConstants.QUESTIONS_GET_BY_FORM_SUCCESS: {
      listQuestions = action.questionList.data;

      return {
        ...state,
        questionList: {
          questions: listQuestions,
          error: false,
          loading: false,
        },
      };
    }

    case formsConstants.QUESTIONS_GET_BY_FORM_FAILURE: {
      return {
        ...state,
        questionList: { questions: null, error: true, loading: false },
      };
    }

    case formsConstants.QUESTIONS_GET_TYPES_REQUEST: {
      return {
        ...state,
        questionsTypes: { types: null, error: false, loading: true },
      };
    }

    case formsConstants.QUESTIONS_GET_TYPES_SUCCESS: {
      listTypes = new QuestionsTypesModel(action.questionsTypes.data);

      return {
        ...state,
        questionsTypes: {
          types: listTypes,
          error: false,
          loading: false,
        },
      };
    }

    case formsConstants.QUESTIONS_GET_TYPES_FAILURE: {
      return {
        ...state,
        questionsTypes: { types: null, error: true, loading: false },
      };
    }

    case formsConstants.FORM_POST_QUESTIONS_REQUEST: {
      return {
        ...state,
        newQuestions: { questions: null, error: false, loading: true },
      };
    }

    case formsConstants.FORM_POST_QUESTIONS_SUCCESS: {
      return {
        ...state,
        newQuestions: {
          questions: action.newQuestions.data,
          error: false,
          loading: false,
        },
      };
    }

    case formsConstants.FORM_POST_QUESTIONS_FAILURE: {
      return {
        ...state,
        newQuestions: { questions: null, error: true, loading: false },
      };
    }

    case formsConstants.FORM_DELETE_QUESTION_REQUEST: {
      return {
        ...state,
        deletedQuestion: { question: null, error: false, loading: true },
      };
    }

    case formsConstants.FORM_DELETE_QUESTION_SUCCESS: {
      return {
        ...state,
        deletedQuestion: {
          question: true,
          error: false,
          loading: false,
        },
      };
    }

    case formsConstants.FORM_DELETE_QUESTION_FAILURE: {
      return {
        ...state,
        deletedQuestion: { question: null, error: true, loading: false },
      };
    }

    case formsConstants.QUESTION_GET_BY_ID_REQUEST: {
      return {
        ...state,
        currentQuestion: { question: null, error: false, loading: true },
      };
    }

    case formsConstants.QUESTION_GET_BY_ID_SUCCESS: {
      return {
        ...state,
        currentQuestion: {
          question: new QuestionModel(action.question.data),
          error: false,
          loading: false,
        },
      };
    }

    case formsConstants.QUESTION_GET_BY_ID_FAILURE: {
      return {
        ...state,
        currentQuestion: {
          question: null,
          error: action.error,
          loading: false,
        },
      };
    }

    case formsConstants.QUESTION_PUT_REQUEST: {
      return {
        ...state,
        updatedQuestion: { question: null, error: false, loading: true },
      };
    }

    case formsConstants.QUESTION_PUT_SUCCESS: {
      return {
        ...state,
        updatedQuestion: {
          question: action.updatedQuestion.data,
          error: false,
          loading: false,
        },
      };
    }

    case formsConstants.QUESTION_PUT_FAILURE: {
      return {
        ...state,
        updatedQuestion: { question: null, error: true, loading: false },
      };
    }

    case formsConstants.QUESTION_PUT_TOGGLE_REQUIRED_REQUEST: {
      return {
        ...state,
        updatedQuestionToggleRequired: {
          question: null,
          error: false,
          loading: true,
        },
      };
    }

    case formsConstants.QUESTION_PUT_TOGGLE_REQUIRED_SUCCESS: {
      return {
        ...state,
        updatedQuestionToggleRequired: {
          question: action.updatedQuestionToggleRequired.data,
          error: false,
          loading: false,
        },
      };
    }

    case formsConstants.QUESTION_PUT_TOGGLE_REQUIRED_FAILURE: {
      return {
        ...state,
        updatedQuestionToggleRequired: {
          question: null,
          error: true,
          loading: false,
        },
      };
    }

    case formsConstants.FORM_PUT_QUESTIONS_ORDER_REQUEST: {
      return {
        ...state,
        updatedFormQuestionsOrder: { form: null, error: false, loading: true },
      };
    }

    case formsConstants.FORM_PUT_QUESTIONS_ORDER_SUCCESS: {
      return {
        ...state,
        updatedFormQuestionsOrder: {
          form: true,
          error: false,
          loading: false,
        },
      };
    }

    case formsConstants.FORM_PUT_QUESTIONS_ORDER_FAILURE: {
      return {
        ...state,
        updatedFormQuestionsOrder: { form: null, error: true, loading: false },
      };
    }

    case formsConstants.CLEAR_FORM_REQUEST: {
      return {
        ...state,
        formsList: null,
        newForm: null,
        newQuestions: null,
        updatedForm: null,
        deletedForm: null,
        deletedQuestion: null,
        questionList: null,
        questionsTypes: null,
        currentQuestion: null,
        updatedQuestion: null,
        updatedQuestionToggleRequired: null,
        updatedFormQuestionsOrder: null,
      };
    }

    default:
      return state;
  }
};

export default forms;
