import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import './AccordionContainer.css';
const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        borderBottom: 'none !important',
        margin: '0 !important',
        '&:before': {
            display: 'none',
        },
    },
    expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
    root: {
        display: 'flex !important',
        borderBottom: '1px solid #eee !important',
        padding: 0,
        minHeight: '50px !important',
    },
    expanded: {
        margin: 0,
    },
})(MuiAccordionSummary);
export const AccordionContainer = (props) => (_jsxs(Accordion, { defaultExpanded: props.isExpand, square: true, children: [props.showCollapse && (_jsx(AccordionSummary, { className: 'accordion-header-container', expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel1a-content", id: "panel1a-header", children: _jsxs(Typography, { children: [props.title, props.showOptionalOrNot && (_jsx("span", { className: "optional-container", children: props.isExpand ? props.requiredLabel : props.optionalLabel }))] }) })), _jsx(MuiAccordionDetails, { children: props.children })] }));
