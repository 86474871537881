import { getUrl, get, del, post, update } from '../../services';

export async function deleteCategoryApi(idCategory: number) {
  return await del(getUrl('categories/' + idCategory));
}

export async function getCategoryTranslationsApi(idCategory: number) {
  return await get(getUrl('categories/' + idCategory + '/translations'));
}

export async function getCategoriesByProjectApi(params: any) {
  return await get(
    getUrl('projects/' + params.idProject + '/categories', params.options)
  );
}

export async function postCategoryByProjectApi(data: any) {
  return await post(
    getUrl('projects/' + data.idProject + '/categories'),
    data.newCategory
  );
}

export async function putCategoryTranslationsApi(params: any) {
  return await update(
    getUrl('categories/' + params.idCategory + '/translations'),
    params.updatedCategoryTranslations
  );
}

export async function postCategoryTranslationsApi(params: any) {
  return await post(
    getUrl('categories/' + params.idCategory + '/translations'),
    params.categoryTranslations
  );
}

export async function postCategoryProductApi(data: any) {
  return await post(
    getUrl('categories/' + data.idCategory + '/products/' + data.idProduct)
  );
}
