import { currentProject } from '../Global/currentProject';

export type CategoryTranslationType = {
  id?: number;
  locale: string;
  name: string;
  description: string;
};

export default class CategoryModel {
  id: number;
  name: string;
  translations: CategoryTranslationType[];

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.translations = this.createCategoryTranslations(data.translations);
  }

  private createCategoryTranslations = (
    translations: any
  ): CategoryTranslationType[] => {
    let allTranslations: CategoryTranslationType[] = [];
    currentProject.allowedLanguages.forEach((language: string) => {
      const found = translations.find(
        (translation: any) => translation.locale === language
      );

      if (found) {
        allTranslations.push(found);
      } else {
        allTranslations.push({
          locale: language,
          name: '',
          description: '',
        });
      }
    });
    return allTranslations;
  };
}
