import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { ticketConstants } from '../constants';
import {
  getTicketsByProjectSuccess,
  getTicketsByProjectFailure,
  getTicketSuccess,
  getTicketFailure,
  putTicketSuccess,
  putTicketFailure,
  postTicketSuccess,
  postTicketFailure,
  putTicketTranslationsSuccess,
  putTicketTranslationsFailure,
  putTicketCategoriesSuccess,
  putTicketCategoriesFailure,
  deleteTicketSuccess,
  deleteTicketFailure,
} from '../actions';
import {
  getTicketApi,
  getTicketsByProjectApi,
  putTicketCategoriesApi,
  postTicketApi,
  putTicketApi,
  putTicketTranslationsApi,
  deleteTicketApi,
} from '../api';

export function* fetchAllTicketsProject(action: any): any {
  try {
    const tickets = yield call(getTicketsByProjectApi, {
      idProject: action.idProject,
      args: action.options,
    });
    yield put(getTicketsByProjectSuccess(tickets));
  } catch (error) {
    yield put(getTicketsByProjectFailure(error));
  }
}

export function* fetchPutTicket(action: any): any {
  try {
    const updatedTicket = yield call(putTicketApi, action.ticket);
    yield put(putTicketSuccess(updatedTicket));
  } catch (error) {
    yield put(putTicketFailure(error));
  }
}

export function* fetchPostTicket(action: any): any {
  try {
    const newTicket = yield call(postTicketApi, {
      idProject: action.idProject,
      ticket: action.ticket,
    });
    yield put(postTicketSuccess(newTicket));
  } catch (error) {
    yield put(postTicketFailure(error));
  }
}

export function* fetchGetTicket(action: any): any {
  try {
    const ticket = yield call(getTicketApi, { id: action.id });
    yield put(getTicketSuccess(ticket));
  } catch (error) {
    yield put(getTicketFailure(error));
  }
}

export function* fetchPutTicketTranslations(action: any): any {
  try {
    const ticketTranslations = yield call(putTicketTranslationsApi, {
      id: action.id,
      translations: action.translations,
    });
    yield put(putTicketTranslationsSuccess(ticketTranslations));
  } catch (error) {
    yield put(putTicketTranslationsFailure(error));
  }
}

export function* fetchPutTicketCategories(action: any): any {
  try {
    const ticketCategories = yield call(putTicketCategoriesApi, {
      id: action.idTicket,
      categories: action.categories,
    });
    yield put(putTicketCategoriesSuccess(ticketCategories));
  } catch (error) {
    yield put(putTicketCategoriesFailure(error));
  }
}

export function* fetchDeleteTicket(action: any): any {
  try {
    const deletedTicket = yield call(deleteTicketApi, { id: action.idTicket });
    yield put(deleteTicketSuccess(deletedTicket));
  } catch (error) {
    yield put(deleteTicketFailure(error));
  }
}

export function* getAllProject() {
  yield takeLatest(
    ticketConstants.TICKETS_GET_BY_PROJECT_REQUEST,
    fetchAllTicketsProject
  );
}

export function* putTicket() {
  yield takeLatest(ticketConstants.PUT_TICKET_REQUEST, fetchPutTicket);
}

export function* postTicket() {
  yield takeLatest(ticketConstants.POST_TICKET_REQUEST, fetchPostTicket);
}

export function* getTicket() {
  yield takeLatest(ticketConstants.GET_TICKET_REQUEST, fetchGetTicket);
}

export function* putTicketTranslations() {
  yield takeLatest(
    ticketConstants.PUT_TICKET_TRANSLATIONS_REQUEST,
    fetchPutTicketTranslations
  );
}

export function* putTicketCategories() {
  yield takeLatest(
    ticketConstants.PUT_TICKET_CATEGORIES_REQUEST,
    fetchPutTicketCategories
  );
}

export function* deleteTicket() {
  yield takeLatest(ticketConstants.DELETE_TICKET_REQUEST, fetchDeleteTicket);
}

export default function* ticketsSaga() {
  yield all([
    fork(getAllProject),
    fork(getTicket),
    fork(putTicket),
    fork(postTicket),
    fork(putTicketTranslations),
    fork(putTicketCategories),
    fork(deleteTicket),
  ]);
}
