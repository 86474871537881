import QuestionOptionModel from './QuestionOptionModel';

export default class QuestionsTypesModel {
  custom: QuestionItemModel[];
  predefined: QuestionItemModel[];

  constructor(data: any) {
    this.custom = data.custom.map(
      (question: any) => new QuestionItemModel(question)
    );
    this.predefined = data.predefined.map(
      (question: any) => new QuestionItemModel(question)
    );
  }
}

export class QuestionItemModel {
  class: string;
  name: string;
  type: string;
  options: QuestionOptionModel | null;

  constructor(data: any) {
    this.class = data.class;
    this.name = data.name;
    this.type = data.type;
    this.options = data.options ?? null;
  }
}
