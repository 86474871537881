import { organizationConstants } from '../constants';
import OrganizationModel from '../../Models/OrganizationModel';
import ErrorModel from '../../Models/ErrorModel';
import OrganizationStatusModel from '../../Models/OrganizationStatusModel';

const initialState: any = {
  detail: null,
  updated: null,
  organizationStatus: null,
};

const organization = (state = initialState, action: any) => {
  switch (action.type) {
    case organizationConstants.GET_ORGANIZATION_REQUEST: {
      return {
        ...state,
        detail: { organization: null, error: false, loading: true },
      };
    }

    case organizationConstants.GET_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        detail: {
          organization: new OrganizationModel(action.organization.data),
          error: false,
          loading: false,
        },
      };
    }

    case organizationConstants.GET_ORGANIZATION_FAILURE: {
      return {
        ...state,
        detail: { organization: null, error: true, loading: false },
      };
    }

    case organizationConstants.PUT_ORGANIZATION_REQUEST: {
      return {
        ...state,
        updated: { organization: null, error: false, loading: true },
      };
    }

    case organizationConstants.PUT_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        updated: {
          organization: true,
          error: false,
          loading: false,
        },
      };
    }

    case organizationConstants.PUT_ORGANIZATION_FAILURE: {
      return {
        ...state,
        updated: {
          organization: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case organizationConstants.GET_ORGANIZATION_STATUS_REQUEST: {
      return {
        ...state,
        organizationStatus: { status: null, error: false, loading: true },
      };
    }

    case organizationConstants.GET_ORGANIZATION_STATUS_SUCCESS: {
      return {
        ...state,
        organizationStatus: {
          status: new OrganizationStatusModel(action.organizationStatus.data),
          error: false,
          loading: false,
        },
      };
    }

    case organizationConstants.GET_ORGANIZATION_STATUS_FAILURE: {
      return {
        ...state,
        organizationStatus: { status: null, error: true, loading: false },
      };
    }

    case organizationConstants.CLEAR_ORGANIZATION_REQUEST: {
      return {
        ...state,
        updated: null,
      };
    }

    default:
      return state;
  }
};

export default organization;
