export const contentsConstants = {
  CONTENTS_GET_REQUEST: 'CONTENTS_GET_REQUEST',
  CONTENTS_GET_SUCCESS: 'CONTENTS_GET_SUCCESS',
  CONTENTS_GET_FAILURE: 'CONTENTS_GET_FAILURE',

  GET_CONTENT_CONTENTS_REQUEST: 'GET_CONTENT_CONTENTS_REQUEST',
  GET_CONTENT_CONTENTS_SUCCESS: 'GET_CONTENT_CONTENTS_SUCCESS',
  GET_CONTENT_CONTENTS_FAILURE: 'GET_CONTENT_CONTENTS_FAILURE',

  PUT_CONTENT_CONTENTS_REQUEST: 'PUT_CONTENT_CONTENTS_REQUEST',
  PUT_CONTENT_CONTENTS_SUCCESS: 'PUT_CONTENT_CONTENTS_SUCCESS',
  PUT_CONTENT_CONTENTS_FAILURE: 'PUT_CONTENT_CONTENTS_FAILURE',

  GET_CONTENT_MAIL_REQUEST: 'GET_CONTENT_MAIL_REQUEST',
  GET_CONTENT_MAIL_SUCCESS: 'GET_CONTENT_MAIL_SUCCESS',
  GET_CONTENT_MAIL_FAILURE: 'GET_CONTENT_MAIL_FAILURE',

  PUT_CONTENT_MAIL_REQUEST: 'PUT_CONTENT_MAIL_REQUEST',
  PUT_CONTENT_MAIL_SUCCESS: 'PUT_CONTENT_MAIL_SUCCESS',
  PUT_CONTENT_MAIL_FAILURE: 'PUT_CONTENT_MAIL_FAILURE',

  CLEAR_CONTENT: 'CLEAR_CONTENT',
};
