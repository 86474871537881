import { barcodesConstants } from '../constants';

export const getBarcodesByProjectRequest = (
  idProject: number,
  options: any
) => ({
  type: barcodesConstants.BARCODES_GET_BY_PROJECT_REQUEST,
  idProject,
  options,
});

export const getBarcodesByProjectSuccess = (barcodesList: any) => ({
  type: barcodesConstants.BARCODES_GET_BY_PROJECT_SUCCESS,
  barcodesList,
});

export const getBarcodesByProjectFailure = (error: any) => ({
  type: barcodesConstants.BARCODES_GET_BY_PROJECT_FAILURE,
  error,
});

export const putBarcodeRequest = (idBarcode: number, barcode: any) => ({
  type: barcodesConstants.BARCODE_PUT_REQUEST,
  idBarcode,
  barcode,
});

export const putBarcodeSuccess = (updateBarcode: any) => ({
  type: barcodesConstants.BARCODE_PUT_SUCCESS,
  updateBarcode,
});

export const putBarcodeFailure = (error: any) => ({
  type: barcodesConstants.BARCODE_PUT_FAILURE,
  error,
});

export const sendBarcodeRequest = (userId: number, barcodes: any) => ({
  type: barcodesConstants.SEND_BARCODE_REQUEST,
  userId,
  barcodes,
});

export const sendBarcodeSuccess = (response: any) => ({
  type: barcodesConstants.SEND_BARCODE_SUCCESS,
  response,
});

export const sendBarcodeFailure = (error: any) => ({
  type: barcodesConstants.SEND_BARCODE_FAILURE,
  error,
});
