import { basketsConstants } from '../constants';

export const getBasketRequest = (idBasket: number, args?: any) => ({
  type: basketsConstants.BASKET_GET_REQUEST,
  idBasket,
  args,
});

export const getBasketSuccess = (basket: any) => ({
  type: basketsConstants.BASKET_GET_SUCCESS,
  basket,
});

export const getBasketFailure = (error: any) => ({
  type: basketsConstants.BASKET_GET_FAILURE,
  error,
});

export const getBasketItemsRequest = (idBasket: number) => ({
  type: basketsConstants.BASKET_GET_ITEMS_REQUEST,
  idBasket,
});

export const getBasketItemsSuccess = (basketItems: any, idBasket: number) => ({
  type: basketsConstants.BASKET_GET_ITEMS_SUCCESS,
  basketItems,
  idBasket,
});

export const getBasketItemsFailure = (error: any) => ({
  type: basketsConstants.BASKET_GET_ITEMS_FAILURE,
  error,
});

export const getBasketPaymentsRequest = (idBasket: number) => ({
  type: basketsConstants.BASKET_GET_PAYMENTS_REQUEST,
  idBasket,
});

export const getBasketPaymentsSuccess = (
  basketPayments: any,
  idBasket: number
) => ({
  type: basketsConstants.BASKET_GET_PAYMENTS_SUCCESS,
  basketPayments,
  idBasket,
});

export const getBasketPaymentsFailure = (error: any) => ({
  type: basketsConstants.BASKET_GET_PAYMENTS_FAILURE,
  error,
});

export const getBasketMailsRequest = (idBasket: number) => ({
  type: basketsConstants.BASKET_GET_MAILS_REQUEST,
  idBasket,
});

export const getBasketMailsSuccess = (basketMails: any) => ({
  type: basketsConstants.BASKET_GET_MAILS_SUCCESS,
  basketMails,
});

export const getBasketMailsFailure = (error: any) => ({
  type: basketsConstants.BASKET_GET_MAILS_FAILURE,
  error,
});

export const getBasketNotesRequest = (idBasket: number, params: any) => ({
  type: basketsConstants.BASKET_GET_NOTES_REQUEST,
  idBasket,
  params,
});

export const getBasketNotesSuccess = (basketNotes: any) => ({
  type: basketsConstants.BASKET_GET_NOTES_SUCCESS,
  basketNotes,
});

export const getBasketNotesFailure = (error: any) => ({
  type: basketsConstants.BASKET_GET_NOTES_FAILURE,
  error,
});

export const postBasketNotesRequest = (idBasket: number, note: any) => ({
  type: basketsConstants.BASKET_POST_NOTES_REQUEST,
  idBasket,
  note,
});

export const postBasketNotesSuccess = (newBasketNotes: any) => ({
  type: basketsConstants.BASKET_POST_NOTES_SUCCESS,
  newBasketNotes,
});

export const postBasketNotesFailure = (error: any) => ({
  type: basketsConstants.BASKET_POST_NOTES_FAILURE,
  error,
});

export const putBasketItemsRequest = (
  idBasket: number,
  updatedBasketItems: any
) => ({
  type: basketsConstants.BASKET_PUT_ITEMS_REQUEST,
  idBasket,
  updatedBasketItems,
});

export const putBasketItemsSuccess = (updatedBasketItems: any) => ({
  type: basketsConstants.BASKET_PUT_ITEMS_SUCCESS,
  updatedBasketItems,
});

export const putBasketItemsFailure = (error: any) => ({
  type: basketsConstants.BASKET_PUT_ITEMS_FAILURE,
  error,
});
