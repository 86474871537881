import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { MainButton } from '../../../index';
import { FormControl, IconButton, Input, InputAdornment, InputLabel, } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import BackgroundRegister from '../background';
import '../index.css';
export const SetupPassword = (props) => {
    const [showFirstPassword, setShowFirstPassword] = useState(false);
    const [showSecondPassword, setShowSecondPassword] = useState(false);
    return (_jsx(BackgroundRegister, { style: props.style, children: _jsxs("div", { className: "app-login-content", children: [_jsx("div", { className: "app-login-header", children: _jsx("h2", { children: props.title }) }), _jsx("div", { className: "app-login-form", children: _jsx("form", { children: _jsxs("fieldset", { children: [_jsxs(FormControl, { fullWidth: true, variant: "standard", children: [_jsx(InputLabel, { htmlFor: "first_input_password", children: props.firstPasswordLabel }), _jsx(Input, { id: "first_input_password", type: showFirstPassword ? 'text' : 'password', onChange: props.handleFirstPassword, value: props.firstPassword, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: () => setShowFirstPassword(!showFirstPassword), edge: "end", children: showFirstPassword ? _jsx(Visibility, {}) : _jsx(VisibilityOff, {}) }) }) })] }), _jsxs(FormControl, { className: 'confirm-password-field', fullWidth: true, variant: "standard", children: [_jsx(InputLabel, { htmlFor: "second_input_password", children: props.secondPasswordLabel }), _jsx(Input, { id: "second_input_password", type: showSecondPassword ? 'text' : 'password', onChange: props.handleSecondPassword, value: props.secondPassword, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: () => setShowSecondPassword(!showSecondPassword), edge: "end", children: showSecondPassword ? (_jsx(Visibility, {})) : (_jsx(VisibilityOff, {})) }) }) })] }), _jsx("div", { className: "login-error-message", children: props.errorMessage }), _jsx("div", { className: "login-button-container", children: _jsx(MainButton, { loading: props.loader, onClick: props.handleConfirm, disabled: props.disabled, buttonType: 'contain', fullWidth: true, children: props.buttonLabel }) })] }) }) })] }) }));
};
