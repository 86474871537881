export const categoriesConstants = {
  CATEGORY_DELETE_REQUEST: 'CATEGORY_DELETE_REQUEST',
  CATEGORY_DELETE_SUCCESS: 'CATEGORY_DELETE_SUCCESS',
  CATEGORY_DELETE_FAILURE: 'CATEGORY_DELETE_FAILURE',

  CATEGORIES_GET_BY_PROJECT_REQUEST: 'CATEGORIES_GET_BY_PROJECT_REQUEST',
  CATEGORIES_GET_BY_PROJECT_SUCCESS: 'CATEGORIES_GET_BY_PROJECT_SUCCESS',
  CATEGORIES_GET_BY_PROJECT_FAILURE: 'CATEGORIES_GET_BY_PROJECT_FAILURE',

  CATEGORY_GET_TRANSLATIONS_REQUEST: 'CATEGORY_GET_TRANSLATIONS_REQUEST',
  CATEGORY_GET_TRANSLATIONS_SUCCESS: 'CATEGORY_GET_TRANSLATIONS_SUCCESS',
  CATEGORY_GET_TRANSLATIONS_FAILURE: 'CATEGORY_GET_TRANSLATIONS_FAILURE',

  CATEGORY_POST_BY_PROJECT_REQUEST: 'CATEGORY_POST_BY_PROJECT_REQUEST',
  CATEGORY_POST_BY_PROJECT_SUCCESS: 'CATEGORY_POST_BY_PROJECT_SUCCESS',
  CATEGORY_POST_BY_PROJECT_FAILURE: 'CATEGORY_POST_BY_PROJECT_FAILURE',

  CATEGORY_TRANSLATIONS_PUT_REQUEST: 'CATEGORY_TRANSLATIONS_PUT_REQUEST',
  CATEGORY_TRANSLATIONS_PUT_SUCCESS: 'CATEGORY_TRANSLATIONS_PUT_SUCCESS',
  CATEGORY_TRANSLATIONS_PUT_FAILURE: 'CATEGORY_TRANSLATIONS_PUT_FAILURE',

  CATEGORY_TRANSLATIONS_POST_REQUEST: 'CATEGORY_TRANSLATIONS_POST_REQUEST',
  CATEGORY_TRANSLATIONS_POST_SUCCESS: 'CATEGORY_TRANSLATIONS_POST_SUCCESS',
  CATEGORY_TRANSLATIONS_POST_FAILURE: 'CATEGORY_TRANSLATIONS_POST_FAILURE',

  CATEGORY_POST_PRODUCT_REQUEST: 'CATEGORY_POST_PRODUCT_REQUEST',
  CATEGORY_POST_PRODUCT_SUCCESS: 'CATEGORY_POST_PRODUCT_SUCCESS',
  CATEGORY_POST_PRODUCT_FAILURE: 'CATEGORY_POST_PRODUCT_FAILURE',

  CLEAR_CATEGORIES: 'CLEAR_CATEGORIES',
};
