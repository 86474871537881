export const basketsConstants = {
  BASKET_GET_REQUEST: 'BASKET_GET_REQUEST',
  BASKET_GET_SUCCESS: 'BASKET_GET_SUCCESS',
  BASKET_GET_FAILURE: 'BASKET_GET_FAILURE',

  BASKET_GET_ITEMS_REQUEST: 'BASKET_GET_ITEMS_REQUEST',
  BASKET_GET_ITEMS_SUCCESS: 'BASKET_GET_ITEMS_SUCCESS',
  BASKET_GET_ITEMS_FAILURE: 'BASKET_GET_ITEMS_FAILURE',

  BASKET_GET_PAYMENTS_REQUEST: 'BASKET_GET_PAYMENTS_REQUEST',
  BASKET_GET_PAYMENTS_SUCCESS: 'BASKET_GET_PAYMENTS_SUCCESS',
  BASKET_GET_PAYMENTS_FAILURE: 'BASKET_GET_PAYMENTS_FAILURE',

  BASKET_GET_MAILS_REQUEST: 'BASKET_GET_MAILS_REQUEST',
  BASKET_GET_MAILS_SUCCESS: 'BASKET_GET_MAILS_SUCCESS',
  BASKET_GET_MAILS_FAILURE: 'BASKET_GET_MAILS_FAILURE',

  BASKET_GET_NOTES_REQUEST: 'BASKET_GET_NOTES_REQUEST',
  BASKET_GET_NOTES_SUCCESS: 'BASKET_GET_NOTES_SUCCESS',
  BASKET_GET_NOTES_FAILURE: 'BASKET_GET_NOTES_FAILURE',

  BASKET_POST_NOTES_REQUEST: 'BASKET_POST_NOTES_REQUEST',
  BASKET_POST_NOTES_SUCCESS: 'BASKET_POST_NOTES_SUCCESS',
  BASKET_POST_NOTES_FAILURE: 'BASKET_POST_NOTES_FAILURE',

  BASKET_PUT_ITEMS_REQUEST: 'BASKET_PUT_ITEMS_REQUEST',
  BASKET_PUT_ITEMS_SUCCESS: 'BASKET_PUT_ITEMS_SUCCESS',
  BASKET_PUT_ITEMS_FAILURE: 'BASKET_PUT_ITEMS_FAILURE',
};
