export default class BarcodeModel {
  id: number;
  attendee: string;
  createdAt: Date | null;
  formule: string;
  isCashlessCharged: boolean;
  nfcTagRequired: boolean;
  quantity: number;
  scanned: boolean;
  status: string;
  updatedAt: Date | null;
  value: string;
  files: {
    eticket_url: string | null;
    passbook_url: string | null;
  };
  scanningTerminal: string;

  constructor(data: any) {
    this.id = data.id;
    this.attendee = data.attendee ? data.attendee.fullName : '';
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.formule = data.formule ? data.formule.name : '';
    this.isCashlessCharged = data.isCashlessCharged;
    this.nfcTagRequired = data.nfcTagRequired;
    this.quantity = data.quantity;
    this.scanned = data.scanned;
    this.status = data.status ? data.status.name : '';
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
    this.value = data.value ?? '';
    this.files = data.files;
    this.scanningTerminal = data.scanningTerminal;
  }
}
