import { vatRatesConstants } from '../constants';

export const getVatRatesRequest = (countryCode: string) => ({
  type: vatRatesConstants.VAT_RATES_GET_REQUEST,
  countryCode,
});

export const getVatRatesSuccess = (vatRates: any) => ({
  type: vatRatesConstants.VAT_RATES_GET_SUCCESS,
  vatRates,
});

export const getVatRatesFailure = (error: any) => ({
  type: vatRatesConstants.VAT_RATES_GET_FAILURE,
  error,
});
