import { getUrl, get, update } from '../../services';

export async function getBrandingByProjectApi(idProject: number) {
  return await get(getUrl('projects/' + idProject + '/branding'));
}

export async function putBrandingByProjectApi(params: any) {
  return await update(
    getUrl('projects/' + params.idProject + '/branding'),
    params.updatedBranding
  );
}
