export const userConstants = {
  USER_SELF_DETAILS_GET_REQUEST: 'USER_SELF_DETAILS_GET_REQUEST',
  USER_SELF_DETAILS_GET_SUCCESS: 'USER_SELF_DETAILS_GET_SUCCESS',
  USER_SELF_DETAILS_GET_FAILURE: 'USER_SELF_DETAILS_GET_FAILURE',

  USER_SELF_PROJECTS_GET_REQUEST: 'USER_SELF_PROJECTS_GET_REQUEST',
  USER_SELF_PROJECTS_GET_SUCCESS: 'USER_SELF_PROJECTS_GET_SUCCESS',
  USER_SELF_PROJECTS_GET_FAILURE: 'USER_SELF_PROJECTS_GET_FAILURE',

  USERS_GET_USER_PROJECT_REQUEST: 'USERS_GET_USER_PROJECT_REQUEST',
  USERS_GET_USER_PROJECT_SUCCESS: 'USERS_GET_USER_PROJECT_SUCCESS',
  USERS_GET_USER_PROJECT_FAILURE: 'USERS_GET_USER_PROJECT_FAILURE',

  USER_PUT_REQUEST: 'USER_PUT_REQUEST',
  USER_PUT_SUCCESS: 'USER_PUT_SUCCESS',
  USER_PUT_FAILURE: 'USER_PUT_FAILURE',
};
