export const brandingConstants = {
  BRANDING_GET_BY_PROJECT_REQUEST: 'BRANDING_GET_BY_PROJECT_REQUEST',
  BRANDING_GET_BY_PROJECT_SUCCESS: 'BRANDING_GET_BY_PROJECT_SUCCESS',
  BRANDING_GET_BY_PROJECT_FAILURE: 'BRANDING_GET_BY_PROJECT_FAILURE',

  BRANDING_PUT_BY_PROJECT_REQUEST: 'BRANDING_PUT_BY_PROJECT_REQUEST',
  BRANDING_PUT_BY_PROJECT_SUCCESS: 'BRANDING_PUT_BY_PROJECT_SUCCESS',
  BRANDING_PUT_BY_PROJECT_FAILURE: 'BRANDING_PUT_BY_PROJECT_FAILURE',

  CLEAR_BRANDING_PUT_BY_PROJECT: 'CLEAR_BRANDING_PUT_BY_PROJECT',
};
