export default class AddressModel {
  id?: number;
  name: string;
  areaLevel1: string;
  boxNumber: string | number;
  city: string;
  country: string;
  street: string;
  streetNumber: string | number;
  zipCode: string;
  complementaryInformation: string;
  phoneNumber: string;
  faxNumber: string;

  constructor(data: any) {
    this.id = data?.id ?? 0;
    this.name = data?.name ?? '';
    this.areaLevel1 = data?.areaLevel1 ?? '';
    this.boxNumber = data?.boxNumber ?? '';
    this.city = data?.city ?? '';
    this.country = data?.country ?? '';
    this.street = data?.street ?? '';
    this.streetNumber = data?.streetNumber ?? '';
    this.zipCode = data?.zipCode ?? '';
    this.complementaryInformation = data?.complementaryInformation ?? '';
    this.phoneNumber = data?.phoneNumber ?? '';
    this.faxNumber = data?.faxNumber ?? '';
  }
}

export const areTwoAddressesEquals = (
  addressOne: AddressModel,
  addressTwo: AddressModel | null
) => {
  const copyAddressOne: any = { ...addressOne };
  const copyAddressTwo: any = { ...addressTwo };
  delete copyAddressOne.id;
  delete copyAddressTwo.id;

  const keys1 = Object.keys(copyAddressOne);
  const keys2 = Object.keys(copyAddressTwo);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = copyAddressOne[key];
    const val2 = copyAddressTwo[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !areTwoAddressesEquals(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

function isObject(object: any) {
  return object != null && typeof object === 'object';
}
