import WalletConfigModel from './WalletConfigModel';

export default class WalletModel {
  mount: number;
  id: number;
  walletConfig: WalletConfigModel;

  constructor(data: any) {
    this.mount = data.mount;
    this.id = data.id;
    this.walletConfig = new WalletConfigModel(data.walletConfig);
  }
}
