import ProjectModel from './ProjectModel';
import CustomerProfileModel from './CustomerProfileModel';

export default class BasketModel {
  id: number;
  accepted: boolean;
  basketStatus: string;
  createdAt: Date | null;
  customerProfile: CustomerProfileModel | null;
  dateConfirmed: Date | null;
  datePayment: Date | null;
  includeInStats: boolean;
  isOnline: boolean;
  totalPriceWithVat: number;
  totalPriceWithoutVat: number;
  updatedAt: Date | null;
  fees: number;
  organizerFee: number;
  feesTransaction: string;
  project: ProjectModel | null;

  constructor(data: any) {
    this.id = data.id;
    this.accepted = data.accepted;
    this.basketStatus = data.basketStatus;
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.customerProfile = data.customerProfile
      ? new CustomerProfileModel(data.customerProfile)
      : null;
    this.dateConfirmed = data.dateConfirmedUTC
      ? new Date(data.dateConfirmedUTC)
      : null;
    this.datePayment = data.datePaymentUTC
      ? new Date(data.datePaymentUTC)
      : null;
    this.includeInStats = data.includeInStats;
    this.isOnline = data.isOnline;
    this.totalPriceWithVat = data.totalPriceWithVat;
    this.totalPriceWithoutVat = data.totalPriceWithoutVat;
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
    this.fees = data.fees;
    this.organizerFee = data.organizerFee;
    this.feesTransaction = data.feesTransaction;
    this.project = data.project ? new ProjectModel(data.project) : null;
  }
}
