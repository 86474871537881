import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { postUploadPictureFailure, postUploadPictureSuccess } from '../actions';
import { uploadsConstants } from '../constants';
import { postUploadPictureApi } from '../api';

export function* fetchPostUploadPicture(action: any): any {
  try {
    const newPicture = yield call(postUploadPictureApi, {
      picture: action.picture,
    });
    yield put(postUploadPictureSuccess(newPicture));
  } catch (error) {
    yield put(postUploadPictureFailure(error));
  }
}

export function* postUploadPicture() {
  yield takeLatest(
    uploadsConstants.UPLOAD_POST_PICTURE_REQUEST,
    fetchPostUploadPicture
  );
}

export default function* uploadsSaga() {
  yield all([fork(postUploadPicture)]);
}
