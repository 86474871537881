import { reportsTicketingConstants } from '../constants';
import ProductReportModel from '../../Models/ProductReportModel';
import BarcodeReportModel from '../../Models/BarcodeReportModel';
import BasketReportModel from '../../Models/BasketReportModel';

const initialState: any = {
  barcodesReports: null,
  basketsReports: null,
  posProductReports: null,
};

let listBarcodesReports: BarcodeReportModel[] = [];
let listBasketsReports: BasketReportModel[] = [];
let listPosProductReports: ProductReportModel[] = [];

const reportsTicketing = (state = initialState, action: any) => {
  switch (action.type) {
    case reportsTicketingConstants.GET_BARCODES_REPORTS_REQUEST: {
      return {
        ...state,
        barcodesReports: {
          report: listBarcodesReports,
          error: false,
          loading: true,
        },
      };
    }

    case reportsTicketingConstants.GET_BARCODES_REPORTS_SUCCESS: {
      listBarcodesReports = action.barcodesReports.data.map(
        (report: any) => new BarcodeReportModel(report)
      );

      return {
        ...state,
        barcodesReports: {
          report: listBarcodesReports,
          meta: action.barcodesReports.meta,
          error: false,
          loading: false,
        },
      };
    }

    case reportsTicketingConstants.GET_BARCODES_REPORTS_FAILURE: {
      return {
        ...state,
        barcodesReports: {
          report: [],
          error: true,
          loading: false,
        },
      };
    }

    case reportsTicketingConstants.GET_BASKETS_REPORTS_REQUEST: {
      return {
        ...state,
        basketsReports: {
          report: listBasketsReports,
          error: false,
          loading: true,
        },
      };
    }

    case reportsTicketingConstants.GET_BASKETS_REPORTS_SUCCESS: {
      listBasketsReports = action.basketsReports.data.map(
        (report: any) => new BasketReportModel(report)
      );

      return {
        ...state,
        basketsReports: {
          report: listBasketsReports,
          meta: action.basketsReports.meta,
          error: false,
          loading: false,
        },
      };
    }

    case reportsTicketingConstants.GET_BASKETS_REPORTS_FAILURE: {
      return {
        ...state,
        basketsReports: {
          report: [],
          error: true,
          loading: false,
        },
      };
    }

    case reportsTicketingConstants.GET_POS_PRODUCT_REPORTS_REQUEST: {
      return {
        ...state,
        posProductReports: {
          report: listPosProductReports,
          error: false,
          loading: true,
        },
      };
    }

    case reportsTicketingConstants.GET_POS_PRODUCT_REPORTS_SUCCESS: {
      listPosProductReports = action.posReports.data.map(
        (report: any) => new ProductReportModel(report)
      );

      return {
        ...state,
        posProductReports: {
          report: listPosProductReports,
          meta: action.posReports.meta,
          error: false,
          loading: false,
        },
      };
    }

    case reportsTicketingConstants.GET_POS_PRODUCT_REPORTS_FAILURE: {
      return {
        ...state,
        posProductReports: {
          report: [],
          error: true,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default reportsTicketing;
