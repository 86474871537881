import BrandingContentModel from './BrandingContentModel';
import { currentProject } from '../Global/currentProject';

export type MailsContentTranslationType = {
  body: string;
  locale: string;
  subject: string;
};

export type MailsContentTranslationsType = {
  [lang: string]: MailsContentTranslationType;
};

export default class MailsContentModel extends BrandingContentModel {
  enable: boolean;
  translations: MailsContentTranslationsType;

  constructor(data: any) {
    super(data);
    this.enable = data.enable;
    this.translations = this.createMailsTranslations(data.translations);
  }

  createMailsTranslations(translations: any): MailsContentTranslationsType {
    let allTranslations: MailsContentTranslationsType = {};
    currentProject.allowedLanguages.forEach((language: string) => {
      if (translations && translations[language]) {
        allTranslations[language] = translations[language];
      } else {
        allTranslations[language] = {
          body: '',
          locale: language,
          subject: '',
        };
      }
    });
    return allTranslations;
  }
}
