import { getUrl, get, post, patch, update } from '../../services';

export async function getPosBasketsApi(params: any) {
  return await get(getUrl('pos/' + params.idPos + '/baskets', params.args));
}

export async function postPosProductApi(params: any) {
  return await post(
    getUrl('pos/' + params.idPos + '/products/' + params.idProduct)
  );
}

export async function getPosProductsApi(params: any) {
  return await get(getUrl('pos/' + params.idPos + '/products', params.args));
}

export async function putFormulePositionPosApi(params: any) {
  return await patch(
    getUrl('pos/' + params.idPos + '/products/' + params.idFormule),
    params.params
  );
}

export async function getPosShopsApi(params: any) {
  return await get(getUrl('pos/' + params.idPos + '/shopconfig'));
}

export async function patchPosShopApi(params: any) {
  return await update(
    getUrl('pos/' + params.idPos + '/shopconfig'),
    params.params
  );
}

export async function openPosShopApi(params: any) {
  return await patch(getUrl('pos/' + params.idPos + '/open'));
}

export async function closePosShopApi(params: any) {
  return await patch(getUrl('pos/' + params.idPos + '/close'));
}
