export default class StringMatcher {
  stringComparator: Intl.Collator;

  constructor(locale: string) {
    this.stringComparator = new Intl.Collator(locale, {
      usage: 'search',
      sensitivity: 'base',
    });
  }

  searchNameForMatch(name: string, search: string): boolean {
    const searchLen = search.length;
    const maxSearch = name.length - searchLen;
    if (maxSearch < 0) {
      return false;
    }
    if (maxSearch === 0) {
      return this.stringComparator.compare(name, search) === 0;
    }
    for (let i = 0; i <= maxSearch; i++) {
      if (
        this.stringComparator.compare(name.slice(i, i + searchLen), search) ===
        0
      ) {
        return true;
      }
    }
    return false;
  }
}
