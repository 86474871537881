import ProductTypeModel, { createProductTypeFromApi } from './ProductTypeModel';
import PrintAtHomesModel, { createPrintAtHomes } from './PrintAtHomesModel';
import {
  createProductTranslations,
  ProductTranslationType,
} from './ProductModel';
import CategoryModel from './CategoryModel';

export default class TicketModel {
  id: number;
  name: string;
  platform: number;
  productType: ProductTypeModel | null;
  price: number;
  priceOnTicket: number | null;
  priceWithVat: number;
  priceWithoutVat: number;
  fees: number;
  activityDuration: number | null;
  entryDateStartUTC: Date | null;
  entryDateStopUTC: Date | null;
  saleDateStartUTC: Date | null;
  saleDateStopUTC: Date | null;
  isAvailableForRefund: boolean;
  isHighlight: boolean;
  isNfcTagRequired: boolean;
  isOnSite: boolean;
  isOnline: boolean;
  isPrintAtHome: boolean;
  isSoldOut: boolean;
  isStockManaged: boolean;
  noGroupingInSale: boolean;
  printTicket: boolean;
  quantityIncrement: number;
  quantityMaxPerSale: number | null;
  quantityMinPerSale: number | null;
  quantityStock: number | null;
  quantityStockAllocated: number | null;
  quantityStockMaximumTotalCustomer: number | null;
  availableQuantity: number | null;
  saleStatus: string;
  printAtHomes: { [key: string]: PrintAtHomesModel };
  translations: ProductTranslationType[];
  vatRate: { id: number } | null;
  picture: {
    id?: number | null;
    src: string | null;
    original: string | null;
  };
  attendeeForm: { id: number } | null;
  categories?: CategoryModel[];
  stampDateEndDelay: number;
  stampDateEndDelayFromEntryEnd: boolean;
  stampDateSource: string;
  stampDateStart: Date | null;
  stampDateStartDelay: number;
  stampDateStop: Date | null;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.platform = data.platform;
    this.productType = data.productType
      ? createProductTypeFromApi(data.productType)
      : null;
    this.price = data.price;
    this.priceOnTicket = data.priceOnTicket;
    this.priceWithVat = data.priceWithVat;
    this.priceWithoutVat = data.priceWithoutVat;
    this.fees = data.fees;
    this.activityDuration = data.activityDuration;
    this.entryDateStartUTC = data.entryDateStartUTC
      ? new Date(data.entryDateStartUTC)
      : null;
    this.entryDateStopUTC = data.entryDateStopUTC
      ? new Date(data.entryDateStopUTC)
      : null;
    this.isAvailableForRefund = data.isAvailableForRefund;
    this.isHighlight = data.isHighlight;
    this.isNfcTagRequired = data.isNfcTagRequired;
    this.isOnSite = data.isOnSite;
    this.isOnline = data.isOnline;
    this.isPrintAtHome = data.isPrintAtHome;
    this.isSoldOut = data.isSoldOut;
    this.isStockManaged = data.isStockManaged;
    this.noGroupingInSale = data.noGroupingInSale;
    this.printTicket = data.printTicket;
    this.quantityIncrement = data.quantityIncrement;
    this.quantityMaxPerSale = data.quantityMaxPerSale;
    this.quantityMinPerSale = data.quantityMinPerSale;
    this.quantityStock = data.quantityStock;
    this.quantityStockAllocated = data.quantityStockAllocated;
    this.availableQuantity = data.availableQuantity;
    this.quantityStockMaximumTotalCustomer =
      data.quantityStockMaximumTotalCustomer;
    this.saleDateStartUTC = data.saleDateStartUTC
      ? new Date(data.saleDateStartUTC)
      : null;
    this.saleDateStopUTC = data.saleDateStopUTC
      ? new Date(data.saleDateStopUTC)
      : null;
    this.saleStatus = data.saleStatus;
    this.picture = {
      id: data.picture ? data.picture.id : null,
      src: data.picture ? data.picture.original : null,
      original: data.picture ? data.picture.original : null,
    };
    this.printAtHomes = createPrintAtHomes(data.printAtHomes);
    this.vatRate = data.vatRate;
    this.attendeeForm = data.attendeeForm || null;
    this.translations = createProductTranslations(data.translations);
    this.categories = data.categories.map((cat: any) => new CategoryModel(cat));
    this.stampDateEndDelay = data.stampDateEndDelay;
    this.stampDateEndDelayFromEntryEnd = data.stampDateEndDelayFromEntryEnd;
    this.stampDateSource = data.stampDateSource;
    this.stampDateStart = data.stampDateStart
      ? new Date(data.stampDateStart)
      : null;
    this.stampDateStartDelay = data.stampDateStartDelay;
    this.stampDateStop = data.stampDateStop
      ? new Date(data.stampDateStop)
      : null;
  }
}
