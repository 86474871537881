import WalletModel from './WalletModel';

type tagType =
  | 'card'
  | 'wristband'
  | 'fob'
  | 'sticker'
  | 'barcode'
  | 'dynqrcode';

export default class NfcTagModel {
  uid: string;
  defaultWallet: WalletModel | null;
  isActive: boolean;
  isLocked: boolean;
  label: string;
  status: number;
  supportType: number;
  type: tagType;
  credits: string;

  constructor(data: any) {
    this.uid = data.uid;
    this.defaultWallet = data.defaultWallet
      ? new WalletModel(data.defaultWallet)
      : null;
    this.isActive = data.isActive;
    this.isLocked = data.isLocked;
    this.label = data.label;
    this.status = data.status;
    this.supportType = data.supportType;
    this.type = data.type;
    this.credits =
      data?.defaultWallet?.amount +
      data?.defaultWallet?.walletConfig?.unitSymbol;
  }
}
