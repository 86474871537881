const parseJwt = (token: string): any | null => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const isTokenNearlyInvalid = (token: string | null): boolean => {
  return !isTokenValid(token, 5);
};

export const isTokenOver = (token: string | null): boolean => {
  return !isTokenValid(token);
};

export const isTokenValid = (token: string | null, time = 0): boolean => {
  if (!token) {
    return false;
  }

  const decodedJwt = parseJwt(token);
  if (!decodedJwt) {
    return false;
  }

  const jwtExpireDate = decodedJwt.exp * 1000;
  const delay = time * 60 * 1000;
  const nowDate = Date.now();

  return nowDate < jwtExpireDate - delay;
};
