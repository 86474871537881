export const adminConstants = {
  GET_ADMINS_REQUEST: 'GET_ADMINS_REQUEST',
  GET_ADMINS_SUCCESS: 'GET_ADMINS_SUCCESS',
  GET_ADMINS_FAILURE: 'GET_ADMINS_FAILURE',

  ADD_ADMIN_REQUEST: 'ADD_ADMIN_REQUEST',
  ADD_ADMIN_SUCCESS: 'ADD_ADMIN_SUCCESS',
  ADD_ADMIN_FAILURE: 'ADD_ADMIN_FAILURE',

  DELETE_ADMIN_REQUEST: 'DELETE_ADMIN_REQUEST',
  DELETE_ADMIN_SUCCESS: 'DELETE_ADMIN_SUCCESS',
  DELETE_ADMIN_FAILURE: 'DELETE_ADMIN_FAILURE',

  ADMIN_RESET: 'ADMIN_RESET',
};
