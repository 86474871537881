import { userConstants } from '../constants';

export const getUserSelfDetailsRequest = (idProject: number) => ({
  type: userConstants.USER_SELF_DETAILS_GET_REQUEST,
  idProject,
});

export const getUserSelfDetailsSuccess = (selfDetails: any) => ({
  type: userConstants.USER_SELF_DETAILS_GET_SUCCESS,
  selfDetails,
});

export const getUserSelfDetailsFailure = (error: any) => ({
  type: userConstants.USER_SELF_DETAILS_GET_FAILURE,
  error,
});

export const getUserSelfProjectsRequest = () => ({
  type: userConstants.USER_SELF_PROJECTS_GET_REQUEST,
  selfDetails: '',
});

export const getUserSelfProjectsSuccess = (projects: any[]) => ({
  type: userConstants.USER_SELF_PROJECTS_GET_SUCCESS,
  projects,
});

export const getUserSelfProjectsFailure = (error: any) => ({
  type: userConstants.USER_SELF_PROJECTS_GET_FAILURE,
  error,
});

export const getUserProjectByProjectRequest = (
  idProject: number,
  idUser: number
) => ({
  type: userConstants.USERS_GET_USER_PROJECT_REQUEST,
  idProject,
  idUser,
});

export const getUserProjectByProjectSuccess = (userProject: any) => ({
  type: userConstants.USERS_GET_USER_PROJECT_SUCCESS,
  userProject,
});

export const getUserProjectByProjectFailure = (error: any) => ({
  type: userConstants.USERS_GET_USER_PROJECT_FAILURE,
  error,
});

export const putUserRequest = (user: any) => ({
  type: userConstants.USER_PUT_REQUEST,
  user,
});

export const putUserSuccess = (userUpdate: any) => ({
  type: userConstants.USER_PUT_SUCCESS,
  userUpdate,
});

export const putUserFailure = (error: any) => ({
  type: userConstants.USER_PUT_FAILURE,
  error,
});
