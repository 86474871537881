export default class BrandingContentModel {
  title: string;
  description: string;
  reference: string;
  editable: boolean | null;

  constructor(data: any) {
    this.title = data.title;
    this.description = data.description;
    this.reference = data.reference;
    this.editable = data.editable ?? null;
  }
}
