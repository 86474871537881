import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import getStore from './Redux/store';
import App from './app';
import './app/global.css';
import './assets/vendors/style';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const store = getStore();
export type storeType = ReturnType<typeof store.getState>;

const root = ReactDOM.createRoot(
  document.getElementById('app-site') as HTMLElement
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}
