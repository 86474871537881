import { combineReducers } from 'redux';
import forms from './forms.reducer';
import reportsTicketing from './reports-ticketing.reducer';
import user from './user.reducer';
import branding from './branding.reducer';
import attendees from './attendees.reducer';
import categories from './categories.reducer';
import baskets from './baskets.reducer';
import project from './project.reducer';
import tickets from './ticket.reducer';
import barcodes from './barcodes.reducer';
import uploads from './uploads.reducer';
import addresses from './addresses.reducer';
import mails from './mails.reducer';
import vatRates from './vat-rates.reducer';
import productTypes from './product-types.reducer';
import error from './error.reducer';
import pos from './pos.reducer';
import organization from './organization.reducer';
import admin from './admin.reducer';
import contents from './contents.reducer';
import printAtHome from './printAtHome.reducer';
import { authConstants } from '../constants';

export const appReducer = combineReducers({
  addresses: addresses,
  admin: admin,
  attendees: attendees,
  barcodes: barcodes,
  baskets: baskets,
  branding: branding,
  categories: categories,
  contents: contents,
  error: error,
  forms: forms,
  mails: mails,
  organization: organization,
  tickets: tickets,
  pos: pos,
  printAtHome: printAtHome,
  productTypes: productTypes,
  project: project,
  reportsTicketing: reportsTicketing,
  user: user,
  uploads: uploads,
  vatRates: vatRates,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === authConstants.USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
