import { statusType } from '@emisys/audience-sdk-ui-react/src/components/Status';
import CustomerProfileModel from './CustomerProfileModel';

export default class MailDetailModel {
  id: number;
  attachments: any[];
  createdAt: Date | null;
  from: string;
  link: string;
  locale: string;
  parts: {
    content: string;
    id: number;
    mimeType: string;
  }[];
  queueDateUtc: Date | null;
  queueMailId: null | number;
  randomKey: number;
  recipientProfile: CustomerProfileModel;
  sendChannel: null;
  status: statusType;
  subject: string;
  templateName: string;
  to: string;
  updatedAt: Date | null;

  constructor(data: any) {
    this.id = data.id;
    this.attachments = data.attachments;
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.from = data.from;
    this.link = data.link;
    this.locale = data.locale;
    this.parts = data.parts;
    this.queueDateUtc = data.queueDateUtc ? new Date(data.queueDateUtc) : null;
    this.queueMailId = data.queueMailId;
    this.randomKey = data.randomKey;
    this.recipientProfile = new CustomerProfileModel(data.recipientProfile);
    this.sendChannel = data.sendChannel;
    this.status = data.status;
    this.subject = data.subject;
    this.templateName = data.templateName;
    this.to = data.to;
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
  }
}
