import { getUrl, get, update, patch } from '../../services';
import { currentProject } from '../../../Global/currentProject';

export async function getBarcodesByProjectApi(params: any) {
  return await get(
    getUrl('projects/' + params.idProject + '/barcodes', params.args)
  );
}

export async function putBarcodeApi(params: any) {
  return await update(getUrl('barcodes/' + params.idBarcode), params.barcode);
}

export async function sendBarcodeApi(params: any) {
  return await patch(
    getUrl('users/' + params.userId + '/barcodes/send', {
      project_id: currentProject.id,
    }),
    params.barcodes
  );
}
