import { get, getUrl, del, post, update, patch } from '../../services';

export async function getFormsByProjectApi(params: any) {
  return await get(getUrl('projects/' + params.idProject + '/forms?shop=1'));
}

export async function getFormByIdApi(params: any) {
  return await get(getUrl('forms/' + params.idForm));
}

export async function postFormApi(params: any) {
  return await post(getUrl('projects/' + params.idProject + '/forms'), {
    name: params.form,
    shop: 1,
  });
}

export async function putFormApi(params: any) {
  return await update(getUrl('forms/' + params.idForm), params.form);
}

export async function deleteFormApi(idForm: number) {
  return await del(getUrl('forms/' + idForm));
}

export async function getQuestionsByFormApi(params: any) {
  return await get(getUrl('forms/' + params.idForm + '/questions'));
}

export async function getQuestionsTypesApi() {
  return await get(
    getUrl('questions/types', {
      platform: 1,
    })
  );
}

export async function postFormQuestionsApi(params: any) {
  return await post(
    getUrl('forms/' + params.idForm + '/questions'),
    params.questions
  );
}

export async function deleteFormQuestionApi(idQuestion: number) {
  return await del(getUrl('questions/' + idQuestion));
}

export async function getQuestionByIdApi(params: any) {
  return await get(getUrl('questions/' + params.idQuestion));
}

export async function putQuestionApi(params: any) {
  return await update(
    getUrl('questions/' + params.idQuestion),
    params.question
  );
}

export async function putQuestionToggleRequiredApi(params: any) {
  return await update(
    getUrl('questions/' + params.idQuestion + '/toggle-required')
  );
}

export async function putFormQuestionsOrderApi(params: any) {
  return await patch(
    getUrl('forms/' + params.idForm + '/set_order'),
    params.questionsOrder
  );
}
