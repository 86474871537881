import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getBarcodesByProjectSuccess,
  getBarcodesByProjectFailure,
  putBarcodeSuccess,
  putBarcodeFailure,
  sendBarcodeSuccess,
  sendBarcodeFailure,
} from '../actions';
import { barcodesConstants } from '../constants';
import { getBarcodesByProjectApi, putBarcodeApi, sendBarcodeApi } from '../api';

export function* fetchGetBarcodesByProject(action: any): any {
  try {
    const barcodes = yield call(getBarcodesByProjectApi, {
      idProject: action.idProject,
      args: action.options,
    });
    yield put(getBarcodesByProjectSuccess(barcodes));
  } catch (error) {
    yield put(getBarcodesByProjectFailure(error));
  }
}

export function* fetchPutBarcode(action: any): any {
  try {
    const updatedBarcode = yield call(putBarcodeApi, {
      idBarcode: action.idBarcode,
      barcode: action.barcode,
    });
    yield put(putBarcodeSuccess(updatedBarcode));
  } catch (error) {
    yield put(putBarcodeFailure(error));
  }
}

export function* fetchSendBarcode(action: any): any {
  try {
    const response = yield call(sendBarcodeApi, {
      userId: action.userId,
      barcodes: action.barcodes,
    });
    yield put(sendBarcodeSuccess(response));
  } catch (error) {
    yield put(sendBarcodeFailure(error));
  }
}

export function* getBarcodesByProject() {
  yield takeLatest(
    barcodesConstants.BARCODES_GET_BY_PROJECT_REQUEST,
    fetchGetBarcodesByProject
  );
}

export function* putBarcode() {
  yield takeLatest(barcodesConstants.BARCODE_PUT_REQUEST, fetchPutBarcode);
}

export function* sendBarcode() {
  yield takeLatest(barcodesConstants.SEND_BARCODE_REQUEST, fetchSendBarcode);
}

export default function* barcodesSaga() {
  yield all([fork(getBarcodesByProject), fork(putBarcode), fork(sendBarcode)]);
}
