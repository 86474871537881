export default class MailModel {
  id: number;
  from: string;
  locale: string;
  queueDateUtc: Date | null;
  queueMailId: string;
  recipientProfile: number | null;
  status: string;
  subject: string;
  templateName: string;
  to: string;

  constructor(data: any) {
    this.id = data.id;
    this.from = data.from;
    this.locale = data.locale;
    this.queueDateUtc = data.queueDateUtc ? new Date(data.queueDateUtc) : null;
    this.queueMailId = data.queueMailId;
    this.recipientProfile = data.recipientProfile?.id ?? null;
    this.status = data.status ? data.status : '/';
    this.subject = data.subject ? data.subject : '/';
    this.templateName = data.templateName;
    this.to = data.to ? data.to : '/';
  }
}
