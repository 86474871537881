import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getBarcodesReportsFailure,
  getBarcodesReportsSuccess,
  getBasketsReportsFailure,
  getBasketsReportsSuccess,
  getPosProductReportsFailure,
  getPosProductReportsSuccess,
} from '../actions';
import { reportsTicketingConstants } from '../constants';
import {
  getBarcodesReportsApi,
  getBasketsReportsApi,
  getPosProductReportsApi,
} from '../api';

export function* fetchGetBarcodesReports(action: any): any {
  try {
    const barcodesReports = yield call(getBarcodesReportsApi, {
      idProject: action.idProject,
      args: action.options,
    });
    yield put(getBarcodesReportsSuccess(barcodesReports));
  } catch (error) {
    yield put(getBarcodesReportsFailure(error));
  }
}

export function* fetchGetBasketsReports(action: any): any {
  try {
    const basketsReports = yield call(getBasketsReportsApi, {
      idProject: action.idProject,
      args: action.options,
    });
    yield put(getBasketsReportsSuccess(basketsReports));
  } catch (error) {
    yield put(getBasketsReportsFailure(error));
  }
}

export function* fetchGetPosProductReports(action: any): any {
  try {
    const posProductReports = yield call(getPosProductReportsApi, {
      idPos: action.idPos,
      args: action.options,
    });
    yield put(getPosProductReportsSuccess(posProductReports));
  } catch (error) {
    yield put(getPosProductReportsFailure(error));
  }
}

export function* getBarcodesReports() {
  yield takeLatest(
    reportsTicketingConstants.GET_BARCODES_REPORTS_REQUEST,
    fetchGetBarcodesReports
  );
}

export function* getBasketsReports() {
  yield takeLatest(
    reportsTicketingConstants.GET_BASKETS_REPORTS_REQUEST,
    fetchGetBasketsReports
  );
}

export function* getPosProductReports() {
  yield takeLatest(
    reportsTicketingConstants.GET_POS_PRODUCT_REPORTS_REQUEST,
    fetchGetPosProductReports
  );
}

export default function* reportsTicketingSaga() {
  yield all([
    fork(getBarcodesReports),
    fork(getBasketsReports),
    fork(getPosProductReports),
  ]);
}
