export default class OnboardingModel {
  customization: boolean;
  event: boolean;
  firstCategory: boolean;
  firstProduct: boolean;
  organization: boolean;
  published: boolean;
  isAlmostComplete: boolean;
  isCompleted: boolean;

  constructor(data: any) {
    this.customization = data.customization;
    this.event = data.event;
    this.firstCategory = data.firstCategory;
    this.firstProduct = data.firstProduct;
    this.organization = data.organization;
    this.published = data.published;

    this.isAlmostComplete = this.isAlmostCompleted();
    this.isCompleted = this.isOnboardingCompleted();
  }

  private isOnboardingCompleted = () =>
    this.customization &&
    this.event &&
    this.firstCategory &&
    this.firstProduct &&
    this.organization;

  private isAlmostCompleted = () => {
    const onboarding = [
      this.customization,
      this.event,
      this.firstCategory,
      this.firstProduct,
      this.organization,
    ];

    let allTrue = onboarding.filter((value) => value);
    let allFalse = onboarding.filter((value) => !value);

    return allFalse.length < allTrue.length;
  };
}
