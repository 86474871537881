import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Translator from '../../services/translator';
import './ArchivedEventBanner.css';

const ArchivedEventBanner = () => (
  <div className={'archived-event-banner'}>
    <div className={'archived-event-banner-icon'}>
      <ErrorOutlineIcon />
    </div>
    <div>
      <h4>{Translator.trans('popup.important.archived.title')}</h4>
      <div>{Translator.trans('popup.important.archived.desc')}</div>
    </div>
  </div>
);

export default ArchivedEventBanner;
