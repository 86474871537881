import { printAtHomeConstants } from '../constants';
import { createPrintAtHomes } from '../../Models/PrintAtHomesModel';

const initialState: any = {
  printAtHome: null,
};

const printAtHome = (state = initialState, action: any) => {
  switch (action.type) {
    case printAtHomeConstants.GET_PRINT_AT_HOME_REQUEST: {
      return {
        ...state,
        printAtHome: { details: null, error: false, loading: true },
      };
    }

    case printAtHomeConstants.GET_PRINT_AT_HOME_SUCCESS: {
      return {
        ...state,
        printAtHome: {
          details: createPrintAtHomes(action.response.data),
          error: false,
          loading: false,
        },
      };
    }

    case printAtHomeConstants.GET_PRINT_AT_HOME_FAILURE: {
      return {
        ...state,
        printAtHome: { details: null, error: true, loading: false },
      };
    }

    default:
      return state;
  }
};

export default printAtHome;
