export default class UserModel {
  appleId: null | number;
  createdAt: Date | null;
  email: string;
  facebookId: null | string;
  gplusId: null | string;
  id: number;
  isActive: boolean;
  twitterId: null | string;
  updatedAt: Date | null;

  constructor(data: any) {
    this.appleId = data.appleId;
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.email = data.email;
    this.facebookId = data.facebookId;
    this.gplusId = data.gplusId;
    this.id = data.id;
    this.isActive = data.isActive;
    this.twitterId = data.twitterId;
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
  }
}

export const setFullName = (fullName: string) => {
  let currentName = null;
  if (fullName) {
    currentName = fullName.replace(' ', '');
  }

  return currentName ? fullName : null;
};
