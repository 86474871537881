import { posConstants } from '../constants';

export const postPosProductRequest = (idPos: number, idProduct: number) => ({
  type: posConstants.POST_POS_PRODUCT_REQUEST,
  idPos,
  idProduct,
});

export const postPosProductSuccess = (productLinked: any) => ({
  type: posConstants.POST_POS_PRODUCT_SUCCESS,
  productLinked,
});

export const postPosProductFailure = (error: any) => ({
  type: posConstants.POST_POS_PRODUCT_FAILURE,
  error,
});

export const getPosBasketsRequest = (idPos: number, options: any = null) => ({
  type: posConstants.GET_POS_BASKETS_REQUEST,
  idPos,
  options,
});

export const getPosBasketsSuccess = (baskets: any) => ({
  type: posConstants.GET_POS_BASKETS_SUCCESS,
  baskets,
});

export const getPosBasketsFailure = (error: any) => ({
  type: posConstants.GET_POS_BASKETS_FAILURE,
  error,
});

export const getPosProductsRequest = (idPos: number, options: any) => ({
  type: posConstants.GET_POS_PRODUCTS_REQUEST,
  idPos,
  options,
});

export const getPosProductsSuccess = (products: any) => ({
  type: posConstants.GET_POS_PRODUCTS_SUCCESS,
  products,
});

export const getPosProductsFailure = (error: any) => ({
  type: posConstants.GET_POS_PRODUCTS_FAILURE,
  error,
});

export const putFormulePositionPosRequest = (
  idPos: number,
  idFormule: number,
  params: any
) => ({
  type: posConstants.PUT_FORMULE_POSITION_POS_REQUEST,
  idPos,
  idFormule,
  params,
});

export const putFormulePositionPosSuccess = (response: any) => ({
  type: posConstants.PUT_FORMULE_POSITION_POS_SUCCESS,
  response,
});

export const putFormulePositionPosFailure = (error: any) => ({
  type: posConstants.PUT_FORMULE_POSITION_POS_FAILURE,
  error,
});

export const getPosShopsRequest = (idPos: number) => ({
  type: posConstants.GET_POS_SHOPS_REQUEST,
  idPos,
});

export const getPosShopsSuccess = (shops: any) => ({
  type: posConstants.GET_POS_SHOPS_SUCCESS,
  shops,
});

export const getPosShopsFailure = (error: any) => ({
  type: posConstants.GET_POS_SHOPS_FAILURE,
  error,
});

export const patchPosShopRequest = (idPos: number, params: any) => ({
  type: posConstants.PATCH_POS_SHOP_REQUEST,
  idPos,
  params,
});

export const patchPosShopSuccess = (response: any) => ({
  type: posConstants.PATCH_POS_SHOP_SUCCESS,
  response,
});

export const patchPosShopFailure = (error: any) => ({
  type: posConstants.PATCH_POS_SHOP_FAILURE,
  error,
});

export const openPosShopRequest = (idPos: number) => ({
  type: posConstants.OPEN_POS_SHOP_REQUEST,
  idPos,
});

export const openPosShopSuccess = (response: any) => ({
  type: posConstants.OPEN_POS_SHOP_SUCCESS,
  response,
});

export const openPosShopFailure = (error: any) => ({
  type: posConstants.OPEN_POS_SHOP_FAILURE,
  error,
});

export const closePosShopRequest = (idPos: number) => ({
  type: posConstants.CLOSE_POS_SHOP_REQUEST,
  idPos,
});

export const closePosShopSuccess = (response: any) => ({
  type: posConstants.CLOSE_POS_SHOP_SUCCESS,
  response,
});

export const closePosShopFailure = (error: any) => ({
  type: posConstants.CLOSE_POS_SHOP_FAILURE,
  error,
});

export const resetShopRequest = () => ({
  type: posConstants.RESET_POS,
});
