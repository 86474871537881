import React from 'react';
import intl from 'react-intl-universal';
import initTranslations from './initTranslations';

initTranslations();

interface TranslationParameter {
  [index: string]: string | number | React.ReactElement | undefined;
}

export default class Translator {
  static trans(key: string, params?: TranslationParameter): string {
    return intl.get(key, params).d(key);
  }

  static transHtml(key: string, params?: TranslationParameter) {
    return intl.getHTML(key, params).d(key);
  }
}
