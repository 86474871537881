import CurrencyModel from './CurrencyModel';

export default class FinanceModel {
  id: number | null;
  defaultCurrency: CurrencyModel | null;
  defaultVatRate: {
    id: number;
    rate: number;
  } | null;
  priceIncludeVat: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.defaultCurrency = data.defaultCurrency
      ? new CurrencyModel(data.defaultCurrency)
      : null;
    this.defaultVatRate = data.defaultVatRate;
    this.priceIncludeVat = data.priceIncludeVat;
  }
}
