import { get, getUrl } from '../../services';

export async function getBarcodesReportsApi(params: any) {
  return await get(
    getUrl('reports/projects/' + params.idProject + '/barcodes', params.args)
  );
}

export async function getBasketsReportsApi(params: any) {
  return await get(
    getUrl('reports/projects/' + params.idProject + '/baskets', params.args)
  );
}

export async function getPosProductReportsApi(params: any) {
  return await get(
    getUrl('reports/pos/' + params.idPos + '/turnover/products', params.args)
  );
}
