import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
export const MainTextField = (props) => {
    const inputProps = {
        min: 0,
    };
    if (props.maxLength) {
        inputProps.maxLength = props.maxLength;
    }
    return (_jsxs(_Fragment, { children: [props.labelText && !props.label && (_jsx("div", { className: `mb-1 ${props.required && 'required'}`, children: props.labelText })), _jsx(TextField, Object.assign({ InputProps: {
                    endAdornment: ((props.required && !props.value) || props.error) && (_jsx(ErrorOutlineIcon, { className: 'text-danger' })),
                    inputProps: inputProps,
                }, variant: "outlined", size: "small", error: (props.required && !props.value) || props.error, fullWidth: true, onKeyDown: props.onEnter
                    ? (ev) => {
                        var _a;
                        if (ev.key === 'Enter') {
                            (_a = props.onEnter) === null || _a === void 0 ? void 0 : _a.call(props);
                            ev.preventDefault();
                        }
                    }
                    : undefined }, props))] }));
};
