import { productTypesConstants } from '../constants';

export const getProductTypesRequest = () => ({
  type: productTypesConstants.PRODUCT_TYPES_GET_REQUEST,
});

export const getProductTypesSuccess = (productTypes: any) => ({
  type: productTypesConstants.PRODUCT_TYPES_GET_SUCCESS,
  productTypes,
});

export const getProductTypesFailure = (error: any) => ({
  type: productTypesConstants.PRODUCT_TYPES_GET_FAILURE,
  error,
});
