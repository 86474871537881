import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { errorConstants } from '../constants';
import { postErrorInSlackApi } from '../api';
import { postErrorInSlackFailure, postErrorInSlackSuccess } from '../actions';

export function* fetchPostErrorInSlack(action: any): any {
  try {
    const error = yield call(postErrorInSlackApi, { params: action.params });
    yield put(postErrorInSlackSuccess(error));
  } catch (error) {
    yield put(postErrorInSlackFailure(error));
  }
}

export function* postErrorInSlack() {
  yield takeLatest(
    errorConstants.POST_ERROR_SLACK_REQUEST,
    fetchPostErrorInSlack
  );
}

export default function* errorSaga() {
  yield all([fork(postErrorInSlack)]);
}
