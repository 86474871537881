import { currentProject } from '../Global/currentProject';

export type ProductTranslationType = {
  id?: number;
  description: string;
  locale: string;
  marketingName: string;
  shortDescription: string;
  shortName: string;
};

export default class ProductModel {
  id: number;
  barcodeValue: string;
  cashlessValue: number;
  entryDateStart: Date | null;
  entryDateStop: Date | null;
  fees: number;
  formuleCategory: string;
  isNfcTagRequired: boolean;
  isOnSite: boolean;
  isPrintAtHome: boolean;
  marketingName: string;
  name: string;
  options: boolean;
  platform: number;
  price: number;
  priceOnTicket: null | number;
  priceWithVat: number;
  priceWithoutVat: number;
  productType: string;
  quantityIncrement: number;
  quantityMaxPerSale: null | number;
  quantityMinPerSale: null | number;
  quantityStock: null | number;
  quantityStockAllocated: null | number;
  saleDateStart: Date | null;
  saleDateStop: Date | null;
  saleStatus: string;
  sessions: boolean;
  translations: ProductTranslationType[];

  constructor(data: any) {
    this.id = data.id;
    this.barcodeValue = data.barcodeValue;
    this.cashlessValue = data.cashlessValue;
    this.entryDateStart = data.entryDateStart;
    this.entryDateStop = data.entryDateStop;
    this.fees = data.fees;
    this.formuleCategory = data.formuleCategory
      ? data.formuleCategory
          .map((formule: any) => formule.category.name)
          .join(', ')
      : [];
    this.isNfcTagRequired = data.isNfcTagRequired;
    this.isOnSite = data.isOnSite;
    this.isPrintAtHome = data.isPrintAtHome;
    this.marketingName = data.marketingName;
    this.name = data.name;
    this.options = data.options;
    this.platform = data.platform;
    this.price = data.price;
    this.priceOnTicket = data.priceOnTicket;
    this.priceWithVat = data.priceWithVat;
    this.priceWithoutVat = data.priceWithoutVat;
    this.productType = data.productType;
    this.quantityIncrement = data.quantityIncrement;
    this.quantityMaxPerSale = data.quantityMaxPerSale;
    this.quantityMinPerSale = data.quantityMinPerSale;
    this.quantityStock = data.quantityStock;
    this.quantityStockAllocated = data.quantityStockAllocated;
    this.saleDateStart = data.saleDateStart;
    this.saleDateStop = data.saleDateStop;
    this.saleStatus = data.saleStatus;
    this.sessions = data.sessions;
    this.translations = createProductTranslations(data.translations);
  }
}

export const createProductTranslations = (
  translations: any
): ProductTranslationType[] => {
  let allTranslations: ProductTranslationType[] = [];

  currentProject.allowedLanguages.forEach((language: string) => {
    const found = translations?.find(
      (translation: any) => translation.locale === language
    );

    if (found) {
      allTranslations.push(found);
    } else {
      allTranslations.push({
        description: '',
        locale: language,
        marketingName: '',
        shortDescription: '',
        shortName: '',
      });
    }
  });
  return allTranslations;
};
