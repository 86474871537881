import RoleModel from './RoleModel';
import UserProjectModel from './UserProjectModel';

export default class UserSelfProjectModel {
  id: number;
  role: RoleModel;
  userProject: UserProjectModel;

  constructor(data: any) {
    this.id = data.id;
    this.role = new RoleModel(data.role);
    this.userProject = new UserProjectModel(data.userProject);
  }
}
