import { currentProject } from '../Global/currentProject';

type FormTypes =
  | 'TextType'
  | 'UrlType'
  | 'BirthdayType'
  | 'PhoneNumberType'
  | 'GenderType'
  | 'CountryType'
  | 'CheckboxType'
  | 'ChoiceType'
  | 'EmailType'
  | 'DynFormFileType'
  | 'IntegerType'
  | 'TextareaType'
  | 'UserPictureType';

type identifyingDataNameTypes = 'dynFormTitle' | 'dynFormSubTitle';

export default class QuestionModel {
  formType: FormTypes;
  help: null | string;
  htmlAttributes: null | any;
  icon: null | any;
  id: number;
  identifyingDataName: identifyingDataNameTypes;
  internalNameUpperCase: string;
  propertyName: string;
  locale: string;
  maxLength: null | number;
  name: string;
  option: any[];
  placeholder: null | string;
  position: number;
  predefinedWidget: boolean;
  questionOptions: null | any;
  required: boolean | null;
  saveInOrganization: boolean;
  title: boolean;
  titleDepth: number;
  titleLevel: string;
  uniqueId: string;
  translations: translationQuestionType[];

  constructor(data: any) {
    this.formType = data.formType;
    this.help = data.help;
    this.htmlAttributes = data.htmlAttributes;
    this.icon = data.icon;
    this.id = data.id;
    this.identifyingDataName = data.identifyingDataName;
    this.internalNameUpperCase = data.internalNameUpperCase;
    this.propertyName = data.property_name;
    this.locale = data.locale;
    this.maxLength = data.maxLength ? data.maxLength : 0;
    this.name = data.name ? data.name : '';
    this.option = data.option;
    this.placeholder = data.placeholder;
    this.position = data.position;
    this.predefinedWidget = data.predefinedWidget;
    this.questionOptions = data.questionOptions;
    this.required = !data.title ? data.required : null;
    this.saveInOrganization = data.saveInOrganization;
    this.title = data.title;
    this.titleDepth = data.titleDepth;
    this.titleLevel = data.titleLevel;
    this.uniqueId = data.uniqueId;
    this.translations = createQuestionTranslations(data.translations);
  }
}

export type questionInputs = 'name' | 'help' | 'placeholder';
export type ChoiceItemType = {
  key: string;
  value: string;
  placeholder: string;
};
export type translationQuestionType = {
  locale: string;
  name: string;
  help: string;
  placeholder: string;
  choices: ChoiceItemType[];
};

const createQuestionTranslations = (translations: any) => {
  let newTranslations: translationQuestionType[] = [];
  let defaultTranslationsChoice: any = {};

  if (translations) {
    currentProject.allowedLanguages.forEach((locale: string) => {
      const currentTrad = translations.find(
        (translation: any) => translation.locale === locale
      );

      if (currentTrad) {
        let multipleChoices: any[] = [];
        if (currentTrad.locale === currentProject.defaultLanguage) {
          defaultTranslationsChoice = currentTrad.option?.list;
        }

        if (currentTrad.option?.list) {
          multipleChoices = Object.keys(currentTrad.option.list).map((key) => ({
            key: key,
            value: currentTrad.option.list[key],
            placeholder: defaultTranslationsChoice[key] ?? '',
          }));
        }

        newTranslations.push({
          locale: currentTrad.locale,
          name: currentTrad.name,
          help: currentTrad.help,
          placeholder: currentTrad.placeholder,
          choices: multipleChoices,
        });
      } else {
        const object: translationQuestionType = {
          locale: locale,
          name: '',
          help: '',
          placeholder: '',
          choices: [],
        };
        newTranslations.push(object);
      }
    });
  }

  return newTranslations;
};
