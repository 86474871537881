import { jsx as _jsx } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import './index.css';
export const MainButton = (props) => {
    const isContain = props.buttonType === 'contain';
    return (_jsx(Button, Object.assign({}, props, { className: props.className +
            (props.cancel
                ? isContain
                    ? ' cancelButton'
                    : ' cancelButtonOutlined'
                : isContain
                    ? ' primaryButton'
                    : ' primaryButtonOutlined'), style: props.fullWidth ? { width: '100%' } : {}, startIcon: props.loading ? (_jsx(CircularProgress, { className: 'loadingSend-icon' })) : props.startIcon ? (props.startIcon) : null, disabled: props.loading || props.disabled, children: props.children })));
};
