import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { printAtHomeConstants } from '../constants';
import { getPrintAtHomeApi } from '../api/PrintAtHome';
import {
  getPrintAtHomesFailure,
  getPrintAtHomesSuccess,
} from '../actions/printAtHome.action';

export function* fetchGetPrintAtHome(action: any): any {
  try {
    const printAtHomes = yield call(getPrintAtHomeApi, action.idProject);
    yield put(getPrintAtHomesSuccess(printAtHomes));
  } catch (error) {
    yield put(getPrintAtHomesFailure(error));
  }
}

export function* getPrintAtHome() {
  yield takeLatest(
    printAtHomeConstants.GET_PRINT_AT_HOME_REQUEST,
    fetchGetPrintAtHome
  );
}

export default function* printAtHomeSaga() {
  yield all([fork(getPrintAtHome)]);
}
