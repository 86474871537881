import { barcodesConstants } from '../constants';
import BarcodeModel from '../../Models/BarcodeModel';
import ErrorModel from '../../Models/ErrorModel';

const initialState: any = {
  barcodesList: null,
  updatedBarcode: null,
  sendBarcode: null,
};

let listBarcodes: BarcodeModel[] = [];
let sendBarcodes: number[] = [];

const barcodes = (state = initialState, action: any) => {
  switch (action.type) {
    case barcodesConstants.BARCODES_GET_BY_PROJECT_REQUEST: {
      return {
        ...state,
        barcodesList: { barcodes: [], error: false, loading: true },
      };
    }

    case barcodesConstants.BARCODES_GET_BY_PROJECT_SUCCESS: {
      listBarcodes = action.barcodesList.data.map(
        (barcode: any) => new BarcodeModel(barcode)
      );

      return {
        ...state,
        barcodesList: {
          barcodes: listBarcodes,
          meta: action.barcodesList.meta,
          error: false,
          loading: false,
        },
      };
    }

    case barcodesConstants.BARCODES_GET_BY_PROJECT_FAILURE: {
      return {
        ...state,
        barcodesList: { barcodes: [], error: true, loading: false },
      };
    }

    case barcodesConstants.BARCODE_PUT_REQUEST: {
      return {
        ...state,
        updatedBarcode: { barcode: null, error: false, loading: true },
      };
    }

    case barcodesConstants.BARCODE_PUT_SUCCESS: {
      return {
        ...state,
        updatedBarcode: {
          barcode: action.updatedBarcode.data,
          error: false,
          loading: false,
        },
      };
    }

    case barcodesConstants.BARCODE_PUT_FAILURE: {
      return {
        ...state,
        updatedBarcode: { barcode: null, error: true, loading: false },
      };
    }

    case barcodesConstants.SEND_BARCODE_REQUEST: {
      return {
        ...state,
        sendBarcode: { barcode: sendBarcodes, error: false, loading: true },
      };
    }

    case barcodesConstants.SEND_BARCODE_SUCCESS: {
      sendBarcodes = sendBarcodes.concat(action.response.data.barcode);
      return {
        ...state,
        sendBarcode: {
          barcode: sendBarcodes,
          error: false,
          loading: false,
        },
      };
    }

    case barcodesConstants.SEND_BARCODE_FAILURE: {
      return {
        ...state,
        sendBarcode: {
          barcode: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default barcodes;
