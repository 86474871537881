import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import UserInfo from '../UserInfo';
import SidenavContent from './SidenavContent';
import { Drawer } from '@mui/material';

interface SideNavInterface {
  onToggleCollapsedNav: () => void;
  navCollapsed: boolean;
}

const SideNav = (props: SideNavInterface) => (
  <>
    <div className={`app-sidebar d-none d-md-flex`}>
      <Drawer
        variant={'permanent'}
        classes={{ root: 'side-nav-root', paper: 'side-nav' }}
        open
      >
        <UserInfo />
        <SidenavContent />
      </Drawer>
    </div>

    <SwipeableDrawer
      className="d-block d-md-none"
      variant={'temporary'}
      open={props.navCollapsed}
      onOpen={props.onToggleCollapsedNav}
      onClose={props.onToggleCollapsedNav}
      classes={{ paper: 'side-nav' }}
    >
      <UserInfo />
      <SidenavContent />
    </SwipeableDrawer>
  </>
);

export default SideNav;
