import { errorConstants } from '../constants';

export const postErrorInSlackRequest = (params: any) => ({
  type: errorConstants.POST_ERROR_SLACK_REQUEST,
  params,
});

export const postErrorInSlackSuccess = (response: any) => ({
  type: errorConstants.POST_ERROR_SLACK_SUCCESS,
  response,
});

export const postErrorInSlackFailure = (error: any) => ({
  type: errorConstants.POST_ERROR_SLACK_FAILURE,
  error,
});
