import ProductModel from './ProductModel';

export default class BasketItemModel {
  id: number;
  formule: ProductModel;
  isFees: boolean;
  isLocked: boolean;
  isPreSale: boolean;
  priceBrutWithVat: string;
  priceBrutWithoutVat: string;
  priceUnitWithVat: string;
  priceUnitWithoutVat: string;
  priceWithVat: number;
  priceWithoutVat: number;
  quantity: number;

  constructor(data: any) {
    this.id = data.id;
    this.formule = new ProductModel(data.formule);
    this.isFees = data.isFees;
    this.isLocked = data.isLocked;
    this.isPreSale = data.isPreSale;
    this.priceBrutWithVat = data.priceBrutWithVat;
    this.priceBrutWithoutVat = data.priceBrutWithoutVat;
    this.priceUnitWithVat = data.priceUnitWithVat;
    this.priceUnitWithoutVat = data.priceUnitWithoutVat;
    this.priceWithVat = data.priceWithVat;
    this.priceWithoutVat = data.priceWithoutVat;
    this.quantity = data.quantity ? data.quantity : 0;
  }
}
