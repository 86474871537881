import dayjs from 'dayjs';

export function getDayjsLocale(locale?: string) {
  if (locale && dayjs) {
    const lowerLocale = locale.toLocaleLowerCase();
    try {
      require('dayjs/locale/' + lowerLocale + '.js');
      dayjs.locale(lowerLocale);
    } catch (error) {
      const baseLocale = locale.slice(0, 2);
      try {
        require('dayjs/locale/' + baseLocale + '.js');
        dayjs.locale(baseLocale);
      } catch (error) {
        require('dayjs/locale/en.js');
        dayjs.locale('en');
      }
    }
  }
}
