import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getOrganizationSuccess,
  getOrganizationFailure,
  putOrganizationSuccess,
  putOrganizationFailure,
  getOrganizationStatusSuccess,
  getOrganizationStatusFailure,
} from '../actions';
import { organizationConstants } from '../constants';
import {
  getOrganizationApi,
  getOrganizationStatusApi,
  putOrganizationApi,
} from '../api';

export function* fetchGetOrganization(action: any): any {
  try {
    const organization = yield call(getOrganizationApi, {
      id: action.id,
    });
    yield put(getOrganizationSuccess(organization));
  } catch (error) {
    yield put(getOrganizationFailure(error));
  }
}

export function* fetchPutOrganization(action: any): any {
  try {
    const organization = yield call(putOrganizationApi, {
      id: action.id,
      payload: action.payload,
    });
    yield put(putOrganizationSuccess(organization));
  } catch (error) {
    yield put(putOrganizationFailure(error));
  }
}

export function* fetchGetOrganizationStatus(action: any): any {
  try {
    const organization = yield call(getOrganizationStatusApi, {
      id: action.id,
    });
    yield put(getOrganizationStatusSuccess(organization));
  } catch (error) {
    yield put(getOrganizationStatusFailure(error));
  }
}

export function* getOrganization() {
  yield takeLatest(
    organizationConstants.GET_ORGANIZATION_REQUEST,
    fetchGetOrganization
  );
}

export function* putOrganization() {
  yield takeLatest(
    organizationConstants.PUT_ORGANIZATION_REQUEST,
    fetchPutOrganization
  );
}

export function* getOrganizationStatus() {
  yield takeLatest(
    organizationConstants.GET_ORGANIZATION_STATUS_REQUEST,
    fetchGetOrganizationStatus
  );
}

export default function* organizationSaga() {
  yield all([
    fork(getOrganization),
    fork(putOrganization),
    fork(getOrganizationStatus),
  ]);
}
