import { contentsConstants } from '../constants';

export interface getContentsParams {
  project_id: number;
  locale: string;
}

export const getContentsRequest = (params: getContentsParams) => ({
  type: contentsConstants.CONTENTS_GET_REQUEST,
  params,
});

export const getContentsSuccess = (contents: any) => ({
  type: contentsConstants.CONTENTS_GET_SUCCESS,
  contents,
});

export const getContentsFailure = (error: any) => ({
  type: contentsConstants.CONTENTS_GET_FAILURE,
  error,
});

export const getContentContentsRequest = (reference: string, params: any) => ({
  type: contentsConstants.GET_CONTENT_CONTENTS_REQUEST,
  reference,
  params,
});

export const getContentContentsSuccess = (response: any) => ({
  type: contentsConstants.GET_CONTENT_CONTENTS_SUCCESS,
  response,
});

export const getContentContentsFailure = (error: any) => ({
  type: contentsConstants.GET_CONTENT_CONTENTS_FAILURE,
  error,
});

export const getContentMailRequest = (reference: string, params: any) => ({
  type: contentsConstants.GET_CONTENT_MAIL_REQUEST,
  reference,
  params,
});

export const getContentMailSuccess = (response: any) => ({
  type: contentsConstants.GET_CONTENT_MAIL_SUCCESS,
  response,
});

export const getContentMailFailure = (error: any) => ({
  type: contentsConstants.GET_CONTENT_MAIL_FAILURE,
  error,
});

export const putContentMailRequest = (
  reference: string,
  projectId: number,
  params: any
) => ({
  type: contentsConstants.PUT_CONTENT_MAIL_REQUEST,
  reference,
  projectId,
  params,
});

export const putContentMailSuccess = (response: any) => ({
  type: contentsConstants.PUT_CONTENT_MAIL_SUCCESS,
  response,
});

export const putContentMailFailure = (error: any) => ({
  type: contentsConstants.PUT_CONTENT_MAIL_FAILURE,
  error,
});

export const putContentContentsRequest = (
  reference: string,
  projectId: number,
  params: any
) => ({
  type: contentsConstants.PUT_CONTENT_CONTENTS_REQUEST,
  reference,
  projectId,
  params,
});

export const putContentContentsSuccess = (response: any) => ({
  type: contentsConstants.PUT_CONTENT_CONTENTS_SUCCESS,
  response,
});

export const putContentContentsFailure = (error: any) => ({
  type: contentsConstants.PUT_CONTENT_CONTENTS_FAILURE,
  error,
});

export const clearContent = () => ({
  type: contentsConstants.CLEAR_CONTENT,
});
