import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getProjectDetailsSuccess,
  getProjectDetailsFailure,
  putProjectDetailsSuccess,
  putProjectDetailsFailure,
  getProjectsSelfSuccess,
  getProjectsSelfFailure,
  getProjectCostSuccess,
  getProjectCostFailure,
  archiveProjectSuccess,
  archiveProjectFailure,
} from '../actions';
import { projectConstants } from '../constants';
import {
  putProjectDetailsApi,
  getProjectDetailsApi,
  getProjectsSelfApi,
  getProjectCostApi,
  archiveProjectApi,
} from '../api';

export function* fetchProjectDetails(action: any): any {
  try {
    const projectDetails = yield call(getProjectDetailsApi, {
      idProject: action.idProject,
    });
    yield put(getProjectDetailsSuccess(projectDetails));
  } catch (error) {
    yield put(getProjectDetailsFailure(error));
  }
}

export function* fetchPutProjectDetails(action: any): any {
  try {
    const updatedProjectDetails = yield call(putProjectDetailsApi, {
      idProject: action.idProject,
      projectDetails: action.projectDetails,
    });
    yield put(putProjectDetailsSuccess(updatedProjectDetails));
  } catch (error) {
    yield put(putProjectDetailsFailure(error));
  }
}

export function* fetchProjectsSelf(): any {
  try {
    const projects = yield call(getProjectsSelfApi);
    yield put(getProjectsSelfSuccess(projects));
  } catch (error) {
    yield put(getProjectsSelfFailure(error));
  }
}

export function* fetchProjectCost(action: any): any {
  try {
    const projectCost = yield call(getProjectCostApi, {
      idProject: action.idProject,
    });
    yield put(getProjectCostSuccess(projectCost));
  } catch (error) {
    yield put(getProjectCostFailure(error));
  }
}

export function* fetchArchiveProject(action: any): any {
  try {
    const result = yield call(archiveProjectApi, {
      idProject: action.idProject,
    });
    yield put(archiveProjectSuccess(result));
  } catch (error) {
    yield put(archiveProjectFailure(error));
  }
}

export function* getProjectDetails() {
  yield takeLatest(
    projectConstants.PROJECT_DETAILS_GET_REQUEST,
    fetchProjectDetails
  );
}

export function* putProjectDetails() {
  yield takeLatest(
    projectConstants.PROJECT_DETAILS_PUT_REQUEST,
    fetchPutProjectDetails
  );
}

export function* getProjectsSelf() {
  yield takeLatest(
    projectConstants.PROJECTS_SELF_GET_REQUEST,
    fetchProjectsSelf
  );
}

export function* getProjectCost() {
  yield takeLatest(projectConstants.PROJECT_GET_COST_REQUEST, fetchProjectCost);
}

export function* archiveProject() {
  yield takeLatest(
    projectConstants.ARCHIVE_PROJECT_REQUEST,
    fetchArchiveProject
  );
}

export default function* projectSaga() {
  yield all([
    fork(getProjectDetails),
    fork(putProjectDetails),
    fork(getProjectsSelf),
    fork(getProjectCost),
    fork(archiveProject),
  ]);
}
