import OnboardingModel from './OnboardingModel';

export default class OrganizationStatusModel {
  missingLegalData: boolean;
  missingBankData: boolean;
  missingAddressData: boolean;
  showMissingBanner: boolean;
  onboarding: OnboardingModel;

  constructor(data: any) {
    this.missingLegalData = data.missingLegalData?.status;
    this.missingBankData = data.missingBankData?.status;
    this.missingAddressData = data.missingAddressData?.status;
    this.showMissingBanner = this.handleMissingBanner();
    this.onboarding = new OnboardingModel(data.onboarding?.ticketing);
  }

  private handleMissingBanner = () =>
    this.missingLegalData || this.missingBankData || this.missingAddressData;
}
