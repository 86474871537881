export default class TransactionModel {
  id: number;
  amount: string;
  brand: string | null;
  cardName: string | null;
  cardNumber: string | null;
  country: string | null;
  createdAt: Date | null;
  currency: string;
  expiryDate: Date | null;
  ip: string;
  orderId: string;
  updatedAt: Date | null;

  constructor(data: any) {
    this.id = data.id;
    this.amount = data.amount;
    this.brand = data.brand;
    this.cardName = data.cardName;
    this.cardNumber = data.cardNumber;
    this.country = data.country;
    this.createdAt = data.createdAtUTC ? new Date(data.createdAtUTC) : null;
    this.currency = data.currency;
    this.expiryDate = data.expiryDate ? new Date(data.expiryDate) : null;
    this.ip = data.ip;
    this.orderId = data.orderId;
    this.updatedAt = data.updatedAtUTC ? new Date(data.updatedAtUTC) : null;
  }
}
