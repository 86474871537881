export const organizationConstants = {
  GET_ORGANIZATION_REQUEST: 'GET_ORGANIZATION_REQUEST',
  GET_ORGANIZATION_SUCCESS: 'GET_ORGANIZATION_SUCCESS',
  GET_ORGANIZATION_FAILURE: 'GET_ORGANIZATION_FAILURE',

  PUT_ORGANIZATION_REQUEST: 'PUT_ORGANIZATION_REQUEST',
  PUT_ORGANIZATION_SUCCESS: 'PUT_ORGANIZATION_SUCCESS',
  PUT_ORGANIZATION_FAILURE: 'PUT_ORGANIZATION_FAILURE',

  GET_ORGANIZATION_STATUS_REQUEST: 'GET_ORGANIZATION_STATUS_REQUEST',
  GET_ORGANIZATION_STATUS_SUCCESS: 'GET_ORGANIZATION_STATUS_SUCCESS',
  GET_ORGANIZATION_STATUS_FAILURE: 'GET_ORGANIZATION_STATUS_FAILURE',

  CLEAR_ORGANIZATION_REQUEST: 'CLEAR_ORGANIZATION_REQUEST',
};
