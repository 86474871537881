import { printAtHomeConstants } from '../constants';

export const getPrintAtHomesRequest = (idProject: number) => ({
  type: printAtHomeConstants.GET_PRINT_AT_HOME_REQUEST,
  idProject,
});

export const getPrintAtHomesSuccess = (response: any) => ({
  type: printAtHomeConstants.GET_PRINT_AT_HOME_SUCCESS,
  response,
});

export const getPrintAtHomesFailure = (error: any) => ({
  type: printAtHomeConstants.GET_PRINT_AT_HOME_FAILURE,
  error,
});
