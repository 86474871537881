import AppLanguages, { languageDataInterface } from '../lngProvider';
import ImageModel from './ImageModel';
import ShopModel from './ShopModel';
import FinanceModel from './FinanceModel';
import StringMatcher from '../services/stringComparator';

export default class ProjectModel {
  id: number;
  aliasName: string | null;
  audienceDateStartUTC: Date;
  audienceDateStopUTC: Date;
  brandingName: string;
  canceled: boolean;
  isActive: boolean;
  name: string;
  posIsOpen: boolean;
  posMaxNegativeDebit: number;
  type: string;
  accommodationSiteMap: null | string;
  allowedLanguages: string[];
  colorBackground: string;
  colorPrimary: string;
  colorSecondary: string;
  defaultCountry: string;
  defaultLanguage: string;
  defaultNfcSupportType: number;
  facebookPixel: string;
  facebookUrl: string;
  googleAdwordConversionId: null | number;
  googleAdwordConversionLabel: null | string;
  googleAnalyticsCode: string;
  googleTagManagerId: string;
  instagramUrl: string;
  saleHourSwitch: string;
  setupDateStartUTC: string;
  setupDateStopUTC: string;
  timezone: string;
  twitterUrl: string;
  websiteUrl: string;
  organizationId: number;
  projectFeesId: number;
  picture_logo: ImageModel | null;
  language: languageDataInterface;
  projectFinance: FinanceModel | null;
  posForShop: ShopModel | null;
  stringMatcher: StringMatcher;

  constructor(data: any) {
    this.id = data.id;
    this.aliasName = data.aliasName;
    this.audienceDateStartUTC = new Date(data.audienceDateStartUTC);
    this.audienceDateStopUTC = new Date(data.audienceDateStopUTC);
    this.brandingName = data.brandingName;
    this.canceled = data.canceled;
    this.isActive = data.isActive;
    this.name = data.name;
    this.posIsOpen = data.posIsOpen;
    this.posMaxNegativeDebit = data.posMaxNegativeDebit;
    this.type = data.type;
    this.accommodationSiteMap = data.accommodationSiteMap;
    this.allowedLanguages = data.allowedLanguages;
    this.colorBackground = data.colorBackground;
    this.colorPrimary = data.colorPrimary;
    this.colorSecondary = data.colorSecondary;
    this.defaultCountry = data.defaultCountry;
    this.defaultLanguage = data.defaultLanguage;
    this.defaultNfcSupportType = data.defaultNfcSupportType;
    this.facebookPixel = data.facebookPixel;
    this.facebookUrl = data.facebookUrl;
    this.googleAdwordConversionId = data.googleAdwordConversionId;
    this.googleAdwordConversionLabel = data.googleAdwordConversionLabel;
    this.googleAnalyticsCode = data.googleAnalyticsCode;
    this.googleTagManagerId = data.googleTagManagerId;
    this.instagramUrl = data.instagramUrl;
    this.saleHourSwitch = data.saleHourSwitch;
    this.setupDateStartUTC = data.setupDateStartUTC;
    this.setupDateStopUTC = data.setupDateStopUTC;
    this.timezone = data.timezone;
    this.twitterUrl = data.twitterUrl;
    this.websiteUrl = data.websiteUrl;
    this.organizationId = data.organization?.id;
    this.projectFeesId = data.projectFees?.id;
    this.picture_logo = data.picture_logo
      ? new ImageModel(data.picture_logo)
      : null;
    this.language = getDefaultLanguage();
    this.projectFinance = data.projectFinance
      ? new FinanceModel(data.projectFinance)
      : null;
    this.posForShop = data.posForShop ? new ShopModel(data.posForShop) : null;
    this.stringMatcher = new StringMatcher(getDefaultLanguage().locale);
  }

  updateLocale = (language: languageDataInterface) => {
    localStorage.setItem('locale', language.locale);
    this.stringMatcher = new StringMatcher(language.locale);
    this.language = language;
  };
}

const getDefaultLanguage = () => {
  const storageLocale = localStorage.getItem('locale');
  const getLanguage = AppLanguages.find(
    (appLanguage: languageDataInterface) => appLanguage.locale === storageLocale
  );
  if (getLanguage) {
    return getLanguage;
  }
  return AppLanguages[0];
};
