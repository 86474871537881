import { mailsConstants } from '../constants';
import MailDetailModel from '../../Models/MailDetailModel';

const initialState: any = {
  mail: null,
};

const mails = (state = initialState, action: any) => {
  switch (action.type) {
    case mailsConstants.MAIL_GET_REQUEST: {
      return {
        ...state,
        mail: { details: null, error: false, loading: true },
      };
    }

    case mailsConstants.MAIL_GET_SUCCESS: {
      return {
        ...state,
        mail: {
          details: new MailDetailModel(action.mail.data),
          error: false,
          loading: false,
        },
      };
    }

    case mailsConstants.MAIL_GET_FAILURE: {
      return {
        ...state,
        mail: { details: null, error: true, loading: false },
      };
    }

    case mailsConstants.CLEAR_DETAIL_MAIL: {
      return {
        ...state,
        mail: null,
      };
    }

    default:
      return state;
  }
};

export default mails;
