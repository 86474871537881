import { all } from 'redux-saga/effects';
import formsSaga from './Forms';
import reportsTicketingSaga from './ReportsTicketing';
import userSaga from './User';
import projectSaga from './Project';
import attendeesSaga from './Attendees';
import brandingSaga from './Branding';
import categoriesSaga from './Categories';
import basketsSaga from './Baskets';
import ticketsSaga from './Tickets';
import barcodesSaga from './Barcodes';
import uploadsSaga from './Uploads';
import mailsSaga from './Mails';
import posSaga from './Pos';
import vatRatesSaga from './VatRates';
import productTypeSaga from './ProductTypes';
import errorSaga from './Error';
import organizationSaga from './Organization';
import adminSaga from './Admin';
import addressesSaga from './Addresses';
import contentsSaga from './Contents';
import printAtHomeSaga from './PrintAtHome';

export default function* appSaga() {
  yield all([
    attendeesSaga(),
    basketsSaga(),
    brandingSaga(),
    categoriesSaga(),
    formsSaga(),
    projectSaga(),
    reportsTicketingSaga(),
    userSaga(),
    ticketsSaga(),
    barcodesSaga(),
    uploadsSaga(),
    addressesSaga(),
    mailsSaga(),
    posSaga(),
    vatRatesSaga(),
    productTypeSaga(),
    errorSaga(),
    organizationSaga(),
    adminSaga(),
    contentsSaga(),
    printAtHomeSaga(),
  ]);
}
