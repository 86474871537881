import { categoriesConstants } from '../constants';
import CategoryModel from '../../Models/CategoryModel';
import ErrorModel from '../../Models/ErrorModel';

const initialState: any = {
  category: null,
  categoriesList: null,
  newCategoryTranslations: null,
  updatedCategoryTranslations: null,
  categoryTranslations: null,
  newCategoryProduct: null,
  deletedCategory: null,
  newCategory: null,
};

let listCategories: any[] = [];
const categories = (state = initialState, action: any) => {
  switch (action.type) {
    case categoriesConstants.CATEGORY_DELETE_REQUEST: {
      return {
        ...state,
        deletedCategory: { category: null, error: false, loading: true },
      };
    }

    case categoriesConstants.CATEGORY_DELETE_SUCCESS: {
      return {
        ...state,
        deletedCategory: {
          category: true,
          error: false,
          loading: false,
        },
      };
    }

    case categoriesConstants.CATEGORY_DELETE_FAILURE: {
      return {
        ...state,
        deletedCategory: {
          category: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case categoriesConstants.CATEGORY_GET_TRANSLATIONS_REQUEST: {
      return {
        ...state,
        categoryTranslations: { details: null, error: false, loading: true },
      };
    }

    case categoriesConstants.CATEGORY_GET_TRANSLATIONS_SUCCESS: {
      const translations = action.categoryTranslations.data;

      return {
        ...state,
        categoryTranslations: {
          details: translations,
          error: false,
          loading: false,
        },
      };
    }

    case categoriesConstants.CATEGORY_GET_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        categoryTranslations: { details: null, error: true, loading: false },
      };
    }

    case categoriesConstants.CATEGORIES_GET_BY_PROJECT_REQUEST: {
      return {
        ...state,
        categoriesList: {
          categories: listCategories,
          error: false,
          loading: true,
        },
      };
    }

    case categoriesConstants.CATEGORIES_GET_BY_PROJECT_SUCCESS: {
      listCategories = action.categories.data.map(
        (category: any) => new CategoryModel(category)
      );

      return {
        ...state,
        categoriesList: {
          categories: listCategories,
          error: false,
          loading: false,
        },
      };
    }

    case categoriesConstants.CATEGORIES_GET_BY_PROJECT_FAILURE: {
      return {
        ...state,
        categoriesList: { categories: null, error: true, loading: false },
      };
    }

    case categoriesConstants.CATEGORY_POST_BY_PROJECT_REQUEST: {
      return {
        ...state,
        newCategory: { category: null, error: false, loading: true },
      };
    }

    case categoriesConstants.CATEGORY_POST_BY_PROJECT_SUCCESS: {
      return {
        ...state,
        newCategory: {
          category: action.newCategory.data,
          error: false,
          loading: false,
        },
      };
    }

    case categoriesConstants.CATEGORY_POST_BY_PROJECT_FAILURE: {
      return {
        ...state,
        newCategory: { category: null, error: true, loading: false },
      };
    }

    case categoriesConstants.CATEGORY_POST_PRODUCT_REQUEST: {
      return {
        ...state,
        newCategoryProduct: {
          categoryProduct: null,
          error: false,
          loading: true,
        },
      };
    }

    case categoriesConstants.CATEGORY_POST_PRODUCT_SUCCESS: {
      return {
        ...state,
        newCategoryProduct: {
          categoryProduct: action.newCategoryProduct.data,
          error: false,
          loading: false,
        },
      };
    }

    case categoriesConstants.CATEGORY_POST_PRODUCT_FAILURE: {
      return {
        ...state,
        newCategoryProduct: {
          categoryProduct: null,
          error: true,
          loading: false,
        },
      };
    }

    case categoriesConstants.CATEGORY_TRANSLATIONS_PUT_REQUEST: {
      return {
        ...state,
        updatedCategoryTranslations: {
          translations: null,
          error: false,
          loading: true,
        },
      };
    }

    case categoriesConstants.CATEGORY_TRANSLATIONS_PUT_SUCCESS: {
      return {
        ...state,
        updatedCategoryTranslations: {
          translations: action.updatedCategoryTranslations.data,
          error: false,
          loading: false,
        },
      };
    }

    case categoriesConstants.CATEGORY_TRANSLATIONS_PUT_FAILURE: {
      return {
        ...state,
        updatedCategoryTranslations: {
          translations: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case categoriesConstants.CATEGORY_TRANSLATIONS_POST_REQUEST: {
      return {
        ...state,
        newCategoryTranslations: {
          translations: null,
          error: false,
          loading: true,
        },
      };
    }

    case categoriesConstants.CATEGORY_TRANSLATIONS_POST_SUCCESS: {
      return {
        ...state,
        newCategoryTranslations: {
          translations: action.newCategoryTranslations.data,
          error: false,
          loading: false,
        },
      };
    }

    case categoriesConstants.CATEGORY_TRANSLATIONS_POST_FAILURE: {
      return {
        ...state,
        newCategoryTranslations: {
          translations: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case categoriesConstants.CLEAR_CATEGORIES: {
      return {
        ...state,
        newCategoryTranslations: null,
        updatedCategoryTranslations: null,
        categoryTranslations: null,
        newCategoryProduct: null,
        deletedCategory: null,
        newCategory: null,
      };
    }

    default:
      return state;
  }
};

export default categories;
