export const attendeesConstants = {
  ATTENDEES_GET_BY_PROJECT_REQUEST: 'ATTENDEES_GET_BY_PROJECT_REQUEST',
  ATTENDEES_GET_BY_PROJECT_SUCCESS: 'ATTENDEES_GET_BY_PROJECT_SUCCESS',
  ATTENDEES_GET_BY_PROJECT_FAILURE: 'ATTENDEES_GET_BY_PROJECT_FAILURE',

  ATTENDEE_GET_BY_ID_REQUEST: 'ATTENDEE_GET_BY_ID_REQUEST',
  ATTENDEE_GET_BY_ID_SUCCESS: 'ATTENDEE_GET_BY_ID_SUCCESS',
  ATTENDEE_GET_BY_ID_FAILURE: 'ATTENDEE_GET_BY_ID_FAILURE',

  ATTENDEE_GET_NFCTAG_REQUEST: 'ATTENDEE_GET_NFCTAG_REQUEST',
  ATTENDEE_GET_NFCTAG_SUCCESS: 'ATTENDEE_GET_NFCTAG_SUCCESS',
  ATTENDEE_GET_NFCTAG_FAILURE: 'ATTENDEE_GET_NFCTAG_FAILURE',

  ATTENDEE_GET_BARCODES_REQUEST: 'ATTENDEE_GET_BARCODES_REQUEST',
  ATTENDEE_GET_BARCODES_SUCCESS: 'ATTENDEE_GET_BARCODES_SUCCESS',
  ATTENDEE_GET_BARCODES_FAILURE: 'ATTENDEE_GET_BARCODES_FAILURE',

  ATTENDEE_GET_BASKETS_REQUEST: 'ATTENDEE_GET_BASKETS_REQUEST',
  ATTENDEE_GET_BASKETS_SUCCESS: 'ATTENDEE_GET_BASKETS_SUCCESS',
  ATTENDEE_GET_BASKETS_FAILURE: 'ATTENDEE_GET_BASKETS_FAILURE',

  ATTENDEE_GET_NOTES_REQUEST: 'ATTENDEE_GET_NOTES_REQUEST',
  ATTENDEE_GET_NOTES_SUCCESS: 'ATTENDEE_GET_NOTES_SUCCESS',
  ATTENDEE_GET_NOTES_FAILURE: 'ATTENDEE_GET_NOTES_FAILURE',

  ATTENDEE_GET_MAILS_REQUEST: 'ATTENDEE_GET_MAILS_REQUEST',
  ATTENDEE_GET_MAILS_SUCCESS: 'ATTENDEE_GET_MAILS_SUCCESS',
  ATTENDEE_GET_MAILS_FAILURE: 'ATTENDEE_GET_MAILS_FAILURE',

  ATTENDEE_POST_NOTES_REQUEST: 'ATTENDEE_POST_NOTES_REQUEST',
  ATTENDEE_POST_NOTES_SUCCESS: 'ATTENDEE_POST_NOTES_SUCCESS',
  ATTENDEE_POST_NOTES_FAILURE: 'ATTENDEE_POST_NOTES_FAILURE',
};
