import { brandingConstants } from '../constants';

export const getBrandingByProjectRequest = (idProject: number) => ({
  type: brandingConstants.BRANDING_GET_BY_PROJECT_REQUEST,
  idProject,
});

export const getBrandingByProjectSuccess = (branding: any) => ({
  type: brandingConstants.BRANDING_GET_BY_PROJECT_SUCCESS,
  branding,
});

export const getBrandingByProjectFailure = (error: any) => ({
  type: brandingConstants.BRANDING_GET_BY_PROJECT_FAILURE,
  error,
});

export const putBrandingByProjectRequest = (
  idProject: number,
  updatedBranding: any
) => ({
  type: brandingConstants.BRANDING_PUT_BY_PROJECT_REQUEST,
  idProject,
  updatedBranding,
});

export const putBrandingByProjectSuccess = (updatedBranding: any) => ({
  type: brandingConstants.BRANDING_PUT_BY_PROJECT_SUCCESS,
  updatedBranding,
});

export const putBrandingByProjectFailure = (error: any) => ({
  type: brandingConstants.BRANDING_PUT_BY_PROJECT_FAILURE,
  error,
});

export const clearBrandingByProject = () => ({
  type: brandingConstants.CLEAR_BRANDING_PUT_BY_PROJECT,
});
