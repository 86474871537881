import { posConstants } from '../constants';
import ShopConfigModel from '../../Models/ShopConfigModel';
import BasketModel from '../../Models/BasketModel';
import ProductModel from '../../Models/ProductModel';

const initialState: any = {
  productLinked: null,
  posBaskets: null,
  posProducts: null,
  newFormulePosition: null,
  shopInfo: null,
  updateShop: null,
};

let basketsList: BasketModel[] = [];
let productsList: ProductModel[] = [];

const pos = (state = initialState, action: any) => {
  switch (action.type) {
    case posConstants.POST_POS_PRODUCT_REQUEST: {
      return {
        ...state,
        productLinked: { product: null, error: false, loading: true },
      };
    }

    case posConstants.POST_POS_PRODUCT_SUCCESS: {
      return {
        ...state,
        productLinked: {
          product: action.productLinked.data,
          error: false,
          loading: false,
        },
      };
    }

    case posConstants.POST_POS_PRODUCT_FAILURE: {
      return {
        ...state,
        productLinked: { product: null, error: true, loading: false },
      };
    }

    case posConstants.GET_POS_BASKETS_REQUEST: {
      return {
        ...state,
        posBaskets: { baskets: basketsList, error: false, loading: true },
      };
    }

    case posConstants.GET_POS_BASKETS_SUCCESS: {
      basketsList = action.baskets.data.map(
        (basket: any) => new BasketModel(basket)
      );

      return {
        ...state,
        posBaskets: {
          baskets: basketsList,
          meta: action.baskets.meta,
          error: false,
          loading: false,
        },
      };
    }

    case posConstants.GET_POS_BASKETS_FAILURE: {
      return {
        ...state,
        posBaskets: { baskets: null, error: true, loading: false },
      };
    }

    case posConstants.GET_POS_PRODUCTS_REQUEST: {
      return {
        ...state,
        posProducts: { products: productsList, error: false, loading: true },
      };
    }

    case posConstants.GET_POS_PRODUCTS_SUCCESS: {
      productsList = action.products.data.map(
        (product: any) => new ProductModel(product)
      );

      return {
        ...state,
        posProducts: {
          products: productsList,
          meta: action.products.meta,
          error: false,
          loading: false,
        },
      };
    }

    case posConstants.GET_POS_PRODUCTS_FAILURE: {
      return {
        ...state,
        posProducts: { products: null, error: true, loading: false },
      };
    }

    case posConstants.PUT_FORMULE_POSITION_POS_REQUEST: {
      return {
        ...state,
        newFormulePosition: { formule: null, error: false, loading: true },
      };
    }

    case posConstants.PUT_FORMULE_POSITION_POS_SUCCESS: {
      return {
        ...state,
        newFormulePosition: {
          formule: true,
          error: false,
          loading: false,
        },
      };
    }

    case posConstants.PUT_FORMULE_POSITION_POS_FAILURE: {
      return {
        ...state,
        newFormulePosition: { formule: null, error: true, loading: false },
      };
    }

    case posConstants.GET_POS_SHOPS_REQUEST: {
      return {
        ...state,
        shopInfo: { shops: null, error: false, loading: true },
      };
    }

    case posConstants.GET_POS_SHOPS_SUCCESS: {
      return {
        ...state,
        shopInfo: {
          shops: new ShopConfigModel(action.shops.data),
          error: false,
          loading: false,
        },
      };
    }

    case posConstants.GET_POS_SHOPS_FAILURE: {
      return {
        ...state,
        shopInfo: { shops: null, error: true, loading: false },
      };
    }

    case posConstants.PATCH_POS_SHOP_REQUEST: {
      return {
        ...state,
        updateShop: { shops: null, error: false, loading: true },
        shopInfo: { shops: null, error: false, loading: false },
      };
    }

    case posConstants.PATCH_POS_SHOP_SUCCESS: {
      return {
        ...state,
        updateShop: {
          shop: action.response.data,
          error: false,
          loading: false,
        },
        shopInfo: {
          shops: new ShopConfigModel(action.response.data),
          error: false,
          loading: false,
        },
      };
    }

    case posConstants.PATCH_POS_SHOP_FAILURE: {
      return {
        ...state,
        updateShop: { shops: null, error: true, loading: false },
      };
    }

    case posConstants.OPEN_POS_SHOP_REQUEST: {
      return {
        ...state,
        updateShop: { shop: null, error: false, loading: true },
      };
    }

    case posConstants.OPEN_POS_SHOP_SUCCESS: {
      return {
        ...state,
        updateShop: {
          shop: action.response.data,
          error: false,
          loading: false,
        },
      };
    }

    case posConstants.OPEN_POS_SHOP_FAILURE: {
      return {
        ...state,
        updateShop: { shop: null, error: true, loading: false },
      };
    }

    case posConstants.CLOSE_POS_SHOP_REQUEST: {
      return {
        ...state,
        updateShop: { shop: null, error: false, loading: true },
      };
    }

    case posConstants.CLOSE_POS_SHOP_SUCCESS: {
      return {
        ...state,
        updateShop: {
          shop: action.response.data,
          error: false,
          loading: false,
        },
      };
    }

    case posConstants.CLOSE_POS_SHOP_FAILURE: {
      return {
        ...state,
        updateShop: { shop: null, error: true, loading: false },
      };
    }

    case posConstants.RESET_POS: {
      return {
        ...state,
        updateShop: null,
      };
    }

    default:
      return state;
  }
};

export default pos;
