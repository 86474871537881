export const barcodesConstants = {
  BARCODES_GET_BY_PROJECT_REQUEST: 'BARCODES_GET_BY_PROJECT_REQUEST',
  BARCODES_GET_BY_PROJECT_SUCCESS: 'BARCODES_GET_BY_PROJECT_SUCCESS',
  BARCODES_GET_BY_PROJECT_FAILURE: 'BARCODES_GET_BY_PROJECT_FAILURE',

  BARCODE_PUT_REQUEST: 'BARCODE_PUT_REQUEST',
  BARCODE_PUT_SUCCESS: 'BARCODE_PUT_SUCCESS',
  BARCODE_PUT_FAILURE: 'BARCODE_PUT_FAILURE',

  SEND_BARCODE_REQUEST: 'SEND_BARCODE_REQUEST',
  SEND_BARCODE_SUCCESS: 'SEND_BARCODE_SUCCESS',
  SEND_BARCODE_FAILURE: 'SEND_BARCODE_FAILURE',
};
