import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { pagesConstant } from '../../Constants/Constant';
import { NavLink } from 'react-router-dom';
import Translator from '../../services/translator';
import OrganizationStatusModel from '../../Models/OrganizationStatusModel';
import './RequiredConfig.css';

interface RequiredConfigProps {
  organizationStatus: OrganizationStatusModel;
}

const RequiredConfig = (props: RequiredConfigProps) => (
  <div className={'required-config-container'}>
    <div className={'required-config-icon'}>
      <ErrorOutlineIcon />
    </div>
    <div>
      <h4>{Translator.trans('popup.important.info.complete')}</h4>
      <div>{Translator.trans('popup.important.info.complete.desc')}</div>

      <ul>
        {props.organizationStatus?.missingAddressData && (
          <li>
            <NavLink to={pagesConstant.organization}>
              {Translator.trans('organization.address.billing')}
            </NavLink>
          </li>
        )}
        {props.organizationStatus?.missingBankData && (
          <li>
            <NavLink to={pagesConstant.organization}>
              {Translator.trans('organization.bank.details')}
            </NavLink>
          </li>
        )}
        {props.organizationStatus?.missingLegalData && (
          <li>
            <NavLink to={pagesConstant.organization}>
              {Translator.trans('organization.legalData')}
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  </div>
);

export default RequiredConfig;
