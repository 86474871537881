export const posConstants = {
  POST_POS_PRODUCT_REQUEST: 'POST_POS_PRODUCT_REQUEST',
  POST_POS_PRODUCT_SUCCESS: 'POST_POS_PRODUCT_SUCCESS',
  POST_POS_PRODUCT_FAILURE: 'POST_POS_PRODUCT_FAILURE',

  GET_POS_BASKETS_REQUEST: 'GET_POS_BASKETS_REQUEST',
  GET_POS_BASKETS_SUCCESS: 'GET_POS_BASKETS_SUCCESS',
  GET_POS_BASKETS_FAILURE: 'GET_POS_BASKETS_FAILURE',

  GET_POS_PRODUCTS_REQUEST: 'GET_POS_PRODUCTS_REQUEST',
  GET_POS_PRODUCTS_SUCCESS: 'GET_POS_PRODUCTS_SUCCESS',
  GET_POS_PRODUCTS_FAILURE: 'GET_POS_PRODUCTS_FAILURE',

  PUT_FORMULE_POSITION_POS_REQUEST: 'PUT_FORMULE_POSITION_POS_REQUEST',
  PUT_FORMULE_POSITION_POS_SUCCESS: 'PUT_FORMULE_POSITION_POS_SUCCESS',
  PUT_FORMULE_POSITION_POS_FAILURE: 'PUT_FORMULE_POSITION_POS_FAILURE',

  GET_POS_SHOPS_REQUEST: 'GET_POS_SHOPS_REQUEST',
  GET_POS_SHOPS_SUCCESS: 'GET_POS_SHOPS_SUCCESS',
  GET_POS_SHOPS_FAILURE: 'GET_POS_SHOPS_FAILURE',

  PATCH_POS_SHOP_REQUEST: 'PATCH_POS_SHOP_REQUEST',
  PATCH_POS_SHOP_SUCCESS: 'PATCH_POS_SHOP_SUCCESS',
  PATCH_POS_SHOP_FAILURE: 'PATCH_POS_SHOP_FAILURE',

  OPEN_POS_SHOP_REQUEST: 'OPEN_POS_SHOP_REQUEST',
  OPEN_POS_SHOP_SUCCESS: 'OPEN_POS_SHOP_SUCCESS',
  OPEN_POS_SHOP_FAILURE: 'OPEN_POS_SHOP_FAILURE',

  CLOSE_POS_SHOP_REQUEST: 'CLOSE_POS_SHOP_REQUEST',
  CLOSE_POS_SHOP_SUCCESS: 'CLOSE_POS_SHOP_SUCCESS',
  CLOSE_POS_SHOP_FAILURE: 'CLOSE_POS_SHOP_FAILURE',

  RESET_POS: 'RESET_POS',
};
