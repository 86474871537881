import UserSelfProjectModel from '../Models/UserSelfProjectModel';
import ProjectModel from '../Models/ProjectModel';

export const getCurrentProject = (
  projects: UserSelfProjectModel[]
): ProjectModel => {
  const localProjectId = localStorage.getItem('project_id');
  if (localProjectId) {
    if (existIdInList(projects, localProjectId)) {
      return getProjectWithId(projects, localProjectId);
    } else {
      return getFirstProject(projects[0]);
    }
  } else {
    return getFirstProject(projects[0]);
  }
};

const getFirstProject = (project: UserSelfProjectModel) => {
  localStorage.setItem('project_id', project.userProject.project.id.toString());
  return project.userProject.project;
};

const getProjectWithId = (projects: UserSelfProjectModel[], id: string) => {
  const currentProject = projects.find(
    (project) => project.userProject.project.id === parseInt(id)
  );

  if (currentProject) {
    localStorage.setItem(
      'project_id',
      currentProject.userProject.project.id.toString()
    );
    return currentProject.userProject.project;
  } else {
    return getFirstProject(projects[0]);
  }
};

const existIdInList = (projects: UserSelfProjectModel[], id: string) => {
  return projects.some(
    (project: UserSelfProjectModel) =>
      project.userProject.project.id === parseInt(id)
  );
};
