import { ticketConstants } from '../constants';
import TicketModel from '../../Models/TicketModel';
import ErrorModel from '../../Models/ErrorModel';

const initialState: any = {
  currentTicket: null,
  ticketsList: null,
  updatedTicket: null,
  newTicket: null,
  ticketTranslations: null,
  ticketCategories: null,
  updatedTicketTranslations: null,
  updatedTicketCategories: null,
  deletedTicket: null,
};

let listTickets: TicketModel[] = [];

const tickets = (state = initialState, action: any) => {
  switch (action.type) {
    case ticketConstants.TICKETS_GET_BY_PROJECT_REQUEST: {
      return {
        ...state,
        ticketsList: { tickets: listTickets, error: false, loading: true },
      };
    }

    case ticketConstants.TICKETS_GET_BY_PROJECT_SUCCESS: {
      listTickets = action.allTickets.data.map(
        (ticket: any) => new TicketModel(ticket)
      );

      return {
        ...state,
        ticketsList: {
          tickets: listTickets,
          meta: action.allTickets.meta,
          error: false,
          loading: false,
        },
      };
    }

    case ticketConstants.TICKETS_GET_BY_PROJECT_FAILURE: {
      return {
        ...state,
        ticketsList: { tickets: null, error: true, loading: false },
      };
    }

    case ticketConstants.GET_TICKET_REQUEST: {
      return {
        ...state,
        currentTicket: { details: null, error: false, loading: true },
      };
    }

    case ticketConstants.GET_TICKET_SUCCESS: {
      return {
        ...state,
        currentTicket: {
          details: new TicketModel(action.ticket.data),
          error: false,
          loading: false,
        },
      };
    }

    case ticketConstants.GET_TICKET_FAILURE: {
      return {
        ...state,
        currentTicket: { details: null, error: true, loading: false },
      };
    }

    case ticketConstants.PUT_TICKET_REQUEST: {
      return {
        ...state,
        updatedTicket: { ticket: null, error: false, loading: true },
      };
    }

    case ticketConstants.PUT_TICKET_SUCCESS: {
      return {
        ...state,
        updatedTicket: {
          ticket: action.updatedTicket.data,
          error: false,
          loading: false,
        },
      };
    }

    case ticketConstants.PUT_TICKET_FAILURE: {
      return {
        ...state,
        updatedTicket: { ticket: null, error: true, loading: false },
      };
    }

    case ticketConstants.POST_TICKET_REQUEST: {
      return {
        ...state,
        newTicket: { ticket: null, error: false, loading: true },
      };
    }

    case ticketConstants.POST_TICKET_SUCCESS: {
      return {
        ...state,
        newTicket: {
          ticket: action.newTicket.data,
          error: false,
          loading: false,
        },
      };
    }

    case ticketConstants.POST_TICKET_FAILURE: {
      return {
        ...state,
        newTicket: { ticket: null, error: true, loading: false },
      };
    }

    case ticketConstants.PUT_TICKET_TRANSLATIONS_REQUEST: {
      return {
        ...state,
        updatedTicketTranslations: {
          ticketTranslations: null,
          error: false,
          loading: true,
        },
      };
    }

    case ticketConstants.PUT_TICKET_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        updatedTicketTranslations: {
          ticketTranslations: action.updatedTicketTranslations.data,
          error: false,
          loading: false,
        },
      };
    }

    case ticketConstants.PUT_TICKET_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        updatedTicketTranslations: {
          ticketTranslations: null,
          error: true,
          loading: false,
        },
      };
    }

    case ticketConstants.PUT_TICKET_CATEGORIES_REQUEST: {
      return {
        ...state,
        updatedTicketCategories: {
          ticketCategories: null,
          error: false,
          loading: true,
        },
      };
    }

    case ticketConstants.PUT_TICKET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        updatedTicketCategories: {
          ticketCategories: action.updatedTicketCategories.data,
          error: false,
          loading: false,
        },
      };
    }

    case ticketConstants.PUT_TICKET_CATEGORIES_FAILURE: {
      return {
        ...state,
        updatedTicketCategories: {
          ticketCategories: null,
          error: true,
          loading: false,
        },
      };
    }

    case ticketConstants.DELETE_TICKET_REQUEST: {
      return {
        ...state,
        deletedTicket: { ticket: null, error: false, loading: true },
      };
    }

    case ticketConstants.DELETE_TICKET_SUCCESS: {
      return {
        ...state,
        deletedTicket: { ticket: true, error: false, loading: false },
      };
    }

    case ticketConstants.DELETE_TICKET_FAILURE: {
      return {
        ...state,
        deletedTicket: {
          ticket: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case ticketConstants.CLEAR_TICKET: {
      return {
        ...state,
        currentTicket: null,
        updatedTicket: null,
        newTicket: null,
        ticketTranslations: null,
        ticketCategories: null,
        updatedTicketTranslations: null,
        updatedTicketCategories: null,
        deletedTicket: null,
      };
    }

    default:
      return state;
  }
};

export default tickets;
