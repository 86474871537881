export const formsConstants = {
  FORMS_GET_BY_PROJECT_REQUEST: 'FORMS_GET_BY_PROJECT_REQUEST',
  FORMS_GET_BY_PROJECT_SUCCESS: 'FORMS_GET_BY_PROJECT_SUCCESS',
  FORMS_GET_BY_PROJECT_FAILURE: 'FORMS_GET_BY_PROJECT_FAILURE',

  FORM_GET_BY_ID_REQUEST: 'FORM_GET_BY_ID_REQUEST',
  FORM_GET_BY_ID_SUCCESS: 'FORM_GET_BY_ID_SUCCESS',
  FORM_GET_BY_ID_FAILURE: 'FORM_GET_BY_ID_FAILURE',

  FORM_POST_REQUEST: 'FORM_POST_REQUEST',
  FORM_POST_SUCCESS: 'FORM_POST_SUCCESS',
  FORM_POST_FAILURE: 'FORM_POST_FAILURE',

  FORM_PUT_REQUEST: 'FORM_PUT_REQUEST',
  FORM_PUT_SUCCESS: 'FORM_PUT_SUCCESS',
  FORM_PUT_FAILURE: 'FORM_PUT_FAILURE',

  FORM_DELETE_REQUEST: 'FORM_DELETE_REQUEST',
  FORM_DELETE_SUCCESS: 'FORM_DELETE_SUCCESS',
  FORM_DELETE_FAILURE: 'FORM_DELETE_FAILURE',

  QUESTIONS_GET_BY_FORM_REQUEST: 'QUESTIONS_GET_BY_FORM_REQUEST',
  QUESTIONS_GET_BY_FORM_SUCCESS: 'QUESTIONS_GET_BY_FORM_SUCCESS',
  QUESTIONS_GET_BY_FORM_FAILURE: 'QUESTIONS_GET_BY_FORM_FAILURE',

  QUESTIONS_GET_TYPES_REQUEST: 'QUESTIONS_GET_TYPES_REQUEST',
  QUESTIONS_GET_TYPES_SUCCESS: 'QUESTIONS_GET_TYPES_SUCCESS',
  QUESTIONS_GET_TYPES_FAILURE: 'QUESTIONS_GET_TYPES_FAILURE',

  FORM_POST_QUESTIONS_REQUEST: 'FORM_POST_QUESTIONS_REQUEST',
  FORM_POST_QUESTIONS_SUCCESS: 'FORM_POST_QUESTIONS_SUCCESS',
  FORM_POST_QUESTIONS_FAILURE: 'FORM_POST_QUESTIONS_FAILURE',

  FORM_DELETE_QUESTION_REQUEST: 'FORM_DELETE_QUESTION_REQUEST',
  FORM_DELETE_QUESTION_SUCCESS: 'FORM_DELETE_QUESTION_SUCCESS',
  FORM_DELETE_QUESTION_FAILURE: 'FORM_DELETE_QUESTION_FAILURE',

  QUESTION_GET_BY_ID_REQUEST: 'QUESTION_GET_BY_ID_REQUEST',
  QUESTION_GET_BY_ID_SUCCESS: 'QUESTION_GET_BY_ID_SUCCESS',
  QUESTION_GET_BY_ID_FAILURE: 'QUESTION_GET_BY_ID_FAILURE',

  QUESTION_PUT_REQUEST: 'QUESTION_PUT_REQUEST',
  QUESTION_PUT_SUCCESS: 'QUESTION_PUT_SUCCESS',
  QUESTION_PUT_FAILURE: 'QUESTION_PUT_FAILURE',

  QUESTION_PUT_TOGGLE_REQUIRED_REQUEST: 'QUESTION_PUT_TOGGLE_REQUIRED_REQUEST',
  QUESTION_PUT_TOGGLE_REQUIRED_SUCCESS: 'QUESTION_PUT_TOGGLE_REQUIRED_SUCCESS',
  QUESTION_PUT_TOGGLE_REQUIRED_FAILURE: 'QUESTION_PUT_TOGGLE_REQUIRED_FAILURE',

  FORM_PUT_QUESTIONS_ORDER_REQUEST: 'FORM_PUT_QUESTIONS_ORDER_REQUEST',
  FORM_PUT_QUESTIONS_ORDER_SUCCESS: 'FORM_PUT_QUESTIONS_ORDER_SUCCESS',
  FORM_PUT_QUESTIONS_ORDER_FAILURE: 'FORM_PUT_QUESTIONS_ORDER_FAILURE',

  CLEAR_FORM_REQUEST: 'CLEAR_FORM_REQUEST',
};
