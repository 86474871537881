import { uploadsConstants } from '../constants';

export const postUploadPictureRequest = (picture: any) => ({
  type: uploadsConstants.UPLOAD_POST_PICTURE_REQUEST,
  picture,
});

export const postUploadPictureSuccess = (newUploadPicture: any) => ({
  type: uploadsConstants.UPLOAD_POST_PICTURE_SUCCESS,
  newUploadPicture,
});

export const postUploadPictureFailure = (error: any) => ({
  type: uploadsConstants.UPLOAD_POST_PICTURE_FAILURE,
  error,
});
