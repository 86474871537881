import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './DragAndDrop.css';
export const DragAndDrop = (props) => {
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                props.onChange({
                    name: e.target.name,
                    src: reader.result,
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
        else {
            props.onChange(null);
        }
    };
    return (_jsxs("div", { className: 'container p-0', children: [_jsx("label", { className: 'labelName', children: props.labelText }), _jsx("input", { name: props.name, className: 'inputFile', type: "file", accept: "image/*", onChange: onSelectFile })] }));
};
