import React from 'react';
import { capitalizeFirstLetter } from '../../services/capitalizeFirstLetter';
import { languageDataInterface } from '../../lngProvider';

interface LanguageItemInterface {
  language: languageDataInterface;
}

const LanguageItem = (props: LanguageItemInterface) => {
  const { icon, locale } = props.language;
  let languageNames = new Intl.DisplayNames([locale], {
    type: 'language',
  });

  return (
    <div className="d-flex align-items-center">
      <i className={`flag flag-24 flag-${icon}`} />
      <h4 className="mb-0 ml-2">
        {capitalizeFirstLetter(languageNames.of(locale))}
      </h4>
    </div>
  );
};

export default LanguageItem;
