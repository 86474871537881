import FrMessages from './locales/fr/fr.json';
import NlMessages from './locales/nl/nl.json';
import EnMessages from './locales/en/en.json';

export interface languageDataInterface {
  languageId: string;
  locale: string;
  icon: string;
  translations: any;
}

const AppLanguages: languageDataInterface[] = [
  {
    languageId: 'French',
    locale: 'fr',
    icon: 'fr',
    translations: FrMessages,
  },
  {
    languageId: 'Nederland',
    locale: 'nl',
    icon: 'nl',
    translations: NlMessages,
  },
  {
    languageId: 'English',
    locale: 'en',
    icon: 'us',
    translations: EnMessages,
  },
];

export default AppLanguages;
