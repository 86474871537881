import { productTypesConstants } from '../constants';
import { createProductTypeFromApi } from '../../Models/ProductTypeModel';

const initialState: any = {
  productTypesList: null,
};

let listProductTypes: any[] = [];

const productTypes = (state = initialState, action: any) => {
  switch (action.type) {
    case productTypesConstants.PRODUCT_TYPES_GET_REQUEST: {
      return {
        ...state,
        productTypesList: {
          productTypes: listProductTypes,
          error: false,
          loading: true,
        },
      };
    }

    case productTypesConstants.PRODUCT_TYPES_GET_SUCCESS: {
      listProductTypes = action.productTypes.data.map((type: any) =>
        createProductTypeFromApi(type)
      );
      return {
        ...state,
        productTypesList: {
          productTypes: listProductTypes,
          error: false,
          loading: false,
        },
      };
    }

    case productTypesConstants.PRODUCT_TYPES_GET_FAILURE: {
      return {
        ...state,
        productTypesList: { productTypes: null, error: true, loading: false },
      };
    }

    default:
      return state;
  }
};

export default productTypes;
