export default class CurrencyModel {
  id: number;
  isoCode: string;
  symbol: string;
  symbolNative: string;
  decimals: number;

  constructor(data: any) {
    this.id = data.id;
    this.isoCode = data.isoCode;
    this.symbol = data.symbol;
    this.symbolNative = data.symbolNative;
    this.decimals = data.decimals;
  }
}
