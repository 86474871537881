import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isIOS, isMobile } from 'react-device-detect';
import {
  postErrorInSlackRequest,
  getUserSelfDetailsRequest,
  getUserProjectByProjectRequest,
  getProjectDetailsRequest,
  getUserSelfProjectsRequest,
  getOrganizationStatusRequest,
} from '../Redux/actions';
import { checkReduxResponse } from '../services/checkReduxResponse';

import { ErrorFallback, Loading } from '@emisys/audience-sdk-ui-react';
import Sidebar from '../components/SideNav';
import Header from '../components/Header';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';

import Error404 from '../components/Error404/Error404';
import { pagesConstant } from '../Constants/Constant';
import { currentProject, setCurrentProject } from '../Global/currentProject';
import { getAuthTokensStorage, getTokenStorage } from '../services/handleToken';
import { getCurrentProject } from '../services/getCurrentProject';
import Translator from '../services/translator';
import initTranslations from '../services/initTranslations';
import RequiredConfig from '../components/RequiredConfig/RequiredConfig';
import { storeType } from '../index';
import ArchivedEventBanner from '../components/ArchivedEventBanner/ArchivedEventBanner';
import OrganizationStatusModel from '../Models/OrganizationStatusModel';

const Home = lazy(() => import('./Home'));
const Account = lazy(() => import('./Account'));
const Ticketing = lazy(() => import('./Dashboard/Ticketing'));
const DashboardEvent = lazy(() => import('./Dashboard/Event'));
const Forms = lazy(() => import('./Forms'));
const FormPreview = lazy(() => import('./Forms/FormPreview/FormPreview'));
const Categories = lazy(() => import('./Categories'));
const Attendees = lazy(() => import('./Attendees'));
const ShowAttendee = lazy(() => import('./Attendees/Details/ShowAttendee'));
const Orders = lazy(() => import('./Orders'));
const ShowOrder = lazy(() => import('./Orders/Details/ShowOrder'));
const Barcodes = lazy(() => import('./Barcodes'));
const BrandingTicket = lazy(
  () => import('./Branding/TicketVisual/BrandingTicket')
);
const BrandingVisual = lazy(() => import('./Branding/Visual/BrandingVisual'));
const BrandingContent = lazy(
  () => import('./Branding/Content/BrandingContent')
);
const CurrentEvent = lazy(() => import('./Events/Current/CurrentEvent'));
const EventsList = lazy(() => import('./Events/List/EventsList'));
const AutoImport = lazy(() => import('./Imports/Auto/AutoImport'));
const ManualImport = lazy(() => import('./Imports/Manual/ManualImport'));
const PaymentMethods = lazy(() => import('./PaymentMethods/PaymentMethods'));
const PromotionCode = lazy(
  () => import('./Marketing/PromotionCode/PromotionCode')
);
const Billing = lazy(() => import('./Billing/Billing'));
const Organization = lazy(() => import('./Organization/Organization'));
const Administrators = lazy(() => import('./Administrators/Administrators'));
const PaymentPage = lazy(() => import('./Payment/PaymentPage'));
const PhysicalSales = lazy(() => import('./Sales/Physical/PhysicalSales'));
const OnlineSales = lazy(() => import('./Sales/Online/OnlineSales'));
const SalesIntegration = lazy(
  () => import('./Sales/Integration/SalesIntegration')
);
const MarketingIntegration = lazy(
  () => import('./Marketing/Integration/MarketingIntegration')
);
const FormCreation = lazy(() => import('./Forms/FormCreation/FormCreation'));
const FormEdition = lazy(() => import('./Forms/FormEdition/FormEdition'));
const CreateEvent = lazy(() => import('./Events/Create/CreateEvent'));
const TicketsStepper = lazy(
  () => import('./Tickets/TicketsStepper/TicketsStepper')
);
const Tickets = lazy(() => import('./Tickets'));
const SignIn = lazy(() => import('./SignIn/SignIn'));
const SetupPassword = lazy(() => import('./SetupPassword/SetupPassword'));

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [projectId, setProjectId] = useState<string | null>(null);
  const [isProjectActive, setIsProjectActive] = useState(false);
  const [showMissingBanner, setShowMissingBanner] = useState(false);
  const [showTicketing, setShowTicketing] = useState(false);
  const [navCollapsed, setNavCollapsed] = useState(false);
  const [currentLocale, setCurrentLocale] = useState('');
  const [setUpMail, setSetUpMail] = useState<string | null>(null);
  const [setUpToken, setSetUpToken] = useState<string | null>(null);
  const [organizationStatusState, setOrganizationStatusState] =
    useState<OrganizationStatusModel | null>(null);
  const userSelfDetails = useSelector(
    (state: storeType) => state.user.userSelfDetails
  );
  const userSelfProjects = useSelector(
    (state: storeType) => state.user.userSelfProjects
  );
  const projectDetails = useSelector(
    (state: storeType) => state.project.projectDetails
  );

  const userProjectByProject = useSelector(
    (state: storeType) => state.user.userProjectByProject
  );

  const organizationStatus = useSelector(
    (state: storeType) => state.organization.organizationStatus
  );

  const organizationUpdated = useSelector(
    (state: storeType) => state.organization.updated
  );

  const onToggleCollapsedNav = () => {
    setNavCollapsed(!navCollapsed);
  };

  const getUserSelfProjects = useCallback(() => {
    dispatch(getUserSelfProjectsRequest());
  }, [dispatch]);

  const getUserSelfDetails = useCallback(
    (projectId: number) => {
      dispatch(getUserSelfDetailsRequest(projectId));
    },
    [dispatch]
  );

  const getProjectDetails = useCallback(
    (projectId: number) => {
      dispatch(getProjectDetailsRequest(projectId));
    },
    [dispatch]
  );

  const getUserProjectByProject = useCallback(
    (userId: number, projectId: number) => {
      dispatch(getUserProjectByProjectRequest(projectId, userId));
    },
    [dispatch]
  );

  const handlePostErrorInSlack = useCallback(
    (params: any) => {
      dispatch(postErrorInSlackRequest(params));
    },
    [dispatch]
  );

  useEffect(() => {
    const locationHref = new URL(window.location.href);
    const project_id = locationHref.searchParams.get('p');
    const email = locationHref.searchParams.get('email');
    const token = locationHref.searchParams.get('token');
    setSetUpMail(email);
    setSetUpToken(token);
    setProjectId(project_id);
    if (project_id) {
      localStorage.setItem('project_id', project_id);
    }
  }, []);

  useEffect(() => {
    if (getTokenStorage()) {
      if (userSelfProjects) {
        if (checkReduxResponse(userSelfProjects, 'projects')) {
          if (userSelfProjects.projects.length > 0) {
            const currentProject = getCurrentProject(userSelfProjects.projects);
            setIsProjectActive(currentProject.isActive);
            if (!userSelfDetails) {
              getUserSelfDetails(currentProject.id);
            }
            if (!projectDetails) {
              getProjectDetails(currentProject.id);
            }
          }
        }
      } else {
        getUserSelfProjects();
      }
    }
  }, [
    userSelfProjects,
    getUserSelfDetails,
    getProjectDetails,
    userSelfDetails,
    projectDetails,
    getUserSelfProjects,
  ]);

  useEffect(() => {
    if (checkReduxResponse(projectDetails, 'detail')) {
      setCurrentProject(projectDetails.detail);
      const localFromStorage = localStorage.getItem('locale');
      if (localFromStorage) {
        initTranslations(localFromStorage);
      } else {
        initTranslations(projectDetails.detail.defaultLanguage);
      }
      if (currentProject.organizationId) {
        dispatch(getOrganizationStatusRequest(currentProject.organizationId));
      }
      setShowTicketing(true);
    }
  }, [dispatch, projectDetails]);

  useEffect(() => {
    if (organizationUpdated) {
      if (checkReduxResponse(organizationUpdated, 'organization')) {
        setShowMissingBanner(false);
        if (currentProject.organizationId) {
          dispatch(getOrganizationStatusRequest(currentProject.organizationId));
        }
      }
    }
  }, [dispatch, organizationUpdated]);

  useEffect(() => {
    if (userSelfDetails && currentProject.id) {
      if (checkReduxResponse(userSelfDetails, 'details')) {
        if (userSelfProjects.projects.length > 0) {
          if (!userProjectByProject) {
            getUserProjectByProject(
              userSelfDetails.details.id,
              currentProject.id
            );
          }

          if (location.pathname === pagesConstant.signIn) {
            navigate(pagesConstant.home);
          }
        }
      }
    }
  }, [
    navigate,
    getUserProjectByProject,
    userSelfDetails,
    userSelfProjects?.projects?.length,
    location.pathname,
    userProjectByProject,
  ]);

  useEffect(() => {
    if (checkReduxResponse(organizationStatus, 'status')) {
      setOrganizationStatusState(organizationStatus.status);
      setShowMissingBanner(organizationStatus.status.showMissingBanner);
    }
  }, [organizationStatus]);

  const postErrorInSlack = (error: any) => {
    if (process.env.REACT_APP_ENV === 'prod') {
      handlePostErrorInSlack({
        application: currentProject.name,
        fields: {
          project: currentProject.id,
          user: {
            email: userSelfDetails.details?.email,
            username: userSelfDetails.details?.fullName,
          },
          link: window.location.href,
          device: window.navigator.userAgent,
        },
        error: error.message,
        callstack: error.stack,
      });
    }
  };

  // set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height');
  } else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height');
  }

  return (
    <div className={'app-main'}>
      <ErrorBoundary
        FallbackComponent={(error) => (
          <ErrorFallback
            error={error.error}
            title={Translator.trans('appModule.message.error')}
            back={Translator.trans('extraPages.goHome')}
          />
        )}
        onError={(error) => postErrorInSlack(error)}
      >
        <Suspense fallback={<Loading />}>
          {getAuthTokensStorage() && (!setUpMail || !setUpToken) ? (
            <div className="app-container isLoggedIn">
              <Sidebar
                onToggleCollapsedNav={onToggleCollapsedNav}
                navCollapsed={navCollapsed}
              />

              {showTicketing ? (
                <div className="app-main-container">
                  <div className="app-header">
                    <Header
                      currentLocale={currentLocale}
                      setCurrentLocale={setCurrentLocale}
                      onToggleCollapsedNav={onToggleCollapsedNav}
                    />
                  </div>

                  <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                      {showMissingBanner && organizationStatusState && (
                        <RequiredConfig
                          organizationStatus={organizationStatusState}
                        />
                      )}
                      {!isProjectActive && <ArchivedEventBanner />}
                      {/* prettier-ignore */}
                      <Routes>
                        <Route path={pagesConstant.home} element={<Home />} />
                        <Route path={pagesConstant.profile} element={<Account />} />
                        <Route path={pagesConstant.dashboard.ticketing} element={<Ticketing />} />
                        <Route path={pagesConstant.dashboard.event} element={<DashboardEvent />} />
                        <Route path={pagesConstant.tickets.home} element={<Tickets />} />
                        <Route path={pagesConstant.forms.home} element={<Forms />} />
                        <Route path={pagesConstant.forms.preview + ':idForm'} element={<FormPreview />} />
                        <Route path={pagesConstant.categories} element={<Categories />} />
                        <Route path={pagesConstant.attendees.home} element={<Attendees />} />
                        <Route path={pagesConstant.attendees.detail + ':idAttendee'} element={<ShowAttendee />} />
                        <Route path={pagesConstant.orders.home} element={<Orders />} />
                        <Route path={pagesConstant.orders.detail + ':idOrder'} element={<ShowOrder />} />
                        <Route path={pagesConstant.barcodes} element={<Barcodes />} />
                        <Route path={pagesConstant.branding.ticket} element={<BrandingTicket />} />
                        <Route path={pagesConstant.branding.visual} element={<BrandingVisual />} />
                        <Route path={pagesConstant.branding.content} element={<BrandingContent />} />
                        <Route path={pagesConstant.events.current} element={<CurrentEvent />} />
                        <Route path={pagesConstant.events.list} element={<EventsList />} />
                        <Route path={pagesConstant.import.auto} element={<AutoImport />} />
                        <Route path={pagesConstant.import.manual} element={<ManualImport />} />
                        <Route path={pagesConstant.paymentMethod} element={<PaymentMethods />} />
                        <Route path={pagesConstant.marketing.promo} element={<PromotionCode />} />
                        <Route path={pagesConstant.billing} element={<Billing />} />
                        <Route path={pagesConstant.organization} element={<Organization />} />
                        <Route path={pagesConstant.administrators} element={<Administrators />} />
                        <Route path={pagesConstant.payment} element={<PaymentPage />} />
                        <Route path={pagesConstant.sales.physical} element={<PhysicalSales />} />
                        <Route path={pagesConstant.sales.online} element={<OnlineSales />} />
                        <Route path={pagesConstant.sales.integration} element={<SalesIntegration />} />
                        <Route path={pagesConstant.marketing.integration} element={<MarketingIntegration />} />
                        {isProjectActive &&
                          <>
                            <Route path={pagesConstant.forms.add} element={<FormCreation />} />
                            <Route path={pagesConstant.events.add} element={<CreateEvent />} />
                            <Route path={pagesConstant.tickets.add} element={<TicketsStepper />} />
                            <Route path={pagesConstant.tickets.edit + ':idTicket'} element={<TicketsStepper />} />
                            <Route path={pagesConstant.forms.edit + ':idForm'} element={<FormEdition />} />
                          </>
                        }
                        <Route path="/*" element={<Navigate to={pagesConstant.home} />} />
                        <Route path='*' element={<Error404 />} />
                      </Routes>
                    </div>
                    <ToastContainer />
                  </main>
                </div>
              ) : (
                <Loading />
              )}
            </div>
          ) : (
            <Routes>
              <Route path={pagesConstant.signIn} element={<SignIn />} />
              <Route
                path={pagesConstant.setupPassword}
                element={
                  <SetupPassword
                    email={setUpMail}
                    token={setUpToken}
                    projectId={projectId}
                    clear={() => {
                      setSetUpMail(null);
                      setSetUpToken(null);
                    }}
                  />
                }
              />
              <Route
                path="/*"
                element={<Navigate to={pagesConstant.signIn} />}
              />
            </Routes>
          )}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default App;
