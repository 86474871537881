export const ticketConstants = {
  GET_TICKET_REQUEST: 'GET_TICKET_REQUEST',
  GET_TICKET_SUCCESS: 'GET_TICKET_SUCCESS',
  GET_TICKET_FAILURE: 'GET_TICKET_FAILURE',

  TICKETS_GET_BY_PROJECT_REQUEST: 'TICKETS_GET_BY_PROJECT_REQUEST',
  TICKETS_GET_BY_PROJECT_SUCCESS: 'TICKETS_GET_BY_PROJECT_SUCCESS',
  TICKETS_GET_BY_PROJECT_FAILURE: 'TICKETS_GET_BY_PROJECT_FAILURE',

  PUT_TICKET_REQUEST: 'PUT_TICKET_REQUEST',
  PUT_TICKET_SUCCESS: 'PUT_TICKET_SUCCESS',
  PUT_TICKET_FAILURE: 'PUT_TICKET_FAILURE',

  POST_TICKET_REQUEST: 'POST_TICKET_REQUEST',
  POST_TICKET_SUCCESS: 'POST_TICKET_SUCCESS',
  POST_TICKET_FAILURE: 'POST_TICKET_FAILURE',

  PUT_TICKET_TRANSLATIONS_REQUEST: 'PUT_TICKET_TRANSLATIONS_REQUEST',
  PUT_TICKET_TRANSLATIONS_SUCCESS: 'PUT_TICKET_TRANSLATIONS_SUCCESS',
  PUT_TICKET_TRANSLATIONS_FAILURE: 'PUT_TICKET_TRANSLATIONS_FAILURE',

  PUT_TICKET_CATEGORIES_REQUEST: 'PUT_TICKET_CATEGORIES_REQUEST',
  PUT_TICKET_CATEGORIES_SUCCESS: 'PUT_TICKET_CATEGORIES_SUCCESS',
  PUT_TICKET_CATEGORIES_FAILURE: 'PUT_TICKET_CATEGORIES_FAILURE',

  DELETE_TICKET_REQUEST: 'DELETE_TICKET_REQUEST',
  DELETE_TICKET_SUCCESS: 'DELETE_TICKET_SUCCESS',
  DELETE_TICKET_FAILURE: 'DELETE_TICKET_FAILURE',

  CLEAR_TICKET: 'CLEAR_TICKET',
};
