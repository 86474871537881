import { addressesConstants } from '../constants';
import AddressModel from '../../Models/AddressModel';
import ErrorModel from '../../Models/ErrorModel';

const initialState: any = {
  projectAddress: null,
  organizationAddress: null,
};

const addresses = (state = initialState, action: any) => {
  switch (action.type) {
    case addressesConstants.GET_PROJECT_ADDRESS_REQUEST: {
      return {
        ...state,
        projectAddress: {
          address: null,
          error: false,
          loading: true,
        },
      };
    }

    case addressesConstants.GET_PROJECT_ADDRESS_SUCCESS: {
      return {
        ...state,
        projectAddress: {
          address: new AddressModel(action.address.data),
          error: false,
          loading: false,
        },
      };
    }

    case addressesConstants.GET_PROJECT_ADDRESS_FAILURE: {
      return {
        ...state,
        projectAddress: {
          address: null,
          error: true,
          loading: false,
        },
      };
    }

    case addressesConstants.PUT_PROJECT_ADDRESS_REQUEST: {
      return {
        ...state,
        projectAddress: {
          address: null,
          error: false,
          loading: true,
        },
      };
    }

    case addressesConstants.PUT_PROJECT_ADDRESS_SUCCESS: {
      return {
        ...state,
        projectAddress: {
          address: new AddressModel(action.updatedAddress.data),
          error: false,
          loading: false,
        },
      };
    }

    case addressesConstants.PUT_PROJECT_ADDRESS_FAILURE: {
      return {
        ...state,
        projectAddress: {
          address: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case addressesConstants.GET_ORGANIZATION_ADDRESS_REQUEST: {
      return {
        ...state,
        organizationAddress: {
          address: null,
          error: false,
          loading: true,
        },
      };
    }

    case addressesConstants.GET_ORGANIZATION_ADDRESS_SUCCESS: {
      return {
        ...state,
        organizationAddress: {
          address: new AddressModel(action.address.data),
          error: false,
          loading: false,
        },
      };
    }

    case addressesConstants.GET_ORGANIZATION_ADDRESS_FAILURE: {
      return {
        ...state,
        organizationAddress: {
          address: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case addressesConstants.PUT_ORGANIZATION_ADDRESS_REQUEST: {
      return {
        ...state,
        organizationAddress: {
          address: null,
          error: false,
          loading: true,
        },
      };
    }

    case addressesConstants.PUT_ORGANIZATION_ADDRESS_SUCCESS: {
      return {
        ...state,
        organizationAddress: {
          address: new AddressModel(action.updatedAddress.data),
          error: false,
          loading: false,
        },
      };
    }

    case addressesConstants.PUT_ORGANIZATION_ADDRESS_FAILURE: {
      return {
        ...state,
        organizationAddress: {
          address: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default addresses;
