import { mailsConstants } from '../constants';

export const getMailRequest = (idMail: number) => ({
  type: mailsConstants.MAIL_GET_REQUEST,
  idMail,
});

export const getMailSuccess = (mail: any) => ({
  type: mailsConstants.MAIL_GET_SUCCESS,
  mail,
});

export const getMailFailure = (error: any) => ({
  type: mailsConstants.MAIL_GET_FAILURE,
  error,
});

export const clearMailDetail = () => ({
  type: mailsConstants.CLEAR_DETAIL_MAIL,
});
