import { uploadsConstants } from '../constants';

const initialState: any = {
  newUploadPicture: null,
};

const uploads = (state = initialState, action: any) => {
  switch (action.type) {
    case uploadsConstants.UPLOAD_POST_PICTURE_REQUEST: {
      return {
        ...state,
        newUploadPicture: { uploadPicture: null, error: false, loading: true },
      };
    }

    case uploadsConstants.UPLOAD_POST_PICTURE_SUCCESS: {
      return {
        ...state,
        newUploadPicture: {
          uploadPicture: action.newUploadPicture.data,
          error: false,
          loading: false,
        },
      };
    }

    case uploadsConstants.UPLOAD_POST_PICTURE_FAILURE: {
      return {
        ...state,
        newUploadPicture: { uploadPicture: null, error: true, loading: false },
      };
    }

    default:
      return state;
  }
};

export default uploads;
