export default class RoleModel {
  id: number;
  role: string;
  description: string;

  constructor(data: any) {
    this.id = data.id;
    this.role = data.role;
    this.description = data.description;
  }
}
