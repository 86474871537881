import { formsConstants } from '../constants';

export const getFormsByProjectRequest = (idProject: number) => ({
  type: formsConstants.FORMS_GET_BY_PROJECT_REQUEST,
  idProject,
});

export const getFormsByProjectSuccess = (forms: any) => ({
  type: formsConstants.FORMS_GET_BY_PROJECT_SUCCESS,
  forms,
});

export const getFormsByProjectFailure = (error: any) => ({
  type: formsConstants.FORMS_GET_BY_PROJECT_FAILURE,
  error,
});

export const getFormByIdRequest = (idForm: number) => ({
  type: formsConstants.FORM_GET_BY_ID_REQUEST,
  idForm,
});

export const getFormByIdSuccess = (form: any) => ({
  type: formsConstants.FORM_GET_BY_ID_SUCCESS,
  form,
});

export const getFormByIdFailure = (error: any) => ({
  type: formsConstants.FORM_GET_BY_ID_FAILURE,
  error,
});

export const postFormRequest = (idProject: number, form: any) => ({
  type: formsConstants.FORM_POST_REQUEST,
  form,
  idProject,
});

export const postFormSuccess = (newForm: any) => ({
  type: formsConstants.FORM_POST_SUCCESS,
  newForm,
});

export const postFormFailure = (error: any) => ({
  type: formsConstants.FORM_POST_FAILURE,
  error,
});

export const putFormRequest = (idForm: number, form: any) => ({
  type: formsConstants.FORM_PUT_REQUEST,
  idForm,
  form,
});

export const putFormSuccess = (updatedForm: any) => ({
  type: formsConstants.FORM_PUT_SUCCESS,
  updatedForm,
});

export const putFormFailure = (error: any) => ({
  type: formsConstants.FORM_PUT_FAILURE,
  error,
});

export const deleteFormRequest = (idForm: number) => ({
  type: formsConstants.FORM_DELETE_REQUEST,
  idForm,
});

export const deleteFormSuccess = () => ({
  type: formsConstants.FORM_DELETE_SUCCESS,
});

export const deleteFormFailure = (error: any) => ({
  type: formsConstants.FORM_DELETE_FAILURE,
  error,
});

export const getQuestionsByFormRequest = (idForm: number) => ({
  type: formsConstants.QUESTIONS_GET_BY_FORM_REQUEST,
  idForm,
});

export const getQuestionsByFormSuccess = (questionList: any) => ({
  type: formsConstants.QUESTIONS_GET_BY_FORM_SUCCESS,
  questionList,
});

export const getQuestionsByFormFailure = (error: any) => ({
  type: formsConstants.QUESTIONS_GET_BY_FORM_FAILURE,
  error,
});

export const getQuestionsTypesRequest = () => ({
  type: formsConstants.QUESTIONS_GET_TYPES_REQUEST,
});

export const getQuestionsTypesSuccess = (questionsTypes: any) => ({
  type: formsConstants.QUESTIONS_GET_TYPES_SUCCESS,
  questionsTypes,
});

export const getQuestionsTypesFailure = (error: any) => ({
  type: formsConstants.QUESTIONS_GET_TYPES_FAILURE,
  error,
});

export const postFormQuestionsRequest = (idForm: number, questions: any) => ({
  type: formsConstants.FORM_POST_QUESTIONS_REQUEST,
  idForm,
  questions,
});

export const postFormQuestionsSuccess = (newQuestions: any) => ({
  type: formsConstants.FORM_POST_QUESTIONS_SUCCESS,
  newQuestions,
});

export const postFormQuestionsFailure = (error: any) => ({
  type: formsConstants.FORM_POST_QUESTIONS_FAILURE,
  error,
});

export const deleteFormQuestionRequest = (idQuestion: number) => ({
  type: formsConstants.FORM_DELETE_QUESTION_REQUEST,
  idQuestion,
});

export const deleteFormQuestionSuccess = () => ({
  type: formsConstants.FORM_DELETE_QUESTION_SUCCESS,
});

export const deleteFormQuestionFailure = (error: any) => ({
  type: formsConstants.FORM_DELETE_QUESTION_FAILURE,
  error,
});

export const getQuestionByIdRequest = (idQuestion: number) => ({
  type: formsConstants.QUESTION_GET_BY_ID_REQUEST,
  idQuestion,
});

export const getQuestionByIdSuccess = (question: any) => ({
  type: formsConstants.QUESTION_GET_BY_ID_SUCCESS,
  question,
});

export const getQuestionByIdFailure = (error: any) => ({
  type: formsConstants.QUESTION_GET_BY_ID_FAILURE,
  error,
});

export const putQuestionRequest = (idQuestion: number, question: any) => ({
  type: formsConstants.QUESTION_PUT_REQUEST,
  idQuestion,
  question,
});

export const putQuestionSuccess = (updatedQuestion: any) => ({
  type: formsConstants.QUESTION_PUT_SUCCESS,
  updatedQuestion,
});

export const putQuestionFailure = (error: any) => ({
  type: formsConstants.QUESTION_PUT_FAILURE,
  error,
});

export const putQuestionToggleRequiredRequest = (idQuestion: number) => ({
  type: formsConstants.QUESTION_PUT_TOGGLE_REQUIRED_REQUEST,
  idQuestion,
});

export const putQuestionToggleRequiredSuccess = (
  updatedQuestionToggleRequired: any
) => ({
  type: formsConstants.QUESTION_PUT_TOGGLE_REQUIRED_SUCCESS,
  updatedQuestionToggleRequired,
});

export const putQuestionToggleRequiredFailure = (error: any) => ({
  type: formsConstants.QUESTION_PUT_TOGGLE_REQUIRED_FAILURE,
  error,
});

export const putFormQuestionsOrderRequest = (
  idForm: number,
  questionsOrder: any
) => ({
  type: formsConstants.FORM_PUT_QUESTIONS_ORDER_REQUEST,
  idForm,
  questionsOrder,
});

export const putFormQuestionsOrderSuccess = (newOrder: any) => ({
  type: formsConstants.FORM_PUT_QUESTIONS_ORDER_SUCCESS,
  newOrder,
});

export const putFormQuestionsOrderFailure = (error: any) => ({
  type: formsConstants.FORM_PUT_QUESTIONS_ORDER_FAILURE,
  error,
});

export const clearFormRequest = () => ({
  type: formsConstants.CLEAR_FORM_REQUEST,
});
