import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getAttendeesByProjectFailure,
  getAttendeesByProjectSuccess,
  getAttendeeByIdSuccess,
  getAttendeeByIdFailure,
  getAttendeeNfcTagsSuccess,
  getAttendeeNfcTagsFailure,
  getAttendeeBarcodesSuccess,
  getAttendeeBarcodesFailure,
  getAttendeeBasketsSuccess,
  getAttendeeBasketsFailure,
  getAttendeeNotesSuccess,
  getAttendeeNotesFailure,
  getAttendeeMailsSuccess,
  getAttendeeMailsFailure,
  postAttendeeNotesSuccess,
  postAttendeeNotesFailure,
} from '../actions';
import { attendeesConstants } from '../constants';
import {
  getAttendeesByProjectApi,
  getAttendeeByIdApi,
  getAttendeeNfcTagsApi,
  getAttendeeBarcodesApi,
  getAttendeeBasketsApi,
  getAttendeeNotesApi,
  getAttendeeMailsApi,
  postAttendeeNotesApi,
} from '../api';

export function* fetchGetAttendeesByProject(action: any): any {
  try {
    const attendees = yield call(getAttendeesByProjectApi, {
      idProject: action.idProject,
      args: action.options,
    });
    yield put(getAttendeesByProjectSuccess(attendees));
  } catch (error) {
    yield put(getAttendeesByProjectFailure(error));
  }
}

export function* fetchGetAttendeeById(action: any): any {
  try {
    const attendee = yield call(getAttendeeByIdApi, {
      idAttendee: action.idAttendee,
    });
    yield put(getAttendeeByIdSuccess(attendee));
  } catch (error) {
    yield put(getAttendeeByIdFailure(error));
  }
}

export function* fetchGetAttendeeNfcTags(action: any): any {
  try {
    const nfcTags = yield call(getAttendeeNfcTagsApi, {
      idAttendee: action.idAttendee,
      args: action.options,
    });
    yield put(getAttendeeNfcTagsSuccess(nfcTags));
  } catch (error) {
    yield put(getAttendeeNfcTagsFailure(error));
  }
}

export function* fetchGetAttendeeBarcodes(action: any): any {
  try {
    const barcodes = yield call(getAttendeeBarcodesApi, {
      idAttendee: action.idAttendee,
      args: action.options,
    });
    yield put(getAttendeeBarcodesSuccess(barcodes));
  } catch (error) {
    yield put(getAttendeeBarcodesFailure(error));
  }
}

export function* fetchGetAttendeeBaskets(action: any): any {
  try {
    const baskets = yield call(getAttendeeBasketsApi, {
      idAttendee: action.idAttendee,
      args: action.options,
    });
    yield put(getAttendeeBasketsSuccess(baskets));
  } catch (error) {
    yield put(getAttendeeBasketsFailure(error));
  }
}

export function* fetchGetAttendeeNotes(action: any): any {
  try {
    const notes = yield call(getAttendeeNotesApi, {
      idAttendee: action.idAttendee,
      args: action.options,
    });
    yield put(getAttendeeNotesSuccess(notes));
  } catch (error) {
    yield put(getAttendeeNotesFailure(error));
  }
}

export function* fetchGetAttendeeMails(action: any): any {
  try {
    const mails = yield call(getAttendeeMailsApi, {
      idAttendee: action.idAttendee,
      args: action.options,
    });
    yield put(getAttendeeMailsSuccess(mails));
  } catch (error) {
    yield put(getAttendeeMailsFailure(error));
  }
}

export function* fetchPostAttendeeNotes(action: any): any {
  try {
    const notes = yield call(postAttendeeNotesApi, {
      idAttendee: action.idAttendee,
      note: action.note,
      params: action.params,
    });
    yield put(postAttendeeNotesSuccess(notes));
  } catch (error) {
    yield put(postAttendeeNotesFailure(error));
  }
}

export function* getAttendeesByProject() {
  yield takeLatest(
    attendeesConstants.ATTENDEES_GET_BY_PROJECT_REQUEST,
    fetchGetAttendeesByProject
  );
}

export function* getAttendeeById() {
  yield takeLatest(
    attendeesConstants.ATTENDEE_GET_BY_ID_REQUEST,
    fetchGetAttendeeById
  );
}

export function* getAttendeeNfcTags() {
  yield takeLatest(
    attendeesConstants.ATTENDEE_GET_NFCTAG_REQUEST,
    fetchGetAttendeeNfcTags
  );
}

export function* getAttendeeBarcodes() {
  yield takeLatest(
    attendeesConstants.ATTENDEE_GET_BARCODES_REQUEST,
    fetchGetAttendeeBarcodes
  );
}

export function* getAttendeeBaskets() {
  yield takeLatest(
    attendeesConstants.ATTENDEE_GET_BASKETS_REQUEST,
    fetchGetAttendeeBaskets
  );
}

export function* getAttendeeNotes() {
  yield takeLatest(
    attendeesConstants.ATTENDEE_GET_NOTES_REQUEST,
    fetchGetAttendeeNotes
  );
}

export function* getAttendeeMails() {
  yield takeLatest(
    attendeesConstants.ATTENDEE_GET_MAILS_REQUEST,
    fetchGetAttendeeMails
  );
}

export function* postAttendeeNotes() {
  yield takeLatest(
    attendeesConstants.ATTENDEE_POST_NOTES_REQUEST,
    fetchPostAttendeeNotes
  );
}

export default function* attendeesSaga() {
  yield all([
    fork(getAttendeesByProject),
    fork(getAttendeeById),
    fork(getAttendeeNfcTags),
    fork(getAttendeeBarcodes),
    fork(getAttendeeBaskets),
    fork(getAttendeeNotes),
    fork(getAttendeeMails),
    fork(postAttendeeNotes),
  ]);
}
