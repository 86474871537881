import { getUrl, get, post } from '../../services';

// change link -- attendees instead of users
export async function getAttendeesByProjectApi(params: any) {
  return await get(
    getUrl('projects/' + params.idProject + '/users', params.args)
  );
}

// change link -- attendees instead of users
export async function getAttendeeByIdApi(params: any) {
  return await get(getUrl('users/' + params.idAttendee));
}

// change link -- attendees instead of users
export async function getAttendeeNfcTagsApi(params: any) {
  return await get(
    getUrl('users/' + params.idAttendee + '/nfctags', params.args)
  );
}

// change link -- attendees instead of users
export async function getAttendeeBarcodesApi(params: any) {
  return await get(
    getUrl('users/' + params.idAttendee + '/barcodes', params.args)
  );
}

// change link -- attendees instead of users
export async function getAttendeeBasketsApi(params: any) {
  return await get(
    getUrl('users/' + params.idAttendee + '/baskets', params.args)
  );
}

// change link -- attendees instead of users
export async function getAttendeeNotesApi(params: any) {
  return await get(
    getUrl('users/' + params.idAttendee + '/notes', params.args)
  );
}

// change link -- attendees instead of users
export async function getAttendeeMailsApi(params: any) {
  return await get(
    getUrl('users/' + params.idAttendee + '/mails', params.args)
  );
}

// change link -- attendees instead of users
export async function postAttendeeNotesApi(params: any) {
  return await post(
    getUrl('users/' + params.idAttendee + '/notes', params.params),
    params.note
  );
}
