export default class ImageModel {
  id: number;
  original: string;
  thumb: string;

  constructor(data?: any) {
    this.id = data.id;
    this.original = data.original;
    this.thumb = data.thumb;
  }
}

export const createImageModel = (data: {
  id: number;
  path: {
    original: string;
    large: string;
    thumb: string;
  };
}) => {
  return new ImageModel({
    id: data.id,
    original: data.path.original,
    thumb: data.path.thumb,
  });
};
