import { basketsConstants } from '../constants';
import BasketModel from '../../Models/BasketModel';
import MailModel from '../../Models/MailModel';
import NoteModel from '../../Models/NoteModel';
import PaymentModel from '../../Models/PaymentModel';
import BasketItemModel from '../../Models/BasketItemModel';
import ErrorModel from '../../Models/ErrorModel';

const initialState: any = {
  basket: null,
  basketItems: null,
  updatedBasketItems: null,
  basketPayments: null,
  newBasketNotes: null,
  basketNotes: null,
  basketMails: null,
};

let basketNotesTab: any[] = [];

const baskets = (state = initialState, action: any) => {
  switch (action.type) {
    case basketsConstants.BASKET_GET_REQUEST: {
      return {
        ...state,
        basket: { basket: null, error: false, loading: true },
      };
    }

    case basketsConstants.BASKET_GET_SUCCESS: {
      return {
        ...state,
        basket: {
          basket: new BasketModel(action.basket.data),
          error: false,
          loading: false,
        },
      };
    }

    case basketsConstants.BASKET_GET_FAILURE: {
      return {
        ...state,
        basket: { basket: null, error: true, loading: false },
      };
    }

    case basketsConstants.BASKET_GET_ITEMS_REQUEST: {
      return {
        ...state,
        basketItems: { items: null, error: false, loading: true },
      };
    }

    case basketsConstants.BASKET_GET_ITEMS_SUCCESS: {
      const list = action.basketItems.data.map(
        (item: any) => new BasketItemModel(item)
      );
      return {
        ...state,
        basketItems: {
          items: list,
          error: false,
          loading: false,
        },
      };
    }

    case basketsConstants.BASKET_GET_ITEMS_FAILURE: {
      return {
        ...state,
        basketItems: { items: null, error: true, loading: false },
      };
    }

    case basketsConstants.BASKET_PUT_ITEMS_REQUEST: {
      return {
        ...state,
        updatedBasketItems: { items: null, error: false, loading: true },
      };
    }

    case basketsConstants.BASKET_PUT_ITEMS_SUCCESS: {
      return {
        ...state,
        updatedBasketItems: {
          items: action.updatedBasketItems.data,
          error: false,
          loading: false,
        },
      };
    }

    case basketsConstants.BASKET_PUT_ITEMS_FAILURE: {
      return {
        ...state,
        updatedBasketItems: {
          items: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case basketsConstants.BASKET_GET_PAYMENTS_REQUEST: {
      return {
        ...state,
        basketPayments: { payments: null, error: false, loading: true },
      };
    }

    case basketsConstants.BASKET_GET_PAYMENTS_SUCCESS: {
      const list = action.basketPayments.data.map(
        (payment: any) => new PaymentModel(payment)
      );
      return {
        ...state,
        basketPayments: {
          payments: list,
          error: false,
          loading: false,
        },
      };
    }

    case basketsConstants.BASKET_GET_PAYMENTS_FAILURE: {
      return {
        ...state,
        basketPayments: { payments: null, error: true, loading: false },
      };
    }

    case basketsConstants.BASKET_GET_MAILS_REQUEST: {
      return {
        ...state,
        basketMails: { mails: null, error: false, loading: true },
      };
    }

    case basketsConstants.BASKET_GET_MAILS_SUCCESS: {
      const mailList = action.basketMails.data.map(
        (mail: any) => new MailModel(mail)
      );
      return {
        ...state,
        basketMails: {
          mails: mailList,
          error: false,
          loading: false,
        },
      };
    }

    case basketsConstants.BASKET_GET_MAILS_FAILURE: {
      return {
        ...state,
        basketMails: { mails: null, error: true, loading: false },
      };
    }

    case basketsConstants.BASKET_POST_NOTES_REQUEST: {
      return {
        ...state,
        newBasketNotes: { note: null, error: false, loading: true },
      };
    }

    case basketsConstants.BASKET_POST_NOTES_SUCCESS: {
      return {
        ...state,
        newBasketNotes: {
          note: action.newBasketNotes.data,
          error: false,
          loading: false,
        },
      };
    }

    case basketsConstants.BASKET_POST_NOTES_FAILURE: {
      return {
        ...state,
        newBasketNotes: { note: null, error: true, loading: false },
      };
    }

    case basketsConstants.BASKET_GET_NOTES_REQUEST: {
      return {
        ...state,
        basketNotes: { notes: basketNotesTab, error: false, loading: true },
      };
    }

    case basketsConstants.BASKET_GET_NOTES_SUCCESS: {
      basketNotesTab = action.basketNotes.data.map(
        (note: any) => new NoteModel(note)
      );

      return {
        ...state,
        basketNotes: {
          notes: basketNotesTab,
          error: false,
          loading: false,
        },
      };
    }

    case basketsConstants.BASKET_GET_NOTES_FAILURE: {
      return {
        ...state,
        basketNotes: { notes: basketNotesTab, error: true, loading: false },
      };
    }

    default:
      return state;
  }
};

export default baskets;
