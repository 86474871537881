import { getUrl, get, update } from '../../services';
import { getContentsParams } from '../../actions';

export async function getContentsApi(params: getContentsParams) {
  return await get(getUrl('ticketing/contents', params));
}

export async function getContentContentsApi(arg: any) {
  return await get(
    getUrl('content/contents/' + encodeURIComponent(arg.reference), arg.params)
  );
}

export async function getContentMailsApi(arg: any) {
  return await get(
    getUrl('content/mails/' + encodeURIComponent(arg.reference), arg.params)
  );
}

export async function putContentMailsApi(arg: any) {
  return await update(
    getUrl('content/mails/' + encodeURIComponent(arg.reference), {
      project_id: arg.projectId,
    }),
    arg.params
  );
}

export async function putContentContentsApi(arg: any) {
  return await update(
    getUrl('content/contents/' + encodeURIComponent(arg.reference), {
      project_id: arg.projectId,
    }),
    arg.params
  );
}
