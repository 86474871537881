import Translator from '../services/translator';

type referenceType =
  | 'ticket'
  | 'pack'
  | 'topup'
  | 'accommodation'
  | 'transportation'
  | 'drink'
  | 'food'
  | 'extra'
  | 'shop'
  | 'discount';

export default class ProductTypeModel {
  value: number;
  reference?: referenceType;
  label: string;

  constructor(data: any) {
    this.value = data.value;
    this.reference = data.reference;
    this.label = data.label ? data.label : getProductType(data.reference);
  }
}

export const getProductType = (type: referenceType) => {
  switch (type) {
    case 'ticket':
      return Translator.trans('product.type.ticket');
    case 'pack':
      return Translator.trans('product.type.pack');
    case 'topup':
      return Translator.trans('product.type.topup');
    case 'accommodation':
      return Translator.trans('product.type.accommodation');
    case 'transportation':
      return Translator.trans('product.type.transportation');
    case 'drink':
      return Translator.trans('product.type.drink');
    case 'food':
      return Translator.trans('product.type.food');
    case 'extra':
      return Translator.trans('product.type.extra');
    case 'shop':
      return Translator.trans('product.type.shop');
    case 'discount':
      return Translator.trans('product.type.discount');
  }
};

export const createProductTypeFromApi = (data: any) => {
  return new ProductTypeModel({
    value: data.id,
    reference: data.reference,
  });
};
