import { getUrl, get, update, patch } from '../../services';

export async function putProjectDetailsApi(params: any) {
  return await update(
    getUrl('projects/' + params.idProject),
    params.projectDetails
  );
}

export async function getProjectDetailsApi(params: any) {
  return await get(getUrl('projects/' + params.idProject));
}

export async function getProjectsSelfApi() {
  return await get(getUrl('self/projects?limit=1000&order=id:DESC'));
}

export async function getProjectCostApi(params: any) {
  return await get(getUrl('projects/' + params.idProject + '/costs'));
}

export async function archiveProjectApi(params: any) {
  return await patch(getUrl('projects/' + params.idProject + '/archive'));
}
