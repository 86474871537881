import { reportsTicketingConstants } from '../constants';

export const getBarcodesReportsRequest = (idProject: number, options: any) => ({
  type: reportsTicketingConstants.GET_BARCODES_REPORTS_REQUEST,
  idProject,
  options,
});

export const getBarcodesReportsSuccess = (barcodesReports: any) => ({
  type: reportsTicketingConstants.GET_BARCODES_REPORTS_SUCCESS,
  barcodesReports,
});

export const getBarcodesReportsFailure = (error: any) => ({
  type: reportsTicketingConstants.GET_BARCODES_REPORTS_FAILURE,
  error,
});

export const getBasketsReportsRequest = (idProject: number, options: any) => ({
  type: reportsTicketingConstants.GET_BASKETS_REPORTS_REQUEST,
  idProject,
  options,
});

export const getBasketsReportsSuccess = (basketsReports: any) => ({
  type: reportsTicketingConstants.GET_BASKETS_REPORTS_SUCCESS,
  basketsReports,
});

export const getBasketsReportsFailure = (error: any) => ({
  type: reportsTicketingConstants.GET_BASKETS_REPORTS_FAILURE,
  error,
});

export const getPosProductReportsRequest = (idPos: number, options: any) => ({
  type: reportsTicketingConstants.GET_POS_PRODUCT_REPORTS_REQUEST,
  idPos,
  options,
});

export const getPosProductReportsSuccess = (posReports: any) => ({
  type: reportsTicketingConstants.GET_POS_PRODUCT_REPORTS_SUCCESS,
  posReports,
});

export const getPosProductReportsFailure = (error: any) => ({
  type: reportsTicketingConstants.GET_POS_PRODUCT_REPORTS_FAILURE,
  error,
});
