export const reportsTicketingConstants = {
  GET_BARCODES_REPORTS_REQUEST: 'GET_BARCODES_REPORTS_REQUEST',
  GET_BARCODES_REPORTS_SUCCESS: 'GET_BARCODES_REPORTS_SUCCESS',
  GET_BARCODES_REPORTS_FAILURE: 'GET_BARCODES_REPORTS_FAILURE',

  GET_BASKETS_REPORTS_REQUEST: 'GET_BASKETS_REPORTS_REQUEST',
  GET_BASKETS_REPORTS_SUCCESS: 'GET_BASKETS_REPORTS_SUCCESS',
  GET_BASKETS_REPORTS_FAILURE: 'GET_BASKETS_REPORTS_FAILURE',

  GET_POS_PRODUCT_REPORTS_REQUEST: 'GET_POS_PRODUCT_REPORTS_REQUEST',
  GET_POS_PRODUCT_REPORTS_SUCCESS: 'GET_POS_PRODUCT_REPORTS_SUCCESS',
  GET_POS_PRODUCT_REPORTS_FAILURE: 'GET_POS_PRODUCT_REPORTS_FAILURE',
};
