import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  deleteCategorySuccess,
  deleteCategoryFailure,
  getCategoryTranslationsSuccess,
  getCategoryTranslationsFailure,
  getCategoriesByProjectSuccess,
  getCategoriesByProjectFailure,
  postCategoryByProjectSuccess,
  postCategoryByProjectFailure,
  postCategoryTranslationsSuccess,
  postCategoryTranslationsFailure,
  putCategoryTranslationsSuccess,
  putCategoryTranslationsFailure,
  postCategoryProductSuccess,
  postCategoryProductFailure,
} from '../actions';
import { categoriesConstants } from '../constants';
import {
  deleteCategoryApi,
  getCategoryTranslationsApi,
  getCategoriesByProjectApi,
  postCategoryByProjectApi,
  putCategoryTranslationsApi,
  postCategoryTranslationsApi,
  postCategoryProductApi,
} from '../api';

export function* fetchDeleteCategory(action: any): any {
  try {
    const deletedCategory = yield call(deleteCategoryApi, action.idCategory);
    yield put(deleteCategorySuccess(deletedCategory));
  } catch (error) {
    yield put(deleteCategoryFailure(error));
  }
}

export function* fetchGetCategoryTranslations(action: any): any {
  try {
    const categoryTranslations = yield call(
      getCategoryTranslationsApi,
      action.idCategory
    );
    yield put(getCategoryTranslationsSuccess(categoryTranslations));
  } catch (error) {
    yield put(getCategoryTranslationsFailure(error));
  }
}

export function* fetchGetCategoriesByProject(action: any): any {
  try {
    const categories = yield call(getCategoriesByProjectApi, {
      idProject: action.idProject,
      options: action.options,
    });
    yield put(getCategoriesByProjectSuccess(categories));
  } catch (error) {
    yield put(getCategoriesByProjectFailure(error));
  }
}

export function* fetchPostCategoryByProject(action: any): any {
  try {
    const newCategory = yield call(postCategoryByProjectApi, {
      idProject: action.idProject,
      newCategory: action.newCategory,
    });
    yield put(postCategoryByProjectSuccess(newCategory));
  } catch (error) {
    yield put(postCategoryByProjectFailure(error));
  }
}

export function* fetchPutCategoryTranslations(action: any): any {
  try {
    const updatedCategoryTranslations = yield call(putCategoryTranslationsApi, {
      idCategory: action.idCategory,
      updatedCategoryTranslations: action.updatedCategoryTranslations,
    });
    yield put(putCategoryTranslationsSuccess(updatedCategoryTranslations));
  } catch (error) {
    yield put(putCategoryTranslationsFailure(error));
  }
}

export function* fetchPostCategoryTranslations(action: any): any {
  try {
    const newCategoryTranslations = yield call(postCategoryTranslationsApi, {
      idCategory: action.idCategory,
      categoryTranslations: action.categoryTranslations,
    });
    yield put(postCategoryTranslationsSuccess(newCategoryTranslations));
  } catch (error) {
    yield put(postCategoryTranslationsFailure(error));
  }
}

export function* fetchPostCategoryProduct(action: any): any {
  try {
    const response = yield call(postCategoryProductApi, {
      idCategory: action.idCategory,
      idProduct: action.idProduct,
    });
    yield put(postCategoryProductSuccess(response));
  } catch (error) {
    yield put(postCategoryProductFailure(error));
  }
}

export function* deleteCategory() {
  yield takeLatest(
    categoriesConstants.CATEGORY_DELETE_REQUEST,
    fetchDeleteCategory
  );
}

export function* getCategoryTranslations() {
  yield takeLatest(
    categoriesConstants.CATEGORY_GET_TRANSLATIONS_REQUEST,
    fetchGetCategoryTranslations
  );
}

export function* getCategoriesByProject() {
  yield takeLatest(
    categoriesConstants.CATEGORIES_GET_BY_PROJECT_REQUEST,
    fetchGetCategoriesByProject
  );
}

export function* postCategoryByProject() {
  yield takeLatest(
    categoriesConstants.CATEGORY_POST_BY_PROJECT_REQUEST,
    fetchPostCategoryByProject
  );
}

export function* putCategoryTranslations() {
  yield takeLatest(
    categoriesConstants.CATEGORY_TRANSLATIONS_PUT_REQUEST,
    fetchPutCategoryTranslations
  );
}

export function* postCategoryTranslations() {
  yield takeLatest(
    categoriesConstants.CATEGORY_TRANSLATIONS_POST_REQUEST,
    fetchPostCategoryTranslations
  );
}

export function* postCategoryProduct() {
  yield takeLatest(
    categoriesConstants.CATEGORY_POST_PRODUCT_REQUEST,
    fetchPostCategoryProduct
  );
}

export default function* categoriesSaga() {
  yield all([
    fork(deleteCategory),
    fork(getCategoryTranslations),
    fork(getCategoriesByProject),
    fork(postCategoryByProject),
    fork(putCategoryTranslations),
    fork(postCategoryTranslations),
    fork(postCategoryProduct),
  ]);
}
