import { getUrl, get, post, del } from '../../services';

export async function getAdminListApi(id: number) {
  return await get(getUrl('projects/' + id + '/administrators'));
}

export async function addAdminApi(arg: any) {
  return await post(
    getUrl('projects/' + arg.id + '/administrators'),
    arg.payload
  );
}

export async function deleteAdminApi(arg: any) {
  return await del(
    getUrl('projects/' + arg.projectId + '/administrators/' + arg.adminId)
  );
}
