import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getMailSuccess, getMailFailure } from '../actions';
import { mailsConstants } from '../constants';
import { getMailApi } from '../api';

export function* fetchGetMail(action: any): any {
  try {
    const mail = yield call(getMailApi, action.idMail);
    yield put(getMailSuccess(mail));
  } catch (error) {
    yield put(getMailFailure(error));
  }
}

export function* getMail() {
  yield takeLatest(mailsConstants.MAIL_GET_REQUEST, fetchGetMail);
}

export default function* mailsSaga() {
  yield all([fork(getMail)]);
}
