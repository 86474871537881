import { addressesConstants } from '../constants';

export const getProjectAddressRequest = (idProject: number) => ({
  type: addressesConstants.GET_PROJECT_ADDRESS_REQUEST,
  idProject,
});

export const getProjectAddressSuccess = (address: any) => ({
  type: addressesConstants.GET_PROJECT_ADDRESS_SUCCESS,
  address,
});

export const getProjectAddressFailure = (error: any) => ({
  type: addressesConstants.GET_PROJECT_ADDRESS_FAILURE,
  error,
});

export const putProjectAddressRequest = (idProject: number, address: any) => ({
  type: addressesConstants.PUT_PROJECT_ADDRESS_REQUEST,
  idProject,
  address,
});

export const putProjectAddressSuccess = (updatedAddress: any) => ({
  type: addressesConstants.PUT_PROJECT_ADDRESS_SUCCESS,
  updatedAddress,
});

export const putProjectAddressFailure = (error: any) => ({
  type: addressesConstants.PUT_PROJECT_ADDRESS_FAILURE,
  error,
});

export const getOrganizationAddressRequest = (idOrganization: number) => ({
  type: addressesConstants.GET_ORGANIZATION_ADDRESS_REQUEST,
  idOrganization,
});

export const getOrganizationAddressSuccess = (address: any) => ({
  type: addressesConstants.GET_ORGANIZATION_ADDRESS_SUCCESS,
  address,
});

export const getOrganizationAddressFailure = (error: any) => ({
  type: addressesConstants.GET_ORGANIZATION_ADDRESS_FAILURE,
  error,
});

export const putOrganizationAddressRequest = (
  idOrganization: number,
  address: any
) => ({
  type: addressesConstants.PUT_ORGANIZATION_ADDRESS_REQUEST,
  idOrganization,
  address,
});

export const putOrganizationAddressSuccess = (updatedAddress: any) => ({
  type: addressesConstants.PUT_ORGANIZATION_ADDRESS_SUCCESS,
  updatedAddress,
});

export const putOrganizationAddressFailure = (error: any) => ({
  type: addressesConstants.PUT_ORGANIZATION_ADDRESS_FAILURE,
  error,
});
