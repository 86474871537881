export default class ProgressiveFeeModel {
  id: number;
  constantFee: number;
  percentFee: number;
  priceThreshold: number;

  constructor(data: any) {
    this.id = data.id;
    this.constantFee = data.constantFee;
    this.percentFee = data.percentFee;
    this.priceThreshold = data.priceThreshold;
  }
}
