export const addTokenToStorage = (data: {
  token: string;
  refresh_token: string;
}) => {
  localStorage.setItem('token', data.token);
  localStorage.setItem('refreshToken', data.refresh_token);
};

export const removeTokenFromStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('paramsAttendee');
};

export const getTokenStorage = () => {
  return localStorage.getItem('token');
};

export const getRefreshTokenStorage = () => {
  return localStorage.getItem('refreshToken');
};

export const getAuthTokensStorage = () => {
  return getTokenStorage() && getRefreshTokenStorage();
};
