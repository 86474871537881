import React, { useEffect, useMemo, useState } from 'react';
import Translator from '../../../services/translator';
import { useSelector } from 'react-redux';
import { storeType } from '../../../index';
import { checkReduxResponse } from '../../../services/checkReduxResponse';
import DoneIcon from '@mui/icons-material/Done';
import OnboardingModel from '../../../Models/OnboardingModel';
import { pagesConstant } from '../../../Constants/Constant';
import './WelcomeComponent.css';

interface WelcomeComponentInterface {
  handleClick: (path: string) => void;
}

const WelcomeComponent = (props: WelcomeComponentInterface) => {
  const [onboarding, setOnboarding] = useState<OnboardingModel | null>(null);
  const [showComponent, setShowComponent] = useState(false);
  const TodoList = useMemo(
    () => [
      {
        key: 'organization',
        path: pagesConstant.organization,
        name: Translator.trans('sidenav.welcome.setup.organization'),
        isComplete: onboarding?.organization,
      },
      {
        key: 'event',
        path: pagesConstant.events.current,
        name: Translator.trans('sidenav.welcome.setup.event'),
        isComplete: onboarding?.event,
      },
      {
        key: 'firstCategory',
        path: pagesConstant.categories,
        name: Translator.trans('sidenav.welcome.setup.category'),
        isComplete: onboarding?.firstCategory,
      },
      {
        key: 'firstProduct',
        path: pagesConstant.tickets.add,
        name: Translator.trans('sidenav.welcome.setup.ticket'),
        isComplete: onboarding?.firstProduct,
      },
      {
        key: 'customization',
        path: pagesConstant.branding.visual,
        name: Translator.trans('sidenav.welcome.setup.branding'),
        isComplete: onboarding?.customization,
      },
    ],
    [onboarding]
  );

  const organizationStatus = useSelector(
    (state: storeType) => state.organization.organizationStatus
  );

  useEffect(() => {
    if (checkReduxResponse(organizationStatus, 'status')) {
      if (organizationStatus.status.onboarding) {
        setOnboarding(organizationStatus.status.onboarding);
        setShowComponent(!organizationStatus.status.onboarding.isCompleted);
      }
    }
  }, [organizationStatus]);

  return showComponent ? (
    <div className={'welcome-container'}>
      <div className={'welcome-title'}>
        {onboarding?.isAlmostComplete
          ? Translator.transHtml('sidenav.almost.title')
          : Translator.transHtml('sidenav.welcome.title')}
      </div>
      <div className={'todo-list'}>
        {TodoList.map((todo, index) => (
          <div
            key={todo.key}
            className={`el-list ${todo.isComplete && 'active'}`}
            onClick={() => props.handleClick(todo.path)}
          >
            <span className={'number-marker'}>
              {todo.isComplete ? <DoneIcon /> : index + 1}
            </span>

            <span className={'todo-name'}>{todo.name}</span>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default WelcomeComponent;
