import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getAdminsSuccess,
  getAdminsFailure,
  addAdminSuccess,
  addAdminFailure,
  deleteAdminSuccess,
  deleteAdminFailure,
} from '../actions';
import { adminConstants } from '../constants';
import { addAdminApi, deleteAdminApi, getAdminListApi } from '../api';

export function* fetchGetAdmins(action: any): any {
  try {
    const admin = yield call(getAdminListApi, action.projectId);
    yield put(getAdminsSuccess(admin));
  } catch (error) {
    yield put(getAdminsFailure(error));
  }
}

export function* fetchAddAdmin(action: any): any {
  try {
    const admin = yield call(addAdminApi, {
      id: action.projectId,
      payload: action.payload,
    });
    yield put(addAdminSuccess(admin));
  } catch (error) {
    yield put(addAdminFailure(error));
  }
}

export function* fetchDeleteAdmin(action: any): any {
  try {
    const admin = yield call(deleteAdminApi, {
      projectId: action.projectId,
      adminId: action.adminId,
    });
    yield put(deleteAdminSuccess(admin));
  } catch (error) {
    yield put(deleteAdminFailure(error));
  }
}

export function* getAdmins() {
  yield takeLatest(adminConstants.GET_ADMINS_REQUEST, fetchGetAdmins);
}

export function* addAdmin() {
  yield takeLatest(adminConstants.ADD_ADMIN_REQUEST, fetchAddAdmin);
}

export function* deleteAdmin() {
  yield takeLatest(adminConstants.DELETE_ADMIN_REQUEST, fetchDeleteAdmin);
}

export default function* adminSaga() {
  yield all([fork(getAdmins), fork(addAdmin), fork(deleteAdmin)]);
}
