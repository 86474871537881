import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getBasketSuccess,
  getBasketFailure,
  getBasketItemsSuccess,
  getBasketItemsFailure,
  getBasketPaymentsSuccess,
  getBasketPaymentsFailure,
  getBasketMailsSuccess,
  getBasketMailsFailure,
  putBasketItemsFailure,
  putBasketItemsSuccess,
  getBasketNotesSuccess,
  getBasketNotesFailure,
  postBasketNotesSuccess,
  postBasketNotesFailure,
} from '../actions';
import { basketsConstants } from '../constants';
import {
  getBasketApi,
  getBasketItemsApi,
  getBasketMailsApi,
  getBasketNotesApi,
  getBasketPaymentsApi,
  postBasketNotesApi,
  putBasketItemsApi,
} from '../api';

export function* fetchGetBasket(action: any): any {
  try {
    const basket = yield call(getBasketApi, {
      idBasket: action.idBasket,
      args: action.args,
    });
    yield put(getBasketSuccess(basket));
  } catch (error) {
    yield put(getBasketFailure(error));
  }
}

export function* fetchGetBasketItems(action: any): any {
  try {
    const basketItems = yield call(getBasketItemsApi, action.idBasket);
    yield put(getBasketItemsSuccess(basketItems, action.idBasket));
  } catch (error) {
    yield put(getBasketItemsFailure(error));
  }
}

export function* fetchGetBasketPayments(action: any): any {
  try {
    const basketPayments = yield call(getBasketPaymentsApi, action.idBasket);
    yield put(getBasketPaymentsSuccess(basketPayments, action.idBasket));
  } catch (error) {
    yield put(getBasketPaymentsFailure(error));
  }
}

export function* fetchGetBasketMails(action: any): any {
  try {
    const basketMails = yield call(getBasketMailsApi, action.idBasket);
    yield put(getBasketMailsSuccess(basketMails));
  } catch (error) {
    yield put(getBasketMailsFailure(error));
  }
}

export function* fetchPutBasketItems(action: any): any {
  try {
    const updatedBasketItems = yield call(putBasketItemsApi, {
      idBasket: action.idBasket,
      updatedBasketItems: action.updatedBasketItems,
    });
    yield put(putBasketItemsSuccess(updatedBasketItems));
  } catch (error) {
    yield put(putBasketItemsFailure(error));
  }
}

export function* fetchPostBasketNotes(action: any): any {
  try {
    const newBasketNotes = yield call(postBasketNotesApi, {
      idBasket: action.idBasket,
      note: action.note,
    });
    yield put(postBasketNotesSuccess(newBasketNotes));
  } catch (error) {
    yield put(postBasketNotesFailure(error));
  }
}

export function* fetchGetBasketNotes(action: any): any {
  try {
    const basketNotes = yield call(getBasketNotesApi, {
      idBasket: action.idBasket,
      params: action.params,
    });
    yield put(getBasketNotesSuccess(basketNotes));
  } catch (error) {
    yield put(getBasketNotesFailure(error));
  }
}

export function* getBasketItems() {
  yield takeLatest(
    basketsConstants.BASKET_GET_ITEMS_REQUEST,
    fetchGetBasketItems
  );
}

export function* getBasketPayments() {
  yield takeLatest(
    basketsConstants.BASKET_GET_PAYMENTS_REQUEST,
    fetchGetBasketPayments
  );
}

export function* getBasketMails() {
  yield takeLatest(
    basketsConstants.BASKET_GET_MAILS_REQUEST,
    fetchGetBasketMails
  );
}

export function* putBasketItems() {
  yield takeLatest(
    basketsConstants.BASKET_PUT_ITEMS_REQUEST,
    fetchPutBasketItems
  );
}

export function* getBasket() {
  yield takeLatest(basketsConstants.BASKET_GET_REQUEST, fetchGetBasket);
}

export function* postBasketNotes() {
  yield takeLatest(
    basketsConstants.BASKET_POST_NOTES_REQUEST,
    fetchPostBasketNotes
  );
}

export function* getBasketNotes() {
  yield takeLatest(
    basketsConstants.BASKET_GET_NOTES_REQUEST,
    fetchGetBasketNotes
  );
}

export default function* basketsSaga() {
  yield all([
    fork(getBasket),
    fork(getBasketItems),
    fork(getBasketPayments),
    fork(getBasketMails),
    fork(putBasketItems),
    fork(postBasketNotes),
    fork(getBasketNotes),
  ]);
}
