import { adminConstants } from '../constants';
import AdminModel from '../../Models/AdminModel';
import ErrorModel from '../../Models/ErrorModel';

const initialState: any = {
  adminList: null,
  addedAdmin: null,
  deletedAdmin: null,
};

let listAdmins: AdminModel[] = [];
const admin = (state = initialState, action: any) => {
  switch (action.type) {
    case adminConstants.GET_ADMINS_REQUEST: {
      return {
        ...state,
        adminList: { admins: listAdmins, error: false, loading: true },
      };
    }

    case adminConstants.GET_ADMINS_SUCCESS: {
      listAdmins = action.admins.data.map(
        (admin: any) => new AdminModel(admin)
      );

      return {
        ...state,
        adminList: { admins: listAdmins, error: false, loading: false },
      };
    }

    case adminConstants.GET_ADMINS_FAILURE: {
      return {
        ...state,
        adminList: { admins: null, error: true, loading: false },
      };
    }

    case adminConstants.ADD_ADMIN_REQUEST: {
      return {
        ...state,
        addedAdmin: { admin: null, error: false, loading: true },
      };
    }

    case adminConstants.ADD_ADMIN_SUCCESS: {
      return {
        ...state,
        addedAdmin: { admin: action.response, error: false, loading: false },
      };
    }

    case adminConstants.ADD_ADMIN_FAILURE: {
      return {
        ...state,
        addedAdmin: {
          admin: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case adminConstants.DELETE_ADMIN_REQUEST: {
      return {
        ...state,
        deletedAdmin: { admin: null, error: false, loading: true },
      };
    }

    case adminConstants.DELETE_ADMIN_SUCCESS: {
      return {
        ...state,
        deletedAdmin: { admin: action.response, error: false, loading: false },
      };
    }

    case adminConstants.DELETE_ADMIN_FAILURE: {
      return {
        ...state,
        deletedAdmin: {
          admin: null,
          error: new ErrorModel(action.error.body),
          loading: false,
        },
      };
    }

    case adminConstants.ADMIN_RESET: {
      return {
        ...state,
        addedAdmin: null,
        deletedAdmin: null,
      };
    }

    default:
      return state;
  }
};

export default admin;
