export const addressesConstants = {
  GET_PROJECT_ADDRESS_REQUEST: 'GET_PROJECT_ADDRESS_REQUEST',
  GET_PROJECT_ADDRESS_SUCCESS: 'GET_PROJECT_ADDRESS_SUCCESS',
  GET_PROJECT_ADDRESS_FAILURE: 'GET_PROJECT_ADDRESS_FAILURE',

  PUT_PROJECT_ADDRESS_REQUEST: 'PUT_PROJECT_ADDRESS_REQUEST',
  PUT_PROJECT_ADDRESS_SUCCESS: 'PUT_PROJECT_ADDRESS_SUCCESS',
  PUT_PROJECT_ADDRESS_FAILURE: 'PUT_PROJECT_ADDRESS_FAILURE',

  GET_ORGANIZATION_ADDRESS_REQUEST: 'GET_ORGANIZATION_ADDRESS_REQUEST',
  GET_ORGANIZATION_ADDRESS_SUCCESS: 'GET_ORGANIZATION_ADDRESS_SUCCESS',
  GET_ORGANIZATION_ADDRESS_FAILURE: 'GET_ORGANIZATION_ADDRESS_FAILURE',

  PUT_ORGANIZATION_ADDRESS_REQUEST: 'PUT_ORGANIZATION_ADDRESS_REQUEST',
  PUT_ORGANIZATION_ADDRESS_SUCCESS: 'PUT_ORGANIZATION_ADDRESS_SUCCESS',
  PUT_ORGANIZATION_ADDRESS_FAILURE: 'PUT_ORGANIZATION_ADDRESS_FAILURE',
};
