import Translator from '../services/translator';
import { getNameLanguage } from '../services/GetNameLanguage';
import { currentProject } from '../Global/currentProject';

export const pagesConstant = {
  administrators: '/administrators',
  attendees: {
    home: '/attendees',
    detail: '/attendee/show/',
  },
  barcodes: '/barcodes',
  billing: '/billing',
  branding: {
    content: '/branding/content',
    visual: '/branding/visual',
    ticket: '/branding/ticket',
  },
  categories: '/categories',
  dashboard: {
    ticketing: '/dashboard/ticketing',
    event: '/dashboard/event',
  },
  events: {
    current: '/event',
    list: '/events/list',
    add: '/events/creation',
  },
  forms: {
    home: '/forms',
    add: '/form/creation',
    edit: '/form/edition/',
    preview: '/form/preview/',
  },
  home: '/index',
  import: {
    manual: '/import/manual',
    auto: '/import/auto',
  },
  marketing: {
    promo: '/marketing/promotional',
    integration: '/marketing/integration',
  },
  orders: {
    home: '/orders',
    detail: '/order/show/',
  },
  organization: '/organization',
  payment: '/payment',
  paymentMethod: '/payment-methods',
  profile: '/profile',
  sales: {
    online: '/online-ticket',
    physical: '/physical',
    integration: '/integration',
  },
  signIn: '/signin',
  setupPassword: '/setup-password',
  tickets: {
    home: '/tickets',
    add: '/ticket/creation',
    edit: '/ticket/edition/',
  },
};

export interface selectItemsInterface {
  value: string | number;
  label: string;
}

export interface legalStatusInterface extends selectItemsInterface {
  vatRequired: boolean;
}

export const legalStatusList: readonly legalStatusInterface[] = [
  {
    value: 'particular',
    label: Translator.trans('organization.legal.status.particular'),
    vatRequired: false,
  },
  {
    value: 'business',
    label: Translator.trans('organization.legal.status.business'),
    vatRequired: true,
  },
  {
    value: 'association',
    label: Translator.trans('organization.legal.status.association'),
    vatRequired: true,
  },
  {
    value: 'federation',
    label: Translator.trans('organization.legal.status.federation'),
    vatRequired: true,
  },
  {
    value: 'community',
    label: Translator.trans('organization.legal.status.community'),
    vatRequired: true,
  },
  {
    value: 'educative',
    label: Translator.trans('organization.legal.status.educative'),
    vatRequired: true,
  },
  {
    value: 'others',
    label: Translator.trans('organization.legal.status.other'),
    vatRequired: true,
  },
];

export const eventTypeList: readonly selectItemsInterface[] = [
  {
    value: 'concert',
    label: Translator.trans('project.eventType.concert'),
  },
  {
    value: 'festival',
    label: Translator.trans('project.eventType.festival'),
  },
  {
    value: 'webinar',
    label: Translator.trans('project.eventType.webinar'),
  },
  {
    value: 'sport',
    label: Translator.trans('project.eventType.sport'),
  },
  {
    value: 'culture',
    label: Translator.trans('project.eventType.culture'),
  },
  {
    value: 'professional',
    label: Translator.trans('project.eventType.professional'),
  },
  {
    value: 'private',
    label: Translator.trans('project.eventType.private'),
  },
  {
    value: 'workshop',
    label: Translator.trans('project.eventType.workshop'),
  },
  {
    value: 'charity',
    label: Translator.trans('project.eventType.charity'),
  },
  {
    value: 'test',
    label: Translator.trans('project.eventType.test'),
  },
  {
    value: 'other',
    label: Translator.trans('project.eventType.other'),
  },
];

export const getEventType = (currentType: string) => {
  return eventTypeList.find((data) => data.value === currentType);
};

export const languagesList: selectItemsInterface[] = [
  {
    value: 'fr',
    label: getNameLanguage(currentProject.language.locale, 'fr'),
  },
  {
    value: 'en_US',
    label: getNameLanguage(currentProject.language.locale, 'en_US'),
  },
  {
    value: 'nl',
    label: getNameLanguage(currentProject.language.locale, 'nl'),
  },
  {
    value: 'fr_CA',
    label: getNameLanguage(currentProject.language.locale, 'fr_CA'),
  },
];

export const getLanguage = (language: string) => {
  let tmpLang = languagesList.find((data) => data.value === language);
  if (tmpLang) {
    return tmpLang;
  } else {
    tmpLang = languagesList.find(
      (data) => data.value.toString().slice(0, 2) === language.slice(0, 2)
    );
    if (tmpLang) {
      return tmpLang;
    } else {
      return languagesList[0];
    }
  }
};
