import QuestionModel from './QuestionModel';

export default class FormModel {
  id: number;
  name: string;
  questions: QuestionModel[];

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.questions = data.questions
      ? data.questions.map((question: any) => new QuestionModel(question))
      : [];
  }
}
