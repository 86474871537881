export default class ShopModel {
  id: number;
  name: string;
  shop_url: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.shop_url = data.shop_url;
  }
}
